import React, { Fragment, useEffect, useState } from 'react'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import BackComponent from '../../../components/BackComponent'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetGAAProductModesMaster, SaveGAAProductModesMaster } from "../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster";
import { ClearInfo, HardwareTypes, ProductOperationModesList } from "../../../Encapsulation/Utils";
import CustomSelect from '../../helpers/CustomSelect'
import Option from '../../helpers/Option'

function GAAProductModesMasterAddEdit() {
  let formMode = "New";
  let { id } = useParams();

  if (id) {
    document.title = "Gladiance - Edit Product Master";
    formMode = "Edit";
  } else {
    document.title = "Gladiance - Add Product Master";
  }

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  console.log("errors :", errors);
  const Navigation = useNavigate();
  const [formInfo, setFormInfo] = useState({});

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAAProductModesMaster(id, formInfo);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/product-operation-modes-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
      }
    }
  };

  const fetchProductModesMasterId = async () => {
    setFormInfo(await GetGAAProductModesMaster(id));
  };

  useEffect(() => {
    if (id) {
      fetchProductModesMasterId();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={
            id ? "Edit Product Master " : "Add Product Master"
          }
          button={
            <LinkButton to={"/product-operation-modes-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"Code"} labelText={"Product Code"} />
                    <Input
                      inputFocus={true}
                      id={"Code"}
                      type={"text"}
                      name={"Code"}
                      value={formInfo.Code}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Code} />
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Product Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProductOperationMode"}
                      labelText={"Product Operation Modes"}
                    />
                    <CustomSelect
                      id={"GAAProductOperationMode"}
                      name={"GAAProductOperationMode"}
                      value={formInfo.GAAProductOperationMode}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option
                        value={""}
                      >
                        --- Select Product Operation Modes ---
                      </Option>
                      {ProductOperationModesList &&
                        ProductOperationModesList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option
                                value={data.Value}
                              >
                                {data.Name}
                              </Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                    <Validation error={errors.GAAProductOperationMode} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"HardwareType"}
                      labelText={"Hardware Type"}
                    />
                    <CustomSelect
                      id={"HardwareType"}
                      name={"HardwareType"}
                      value={formInfo.HardwareType}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option value={""}>
                        --- Select Hardware Type ---
                      </Option>
                      {HardwareTypes &&
                        HardwareTypes.map((data, id) => {
                          return (
                            <Option key={id} value={data.Value}>{data.Name}</Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/product-operation-modes-master"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}
export default GAAProductModesMasterAddEdit;
