import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import { ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import SaveButton from "../../helpers/SaveButton";
import {
  ClearInfo,
  FirmwareStatusList,
  HardwareTypes,
} from "../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";
import { GetGAAProductModesMaster } from "../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster";
import CustomSelect from "../../helpers/CustomSelect";
import {
  GetGAAProductVersions,
  SaveGAAProductVersions,
} from "../../../Encapsulation/GladianceDomainEntities/GAAProductVersion";
import Option from "../../helpers/Option";
import Swal from "sweetalert2";
import CheckBox from "../../helpers/CheckBox";

function GAAProductVersionAddEdit() {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Gladiance - Edit Product Version";
      formMode = "Edit";
    } else {
      document.title = "Gladiance - Add Product Version";
    }
  }
  const [loader, setLoader] = useState(false);
  const Navigation = useNavigate();
  const Location = useLocation();
  const [formInfo, setFormInfo] = useState({
    FirmwareStatus: 0,
    FirmwareId: "",
  });

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^[A-Z0-9]*[A-Z][A-Z0-9]*$/.test(formInfo.DesignId) || formInfo.DesignId.length != 12) {
      Swal.fire({
        title: "Design Id (Note)",
        html: `1. Only Alphabets And Numbers Allowed. <br> 2. Text Should be in Uppercase Allowed. <br> 3. 12 Letter  Compulsary.  <br>`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else if (
      !/^[a-z0-9]+(\.[a-z0-9]*){0,3}$/.test(formInfo.FirmwareId) ||
      (formInfo.FirmwareId.match(/\./g) || []).length > 3 ||
      formInfo.FirmwareId.endsWith(".")
    ) {
      Swal.fire({
        title: "Firmware Id (Note)",
        html: `1. Only Alphabets, Numbers and Dots are Allowed <br> 2. First & Last Letter Should be Alphabet or Number <br> 3. Text Should be in Lowercase & No spaces Allowed <br> 4. Max three Dots are Allowed`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    } else {
      console.log("formInfo :", formInfo);
      let result = await SaveGAAProductVersions(id, formInfo);
      console.log("result :", result);
      if (result.Successful == false) {
        flasher.warning(result.Message);
      } else {
        flasher.success("Data Added Successfully");
        if (formMode == "Edit") {
          Navigation("/gladiance-product-version");
        } else {
          setFormInfo(ClearInfo(formInfo));
          fetchProduct();                   // Save ProductRef
        }
      }
    }
  };

  // Fetch Product Details Using Location State //
  const fetchProduct = async () => {
    let Product = await GetGAAProductModesMaster(Location.state);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProductRef: Product.Ref,
      GAAProductName: Product.Name,
    }));
  };

  const fetchProductVersion = async () => {
    let data = await GetGAAProductVersions(id);
    setFormInfo(data);
  };

  // Fetch Product Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchProduct();
    }
    if (id) {
      fetchProductVersion();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Product Version" : "Add Product Version"}
          button={
            <LinkButton to={"/gladiance-product-version"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProductRef"}
                      labelText={"Product Name"}
                    />
                    {formInfo.GAAProductRef && (
                      <Input
                        id={"GAAProductRef"}
                        type={"text"}
                        name={"GAAProductRef"}
                        value={formInfo.GAAProductName}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"DesignId"} labelText={"Design Id"} />
                    <Input
                      inputFocus={true}
                      id={"DesignId"}
                      type={"text"}
                      name={"DesignId"}
                      value={formInfo.DesignId}
                      onChange={handleChange}
                      maxLength={12}
                    />
                  </div>
                  <div>
                    <Label
                      htmlFor={"HardwareType"}
                      labelText={"Hardware Type"}
                    />
                    <CustomSelect
                      id={"HardwareType"}
                      name={"HardwareType"}
                      value={formInfo.HardwareType}
                      onChange={handleChange}
                    >
                      <Option value={""}>--- Select Hardware Type ---</Option>
                      {HardwareTypes &&
                        HardwareTypes.map((data, id) => {
                          return (
                            <Option key={id} value={data.Value}> {data.Name} </Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label htmlFor={"FirmwareId"} labelText={"Firmware Id"} />
                    <Input
                      id={"FirmwareId"}
                      type={"text"}
                      name={"FirmwareId"}
                      value={formInfo.FirmwareId}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <Label
                      htmlFor={"FirmwareStatus"}
                      labelText={"Firmware Status"}
                    />
                    <CustomSelect
                      id={"FirmwareStatus"}
                      name={"FirmwareStatus"}
                      value={formInfo.FirmwareStatus}
                      onChange={handleChange}
                    >
                      <Option value={""}>--- Select Firmware Status ---</Option>
                      {FirmwareStatusList &&
                        FirmwareStatusList.map((data, id) => {
                          return (
                            <Option key={id} value={data.Value}>{data.Name}</Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label htmlFor={"ReleaseDate"} labelText={"Release Date"} />
                    <Input
                      id={"ReleaseDate"}
                      type={"Date"}
                      name={"ReleaseDate"}
                      value={formInfo.ReleaseDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex">
                    <Label className={"mt-2"} htmlFor={"AllowOTAUpdate"} labelText={"Allow OTA Update"} />
                    <div className={' p-2 pl-4 '}>
                      <CheckBox id={'AllowOTAUpdate'} name={'AllowOTAUpdate'} type={'checkbox'} onClick={(e) => setFormInfo({ ...formInfo, AllowOTAUpdate: e.target.checked })} checked={formInfo.AllowOTAUpdate} />
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/gladiance-product-version"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}
export default GAAProductVersionAddEdit;
