import axios from "axios";
import Router from "../Router";
let baseURLstring = "";
if (window.location.hostname.includes("enscloud.in")) {
  baseURLstring = "https://enscloud.in/gladiancedev-gladiance-web-api";
} else if (window.location.hostname.includes("gladiance.one")) {
  baseURLstring = "https://api.gladiance.one/gladiancecloud-web-api";
} else {
  //    baseURLstring = 'https://enscloud.in/gladiancedev-gladiance-web-api';
  baseURLstring = "https://api.gladiance.one/gladiancecloud-web-api";
}
const axiosClient = axios.create({
  baseURL: baseURLstring,
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "ACCESS_TOKEN"
  )}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Router.navigate("/login");
      return error;
    }
    throw error;
  }
);

export default axiosClient;
