import React, { Fragment } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'

function GuestLayout() {
    const { userToken, FRONTEND_URL } = useStateContext()
    if (userToken) {
        return (
            <Navigate to="/" />
        )
    }
    return (
        <Fragment>
            <div className='flex justify-center items-center'>
                <img src={FRONTEND_URL + "/assets/images/login.png"} className='w-screen h-screen' alt='loginimg' />
                {/* bg-[rgba(0,0,0,0.8)] */}
                
                <Outlet />
            </div>
        </Fragment>
    )
}

export default GuestLayout