import React, { Fragment, useEffect, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import Pagination from "../../helpers/Pagination";
import DataNotFound from "../../helpers/DataNotFound";
import CustomSelect from "../../helpers/CustomSelect";
import Label from "../../helpers/Label";
import Option from "../../helpers/Option";
import { GetGAAProjectListByCustomer } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetSpaceGroupListByProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup";
import { GetGAAProjectSpaceListBySpaceGroup } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace";
import { GetGladianceCustomerList } from "../../../Encapsulation/GladianceDomainEntities/Customer";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { DeleteHotelTelevision, GetHotelTelevisionListByProjectSpace } from "../../../Encapsulation/HotelDomainEntities/HotelTelevision";
import LinkButton from "../../helpers/LinkButton";
import { AddCircle } from "iconsax-react";
import { useStateContext } from "../../../context/ContextProvider";

function TelevisionMaster({Rights}) {
    document.title = "Gladiance - Television Master";
    const tableHeading = ['Sr.No', 'Name', 'IP Address', 'Operating System', 'Action'];
    const [loader, setLoader] = useState(false);
    const [CustomerList, setCustomerList] = useState([]);
    const [ProjectList, setProjectList] = useState([]);
    const [SpaceGroupList, setSpaceGroupList] = useState([]);
    const [ProjectSpaceList, setProjectSpaceList] = useState([]);
    const [TelevisionList, setTelevisionList] = useState([]); 
    const { CustomerRef, GAAProjectRef, handleCustomerRef,handleProjectRef,handleSpaceGroupRef,GAAProjectSpaceRef, handleSpaceRef,GAAProjectSpaceGroupRef, TelevisionCP, setTelevisionCP } = useStateContext();
    const itemsPerPage = 7;


    const handlePageChange = (pageNumber) => {
        setTelevisionCP(pageNumber);
    };

    // Fetch Customer List For Dropdown //
    const fetchCustomerList = async () => {
        setCustomerList(await GetGladianceCustomerList());
    };

    // Fetch Project List By Customer Ref //
    const fetchGAAProjectListByCustomer = async () => {
        CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
    };

    // Fetch Space Group List By Project Ref //
    const fetchSpaceGroupListByProject = async () => {
        GAAProjectRef && setSpaceGroupList(await GetSpaceGroupListByProject(GAAProjectRef));
    };

    // Fetch Project Space List By Space Group Ref //
    const fetchProjectSpaceListBySpaceGroup = async () => {
        GAAProjectSpaceGroupRef && setProjectSpaceList(await GetGAAProjectSpaceListBySpaceGroup(GAAProjectSpaceGroupRef));
    };


    // Fetch Television List By Project Space //
    const fetchTelevisionListByProjectSpace = async () => {
        GAAProjectSpaceRef && setTelevisionList(await GetHotelTelevisionListByProjectSpace(GAAProjectSpaceRef));
    };

    const deleteHotelTelevision = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteHotelTelevision(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchTelevisionListByProjectSpace();
                    (TelevisionList.length - 1) % itemsPerPage == 0 && setTelevisionCP(TelevisionCP - 1);

                }
            }
        })
    }

    useEffect(() => {
        fetchCustomerList();
    }, []);

    useEffect(() => {
        if (CustomerRef == '' && CustomerList.length > 0) {
            handleCustomerRef(CustomerList[0].Ref);
        }
    }, [CustomerList]);

    useEffect(() => {
        fetchGAAProjectListByCustomer();
    }, [CustomerRef]);

    useEffect(() => {
        if (GAAProjectRef == '' && ProjectList.length > 0) {
            handleProjectRef(ProjectList[0].Ref);
        }
    }, [ProjectList]);

    useEffect(() => {
        fetchSpaceGroupListByProject();
    }, [GAAProjectRef]);

    useEffect(() => {
        if (GAAProjectSpaceGroupRef == '' && SpaceGroupList.length > 0) {
            handleSpaceGroupRef(SpaceGroupList[0].Ref);
        }
    }, [SpaceGroupList]);

    useEffect(() => {
        fetchProjectSpaceListBySpaceGroup();
    }, [GAAProjectSpaceGroupRef]);

    useEffect(() => {
        if (GAAProjectSpaceRef == '' &&  ProjectSpaceList.length > 0) {
            handleSpaceRef(ProjectSpaceList[0].Ref);
        }
    }, [ProjectSpaceList]);

    useEffect(() => {
        fetchTelevisionListByProjectSpace();
    }, [GAAProjectSpaceRef]);

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <AddComponent
                    title={"Television Master"} button={
                        <LinkButton state={GAAProjectSpaceRef} to={'/television-master/add-television'} className={Rights.Add ? "opacity-100" : "hidden"}>
                            <AddCircle variant='Bold' className='w-5 h-5' />
                            <span>Add Television</span>
                        </LinkButton>
                    }>
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-4'>
                        <div>
                            <Label
                                htmlFor={"Customer"}
                                labelText={"Customer"}
                            />
                            <CustomSelect
                                id={"CustomerRef"}
                                name={"CustomerRef"}
                                value={CustomerRef}
                                onChange={(e) => handleCustomerRef(e.target.value)}
                                required={true}
                            >
                                {CustomerList && CustomerList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label htmlFor={'ProjectRef'} labelText={'Project'} />
                            <CustomSelect id={'ProjectRef'} name={'ProjectRef'} value={GAAProjectRef} onChange={(e) => handleProjectRef(e.target.value)}>
                                {
                                    ProjectList && ProjectList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </div>
                        <div>
                            <Label htmlFor={'SpaceGroupRef'} labelText={'Space Group'} />
                            <CustomSelect id={'SpaceGroupRef'} name={'SpaceGroupRef'} value={GAAProjectSpaceGroupRef} onChange={(e) => handleSpaceGroupRef(e.target.value)}>
                                {
                                    SpaceGroupList && SpaceGroupList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </div>
                        <div>
                            <Label htmlFor={'ProjectSpaceRef'} labelText={'Project Space'} />
                            <CustomSelect id={'ProjectSpaceRef'} name={'ProjectSpaceRef'} value={GAAProjectSpaceRef} onChange={(e) => handleSpaceRef(e.target.value)}>
                                {
                                    ProjectSpaceList && ProjectSpaceList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </div>
                    </div>
                    {
                        TelevisionList && TelevisionList.length > 0 ? (
                            <div className="m-2">
                                <Table tableHeading={tableHeading}>
                                    {TelevisionList &&
                                        TelevisionList
                                            .slice(
                                                (TelevisionCP - 1) * itemsPerPage,
                                                TelevisionCP * itemsPerPage
                                            )
                                            .map((tableData, tableDataID) => {
                                                const serialNumber = (TelevisionCP - 1) * itemsPerPage + tableDataID + 1;
                                                return (
                                                    <Fragment key={tableDataID}>
                                                        <tr className="border-b border-opacity-30 border-white">
                                                            <td className="px-4 py-3">{serialNumber}</td>
                                                            <td className="px-4 py-3">{tableData.Name}</td>
                                                            <td className="px-4 py-3">{tableData.IPAddress}</td>
                                                            <td className="px-4 py-3">{tableData.TelevisionOperatingSystemName}</td>
                                                            <td className="px-4 py-3">
                                                                <div className="flex justify-center gap-3">
                                                                    <Link
                                                                        to={`/television-master/edit-television/${tableData.Ref}`}
                                                                        title="Edit"
                                                                    >
                                                                        <ActionButton
                                                                            className={
                                                                                "p-1.5 text-white bg-yellow-400 "
                                                                            }
                                                                        >
                                                                            <PencilSquareIcon className="w-4 h-4" />
                                                                        </ActionButton>
                                                                    </Link>
                                                                    {Rights.Delete && (
                                                                    <ActionButton onClick={() => deleteHotelTelevision(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                        <TrashIcon className='w-4 h-4' />
                                                                    </ActionButton>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                );
                                            })}
                                </Table>
                            </div>
                        ) : (
                            <DataNotFound labelText={"Sorry! Hotel Television Data Not Found"} />
                        )}
                    {TelevisionList && TelevisionList.length > 7 ? (
                        <div className="flex justify-end">
                            <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={TelevisionList.length}
                                currentPage={TelevisionCP}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </AddComponent>
            )}
        </Fragment>
    );
}

export default TelevisionMaster;
