import React, { Fragment, useEffect, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import axiosClient from "../../../Encapsulation/Axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import ActionButton from "../../helpers/ActionButton";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import {
  DeleteGAAProductOutputDriverChannel,
  GetGAAProductOutputDriverChannelList,
  GetGAAProductOutputDriverChannelListByProduct,
} from "../../../Encapsulation/GladianceDomainEntities/GAAProductOutputDriverChannel";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProductModesMasterList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster";
import Label from "../../helpers/Label";
import Pagination from "../../helpers/Pagination";
import { useStateContext } from "../../../context/ContextProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function GAAProductOutputDriverChannel() {
  document.title = "Gladiance - Output Driver";
  const tableHeading = ["Sr.No", "Name", "Device No.", "Device Type", "Action"];
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [productList, setProductList] = useState([]);
  const [
    GAAProductOutputDriverChannelList,
    setGAAProductOutputDriverChannelList,
  ] = useState([]);

  const { GAAProductOutputRef, setGAAProductOutputRef, ProuctOutputChannelCP, setProuctOutputChannelCP } = useStateContext();

  // Fetch Product List For Dropdown //
  const fetchProductList = async () => {
    setProductList(
      (await GetGAAProductModesMasterList()).filter(
        (e) =>
          e.GAAProductOperationMode == 20 || e.GAAProductOperationMode == 30
      )
    );
  };

  const fetchGAAProductOutputDriverChannelListByProduct = async () => {
    GAAProductOutputRef &&
      setGAAProductOutputDriverChannelList(
        await GetGAAProductOutputDriverChannelListByProduct(GAAProductOutputRef)
      );
  };

  const handlePageChange = (pageNumber) => {
    setProuctOutputChannelCP(pageNumber);
  };

  const deleteProductOutputDriverChannel = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProductOutputDriverChannel(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchGAAProductOutputDriverChannelListByProduct();
          (GAAProductOutputDriverChannelList.length - 1) % itemsPerPage == 0 && setProuctOutputChannelCP(ProuctOutputChannelCP - 1);

        }
      }
    });
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  useEffect(() => {
    if (GAAProductOutputRef == "" && productList.length > 0) {
      setGAAProductOutputRef(productList[0].Ref);
    }
  }, [productList]);

  useEffect(() => {
    fetchGAAProductOutputDriverChannelListByProduct();
  }, [GAAProductOutputRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Product Output Channels"}
          button={
            <LinkButton
              to={
                "/product-output-driver-channel-master/add-product-output-driver-channel-master"
              }
              state={GAAProductOutputRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Product Output Channel</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"GAAProductOutputRef"} labelText={" Product Name"} />
              <CustomSelect
                id={"GAAProductOutputRef"}
                name={"GAAProductOutputRef"}
                value={GAAProductOutputRef}
                onChange={(e) => setGAAProductOutputRef(e.target.value)}
                required={true}
              >
                {productList.map((data, id) => {
                  return (
                    <Option key={id} value={data.Ref}>{data.Name}</Option>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {GAAProductOutputDriverChannelList &&
            GAAProductOutputDriverChannelList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {GAAProductOutputDriverChannelList.slice(
                  (ProuctOutputChannelCP - 1) * itemsPerPage,
                  ProuctOutputChannelCP * itemsPerPage
                ).map(
                  (tableData, tableDataID) => {
                    const serialNumber = (ProuctOutputChannelCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            {tableData.DisplayOrder}
                          </td>
                          <td className="px-4 py-3">
                            {
                              tableData.GAAProductOutputDriverChannelDeviceTypeSingularName
                            }
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/product-output-driver-channel-master/edit-product-output-driver-channel-master/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              <ActionButton
                                onClick={() =>
                                  deleteProductOutputDriverChannel(tableData)
                                }
                                title={"Delete"}
                                className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </ActionButton>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  }
                )}
              </Table>
            </div>
          ) : (
            <DataNotFound
              labelText={"Sorry! Product Output Channel Data Not Found"}
            />
          )}
          {GAAProductOutputDriverChannelList &&
            GAAProductOutputDriverChannelList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={GAAProductOutputDriverChannelList.length}
                currentPage={ProuctOutputChannelCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}
export default GAAProductOutputDriverChannel;
