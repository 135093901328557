import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../Encapsulation/Axios'
import flasher from '@flasher/flasher'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddComponent from '../../components/AddComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import CustomSelect from '../helpers/CustomSelect'
import { useStateContext } from '../../context/ContextProvider'
import { ArrowCircleLeft2, DirectInbox } from 'iconsax-react'
import BackComponent from '../../components/BackComponent'
import SaveButton from '../helpers/SaveButton'
import { SaveGladianceVertical } from '../../Encapsulation/GladianceDomainEntities/GladianceVertical'
import { ClearInfo } from '../../Encapsulation/Utils'

function VerticalsAddEdit() {
    let formMode = 'New';
    const { id } = useParams()
    if (id) {
        document.title = 'Gladiance - Vericals'
        formMode = 'Edit'
    } else {
        document.title = 'Gladiance - Vericals'
    }
    const tableHeading = ['Sr. No.', 'Business Vertical', 'Select Image', 'View Image', 'Action']
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [formInfo, setFormInfo] = useState({})
    const [imgInfo, setImgInfo] = useState({})
    const { FRONTEND_URL } = useStateContext()

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGladianceVertical(id, formInfo,
            [
                ['verticalImg', imgInfo.verticalImg]
            ], setErrors);
        console.log("result :", result);
        setFormInfo(ClearInfo(formInfo))
        setImgInfo(ClearInfo(imgInfo))
    };


    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Verticals' : 'Add Verticals'}>
                    <div className='flex'>
                        <Card className={'w-full p-6'}>
                            <Table tableHeading={tableHeading}>
                                <tr className="text-center">
                                    <td className="px-4 py-3">1</td>
                                    <td className="px-4 py-3">Hotels</td>
                                    <td className="px-4 py-3">
                                        <label for="verticalImg" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center">
                                                <svg aria-hidden="true" className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            </div>
                                            <input id="verticalImg" name='verticalImg' type="file" onChange={(e) => setImgInfo({ ...imgInfo, verticalImg: e.target.files[0] })} className="hidden p-[0px!important] mt-2" />
                                        </label>
                                    </td>
                                    <td className="px-4 py-3 justify-center flex">
                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300'>
                                                {/* <img src={imgInfo.verticalImg ? (
                                                    imgInfo.verticalImg.name ? URL.createObjectURL(imgInfo.verticalImg) : FRONTEND_URL + "/assets/images/verticalImg/" + imgInfo.verticalImg
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' /> */}
                                                <img src={imgInfo.verticalImg ? ((imgInfo.verticalImg.name) ? URL.createObjectURL(imgInfo.verticalImg) : (FRONTEND_URL + '/assets/images/UserImages/' + imgInfo.verticalImg)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-12 h-12 rounded' />

                                            </Link>
                                    </td>
                                  <td className="px-4">
                                        <div className="flex flex-col items-center justify-center w-full">
                                            <SaveButton onClick={(e)=>handleSubmit(e)} text='Save' />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="text-center">
                                    <td className="px-4 py-3">2</td>
                                    <td className="px-4 py-3">Homes</td>
                                    <td className="px-4 py-3">
                                        <label for="verticalImg" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center">
                                                <svg aria-hidden="true" className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            </div>
                                            <input id="verticalImg" name='verticalImg' type="file" onChange={(e) => setImgInfo(e.target.files[0])} className="hidden p-[0px!important] mt-2" />
                                        </label>
                                    </td>
                                    <td className="px-4 py-3 justify-center flex">
                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300'>
                                                {/* <img src={imgInfo.verticalImg ? (
                                                    imgInfo.verticalImg.name ? URL.createObjectURL(imgInfo.verticalImg) : FRONTEND_URL + "/assets/images/verticalImg/" + imgInfo.verticalImg
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' /> */}
                                                <img src={imgInfo.verticalImg ? ((imgInfo.verticalImg.name) ? URL.createObjectURL(imgInfo.verticalImg) : (FRONTEND_URL + '/assets/images/UserImages/' + imgInfo.verticalImg)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-12 h-12 rounded' />

                                            </Link>
                                    </td>
                                  <td className="px-4">
                                        <div className="flex flex-col items-center justify-center w-full">
                                            <SaveButton onClick={(e)=>handleSubmit(e)} text='Save' />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="text-center">
                                    <td className="px-4 py-3">3</td>
                                    <td className="px-4 py-3">Offices</td>
                                    <td className="px-4 py-3">
                                        <label for="verticalImg" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center">
                                                <svg aria-hidden="true" className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            </div>
                                            <input id="verticalImg" name='verticalImg' type="file" onChange={(e) => setImgInfo(e.target.files[0])} className="hidden p-[0px!important] mt-2" />
                                        </label>
                                    </td>
                                    <td className="px-4 py-3 justify-center flex">
                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300'>
                                                {/* <img src={imgInfo.verticalImg ? (
                                                    imgInfo.verticalImg.name ? URL.createObjectURL(imgInfo.verticalImg) : FRONTEND_URL + "/assets/images/verticalImg/" + imgInfo.verticalImg
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' /> */}
                                                <img src={imgInfo.verticalImg ? ((imgInfo.verticalImg.name) ? URL.createObjectURL(imgInfo.verticalImg) : (FRONTEND_URL + '/assets/images/UserImages/' + imgInfo.verticalImg)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-12 h-12 rounded' />

                                            </Link>
                                    </td>
                                  <td className="px-4">
                                        <div className="flex flex-col items-center justify-center w-full">
                                            <SaveButton onClick={(e)=>handleSubmit(e)} text='Save' />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="text-center">
                                    <td className="px-4 py-3">4</td>
                                    <td className="px-4 py-3">Retails</td>
                                    <td className="px-4 py-3">
                                        <label for="verticalImg" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center">
                                                <svg aria-hidden="true" className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            </div>
                                            <input id="verticalImg" name='verticalImg' type="file" onChange={(e) => setImgInfo(e.target.files[0])} className="hidden p-[0px!important] mt-2" />
                                        </label>
                                    </td>
                                    <td className="px-4 py-3 justify-center flex">
                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300'>
                                                {/* <img src={imgInfo.verticalImg ? (
                                                    imgInfo.verticalImg.name ? URL.createObjectURL(imgInfo.verticalImg) : FRONTEND_URL + "/assets/images/verticalImg/" + imgInfo.verticalImg
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' /> */}
                                                <img src={imgInfo.verticalImg ? ((imgInfo.verticalImg.name) ? URL.createObjectURL(imgInfo.verticalImg) : (FRONTEND_URL + '/assets/images/UserImages/' + imgInfo.verticalImg)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-12 h-12 rounded' />

                                            </Link>
                                    </td>
                                  <td className="px-4">
                                        <div className="flex flex-col items-center justify-center w-full">
                                            <SaveButton onClick={(e)=>handleSubmit(e)} text='Save' />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="text-center">
                                    <td className="px-4 py-3">5</td>
                                    <td className="px-4 py-3">Hospitals</td>
                                    <td className="px-4 py-3">
                                        <label for="verticalImg" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                            <div className="flex flex-col items-center justify-center">
                                                <svg aria-hidden="true" className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            </div>
                                            <input id="verticalImg" name='verticalImg' type="file" onChange={(e) => setImgInfo(e.target.files[0])} className="hidden p-[0px!important] mt-2" />
                                        </label>
                                    </td>
                                    <td className="px-4 py-3 justify-center flex">
                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300'>
                                                {/* <img src={imgInfo.verticalImg ? (
                                                    imgInfo.verticalImg.name ? URL.createObjectURL(imgInfo.verticalImg) : FRONTEND_URL + "/assets/images/verticalImg/" + imgInfo.verticalImg
                                                ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' /> */}
                                                <img src={imgInfo.verticalImg ? ((imgInfo.verticalImg.name) ? URL.createObjectURL(imgInfo.verticalImg) : (FRONTEND_URL + '/assets/images/UserImages/' + imgInfo.verticalImg)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-12 h-12 rounded' />

                                            </Link>
                                    </td>
                                    <td className="px-4">
                                        <div className="flex flex-col items-center justify-center w-full">
                                            <SaveButton onClick={(e)=>handleSubmit(e)} text='Save' />
                                        </div>
                                    </td>
                                </tr>
                            </Table>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}
export default VerticalsAddEdit


