import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'


function PageNotFound({ }) {
    const { FRONTEND_URL } = useStateContext()

    return (
        <Fragment>
            <main>
                <div className="bg-default-color h-[100vh]">
                    <div className="h-24 md:h-48"></div>
                    <h1 className='flex justify-center items-center text-3xl font-bold'> </h1>
                    <div className="grid grid-cols-1 md:grid-cols-3">
                        <div></div>
                        <img src={FRONTEND_URL + '/assets/images/not_found.gif'} className='w-full' alt="loader" />
                        <div></div>
                    </div>
                    <h1 className='flex justify-center text-[#1d475b] items-center text-3xl font-bold'> Page Not Founddd !</h1>
                </div>
            </main>
        </Fragment>
    )
}

export default PageNotFound