import React, { Fragment, useEffect, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteHotelPromotion, GetHotelPromotionList, GetProjectPromotionListByProject } from '../../../Encapsulation/HotelDomainEntities/HotelPromotion'
import Swal from 'sweetalert2'
import Option from "../../helpers/Option";

import flasher from '@flasher/flasher'
import { useStateContext } from '../../../context/ContextProvider'
import CustomSelect from '../../helpers/CustomSelect'
import Label from '../../helpers/Label'
import { GetGladianceCustomerList } from '../../../Encapsulation/GladianceDomainEntities/Customer'
import { GetGAAProjectListByCustomer } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'

function PromotionsManagement() {
  document.title = 'Gladiance - Promotion Management'
  const tableHeading = ['Sr.No', 'Event', 'Description',,'VideoUrl', 'Action'];
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const { CustomerRef, GAAProjectRef, handleProjectRef, handleCustomerRef, PromotionCP,
    setPromotionCP } = useStateContext();
  const itemsPerPage = 7; 
   const [promotionList, setPromotionList] = useState([])
  console.log('promotionList :', promotionList);


   //Fetch Customer List For Dropdown//
   const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  // Fetch Project List For Dropdown //
  const fetchProjectListByCustomer = async () => {
    CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };


  const fetchpromotionListByProject = async () => {
    GAAProjectRef &&
    setPromotionList(await GetProjectPromotionListByProject(GAAProjectRef));
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const deletePromotion = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteHotelPromotion(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchpromotionListByProject();
          (promotionList.length - 1) % itemsPerPage == 0 && setPromotionCP(PromotionCP - 1);

        }
      }
    })
  }
  

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == '' && ProjectList.length > 0) {
      handleProjectRef(ProjectList[0].Ref);
    }
  }, [ProjectList]);

  useEffect(() => {
    fetchpromotionListByProject();
  }, [GAAProjectRef]);



  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <AddComponent title={'Promotion Managements'} button={
          <LinkButton to={'/promotion-management/add-promotion-management'} state={GAAProjectRef}>
            <AddCircle variant='Bold' className='w-5 h-5' />
            <span>Add Promotion</span>
          </LinkButton>
        }>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option key={id} value={data.Ref}>{data.Name}</Option>

                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAprojectRef"}
                labelText={"Project"}
              />
              <CustomSelect
                id={"GAAprojectRef"}
                name={"GAAprojectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {ProjectList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {promotionList && promotionList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {promotionList
                  .slice(
                    (PromotionCP - 1) * itemsPerPage,
                    PromotionCP * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    const serialNumber = (PromotionCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                    <Fragment key={tableDataID}>
                      <tr className="border-b border-opacity-30 border-white text-center">
                      <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.EventName}</td>
                          <td className="px-4 py-3">
                            {tableData.Description}
                          </td>
                          <td className="px-4 py-3">
                            < a href={tableData.VideoUrl}><span className="text-blue-400">{tableData.VideoUrl}</span>
                            </a>
                          </td>

                          {/* <div className="col-start-1 col-span-3">
                              Video url : <a href={tableData.VideoURL}> <span className="text-blue-400">{tableData.VideoURL}</span>
                              </a>
                            </div> */}
                        <td className="px-4 py-3">
                          <div className='flex justify-center gap-3'>
                            <Link to={`/promotion-management/edit-promotion-management/${tableData.Ref}`}
                                title="Edit" ><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                              <PencilSquareIcon className='w-4 h-4' />
                            </ActionButton></Link>
                            <ActionButton onClick={() => deletePromotion(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                              <TrashIcon className='w-4 h-4' />
                            </ActionButton>
                          </div>
                        </td>
                      </tr>

                    </Fragment>
                  )
                })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={'Sorry! Promotion Management Data Not Found'} />
          )}
          {promotionList && promotionList.length > 7 ? (<div className="flex justify-end">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={promotionList.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>) : (<div></div>)
          }
        </AddComponent>
      )}
    </Fragment>
  )
}

export default PromotionsManagement

