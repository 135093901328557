import React, { Fragment, useEffect, useState } from 'react'
import Card from '../views/helpers/Card'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import SubDashboard from '../components/SubDashboard';
import { NewDashboard } from '../components/NewDashboard';
import axios from 'axios';
import Chart from '../components/Chart';
import BackComponent from '../components/BackComponent';
import { useStateContext } from '../context/ContextProvider';
function Dashboard() {
    const { theme } = useStateContext()
    document.title = 'Gladiance - Dashboard'

    return (
        <Fragment>
            <BackComponent >
                <h1 className={(theme ? 'text-3xl text-default-color' : 'text-3xl text-gray-800 ') + " font-bold tracking-tight text-center"}> Gladiance Dashboard</h1>
                <div className="p-8">
                    <div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103 ">
                                    <thead className="bg-orange-400 ">
                                        <tr >
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Sign Up Requests</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Approved</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>90</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>75</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>15</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead className="bg-orange-400">
                                        <tr>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Designer</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Instant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>75</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>15</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                <thead className="bg-orange-400">
                                    <tr>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Designer Requests</th>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Approved</th>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Pending</th>
                                    </tr>
                                    <tr className="bg-gray-100">
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Total</th>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Active</th>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Pending</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-gray-100">
                                        <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>100</td>
                                        <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>98</td>
                                        <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>2</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div >
                            <table className={"w-full text-center border-collapse mb-8 hover:-translate-y-1 hover:scale-103"}>
                                <thead className="bg-orange-400">
                                    <tr>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total</th>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Activated</th>
                                        <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Non activated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-gray-100">
                                        <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>10347</td>
                                        <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>10301</td>
                                        <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>46</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <h1 className={(theme ? 'text-3xl text-default-color' : 'text-3xl text-gray-800 ') + " font-bold tracking-tight text-center"}> Customer Dashboard</h1>
                <div className="p-8">
                    <div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">

                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-gray-800 text-lg') + " p-4"} colSpan="3">Gladiance Devices</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Planned</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Installed</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>100</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>98</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="2">Gladiance Devices</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Online </th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Offline</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>97</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="3">Check In & Check Outs</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Booked Rooms</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Checked In</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Checked Out</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>100</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>98</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div >
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="2">Housekeeping</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Rooms</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Housekeeping</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>100</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>70</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="2">Privacy</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Rooms</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Privacy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>100</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>10</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="w-full text-center border-collapse  mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="3">In Room Dining</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Orders</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Completed</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Pending </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>109</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>103</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>6</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="3">Laundry Pick Up</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Pick Up Requests </th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Completed</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>9</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>3</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>6</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="3">Laundry Delivery</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Delivery Requests</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Completed</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Pending</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>55</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>5</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>50</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="2">Messages</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Messages Sent</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Messages Read</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>100</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>10</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="2">Emergency Requests</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Request Received</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Attended</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>1</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="4">Air Quality</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Total Sensors</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Good</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Moderate</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Poor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>80</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>80</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>4</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>4</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="w-full text-center border-collapse mb-12 hover:-translate-y-1 hover:scale-103">
                                    <thead>
                                        <tr>
                                            <th className={(theme ? 'text-default-color text-lg' : 'text-lg text-gray-800 ') + " p-4"} colSpan="5">Feedback</th>
                                        </tr>
                                        <tr className="bg-orange-400">
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tl-xl overflow-auto"}>Check In</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Ambiance</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>In Room Dining</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>Housekeeping</th>
                                            <th className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-tr-xl overflow-auto"}>Staff</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-gray-100">
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-bl-xl overflow-auto"}>5/5</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>4/5</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>5/5</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black "}>4/5</td>
                                            <td className={(theme ? 'border border-gray-800 p-3' : 'border border-gray-200 p-3') + " text-black  rounded-br-xl overflow-auto"}>3/5</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </BackComponent>
        </Fragment>
    )
}

export default Dashboard