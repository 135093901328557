import { createContext, useContext, useState } from "react";

const StateContext = createContext({
  currentUser: {},
  setCurrentUser: () => { },
  userToken: null,
  setUserToken: () => { },
  FRONTEND_URL: null,
  BACKEND_URL: null,
});

export const ContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [idParam, setIdParam] = useState("");
  const [successful, setSuccessful] = useState("");
  const [theme, setTheme] = useState(true);
  const [userToken, _setUserToken] = useState(
    localStorage.getItem("ACCESS_TOKEN")
  );
  const setUserToken = (token) => {
    token ? localStorage.setItem("ACCESS_TOKEN", token) : localStorage.clear();
    _setUserToken(token);
  };

  const [GAAProductRef, setGAAProductRef] = useState("");
  const [GAAProductInputRef, setGAAProductInputRef] = useState("");
  const [GAAProductOutputRef, setGAAProductOutputRef] = useState("");
  const [CustomerRef, setCustomerRef] = useState("");
  const [GAAProjectRef, setGAAProjectRef] = useState("");
  const [GAADepartmentRef, setGAADepartmentRef] = useState("");
  const [GAAProjectSpaceTypeRef, setGAAProjectSpaceTypeRef] = useState("");
  const [GAAProjectSpaceTypeAreaRef, setGAAProjectSpaceTypeAreaRef] =
    useState("");
  const [GAAProjectSpaceGroupRef, setGAAProjectSpaceGroupRef] = useState("");
  const [GAAProjectSpaceRef, setGAAProjectSpaceRef] = useState("");
  const [GAAProjectRBItemRef, setGAAProjectRBItemRef] = useState();
  const [GAAProjectSceneRef, setGAAProjectSceneRef] = useState();
  const [GAAProjectSpaceTypePlannedDeviceRef, setGAAProjectSpaceTypePlannedDeviceRef] = useState();


  // -------------------------------- Current Page Start ---------------------------------------------------- //

  const [DepartmentCP, setDepartmentCP] = useState(1);
  const [JobTitleCP, setJobTitleCP] = useState(1);
  const [UserRoleCP, setUserRoleCP] = useState(1);
  const [UserRightCP, setUserRightCP] = useState(1);
  const [UserCP, setUserCP] = useState(1);
  const [ProuctOutputFunctionIdsCP, setProuctOutputFunctionIdsCP] = useState(1);
  const [ProuctOutputFunctionTypesCP, setProuctOutputFunctionTypesCP] =
    useState(1);
  const [ProuctInventoryCP, setProuctInventoryCP] = useState(1);
  const [ProuctInputChannelCP, setProuctInputChannelCP] = useState(1);
  const [
    ProuctOutputChannelDeviceTypesCP,
    setProuctOutputChannelDeviceTypesCP,
  ] = useState(1);
  const [ProuctOutputChannelCP, setProuctOutputChannelCP] = useState(1);
  const [ProuctVersionCP, setProuctVersionCP] = useState(1);
  const [SolutionInventoryCP, setSolutionInventoryCP] = useState(1);
  const [CountryInventoryCP, setCountryInventoryCP] = useState(1);
  const [SpaceGroupCP, setSpaceGroupCP] = useState(1);
  const [SpaceTypeAreaCP, setSpaceTypeAreaCP] = useState(1);
  const [ProjectSpaceCP, setProjectSpaceCP] = useState(1);
  const [AreaWifiConfigCP, setAreaWifiConfigCP] = useState(1);
  const [ProjectCP, setProjectCP] = useState(1);
  const [ProjectDepartmentCP, setProjectDepartmentCP] = useState(1);
  const [ProjectJobTitleCP, setProjectJobTitleCP] = useState(1);
  const [ProjectUserRoleCP, setProjectUserRoleCP] = useState(1);
  const [ProjectUserCP, setProjectUserCP] = useState(1);
  const [CustomerCP, setCustomerCP] = useState(1);
  const [ProgrammingRequesitionCP, setProgrammingRequesitionCP] = useState(1);
  const [CountryMasterCP, setCountryMasterCP] = useState(1);
  const [RBItemServingSlotCP, setRBItemServingSlotCP] = useState(1);
  const [RBItemApplicableServingSlotCP, setRBItemApplicableServingSlotCP] =
    useState(1);
  const [RBItemMasterCP, setRBItemMasterCP] = useState(1);
  const [LaundryCP, setLaundryCP] = useState(1);
  const [PromotionCP, setPromotionCP] = useState(1);
  const [SurveillanceCP, setSurveillanceCP] = useState(1);
  const [TelevisionCP, setTelevisionCP] = useState(1);
  const [DoorCameraCP, setDoorCameraCP] = useState(1);
  const [PlannedDeviceCP, setPlannedDeviceCP] = useState(1);
  const [PlannedDeviceConnectionCP, setPlannedDeviceConnectionCP] = useState(1);
  const [RoomSceneCP, setRoomSceneCP] = useState(1);
  const [ScheduleCP, setScheduleCP] = useState(1);
  const [RoomSceneConfigCP, setRoomSceneConfigCP] = useState(1);
  const [TelephoneCP, setTelephoneCP] = useState(1);
  const [KeyContactCP, setKeyContactCP] = useState(1);
  const [FeedbackCP, setFeedbackCP] = useState(1);
  const [AmenitiesCP, setAmenitiesCP] = useState(1);
  const [SpaceTypeCP, setSpaceTypeCP] = useState(1);

  // -------------------------------Current Page End -------------------------------------------//

  function handleCustomerRef(value) {
    setCustomerRef(value);
    setGAAProjectRef("");
  }

  function handleProjectRef(value) {
    setGAAProjectRef(value);
    setGAADepartmentRef("");
    setGAAProjectSpaceGroupRef("");
    setGAAProjectSpaceTypeRef("");
    setGAAProjectSceneRef("");
    setGAAProjectRBItemRef("");
  }

  function handleDepartmentRef(value) {
    setGAADepartmentRef(value);
  }

  function handleSpaceTypeRef(value) {
    setGAAProjectSpaceTypeRef(value);
    setGAAProjectSpaceTypeAreaRef("");
    setGAAProjectSpaceTypePlannedDeviceRef("");
  }

  function handleSpaceTypeAreaRef(value) {
    setGAAProjectSpaceTypeAreaRef(value);
    setGAAProjectSpaceTypePlannedDeviceRef("");
  }

  function handleSpaceGroupRef(value) {
    setGAAProjectSpaceGroupRef(value);
    setGAAProjectSpaceRef("");
  }

  function handleSpaceRef(value) {
    setGAAProjectSpaceRef(value);
  }
  function handleRBItemRef(value) {
    setGAAProjectRBItemRef(value);
  }

  function handlePlannedDeviceRef(value) {
    setGAAProjectSpaceTypePlannedDeviceRef(value);
  }

  function handleRoomSceneRef(value) {
    setGAAProjectSceneRef(value);
  }


  function handleScheduleRef(value) {
    setGAAProjectSceneRef(value);
  }


  // Online  URL'S //
  // const FRONTEND_URL = "https://gladiancedev.enscloud.in";
  const FRONTEND_URL = 'https://www.gladiance.one';

  return (
    <StateContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        userToken,
        setUserToken,
        FRONTEND_URL,
        theme,
        setTheme,
        idParam,
        setIdParam,
        successful,
        setSuccessful,

        //------------------------------------ Dropdown  useState Start  -----------------------------------------------//

        CustomerRef,
        setCustomerRef,
        GAAProjectRef,
        setGAAProjectRef,
        GAADepartmentRef,
        setGAADepartmentRef,
        GAAProjectSpaceTypeRef,
        setGAAProjectSpaceTypeRef,
        GAAProjectSpaceGroupRef,
        setGAAProjectSpaceGroupRef,
        handleCustomerRef,
        handleProjectRef,
        handleDepartmentRef,
        handleSpaceTypeRef,
        handleSpaceTypeAreaRef,
        handleSpaceGroupRef,
        handleSpaceRef,
        handleRoomSceneRef,


        handleScheduleRef,


        handleRBItemRef,
        handlePlannedDeviceRef,
        GAAProjectSpaceTypeAreaRef,
        setGAAProjectSpaceTypeAreaRef,
        GAAProjectSpaceRef,
        setGAAProjectSpaceRef,
        GAAProjectRBItemRef,
        setGAAProjectRBItemRef,
        GAAProductRef,
        setGAAProductRef,
        GAAProductInputRef,
        setGAAProductInputRef,
        GAAProductOutputRef,
        setGAAProductOutputRef,
        GAAProjectSceneRef,
        setGAAProjectSceneRef,
        GAAProjectSpaceTypePlannedDeviceRef,
        setGAAProjectSpaceTypePlannedDeviceRef,

        //-------------------------------------- Dropdown useState End  ---------------------------------------------------//


        //--------------------------------------- Pagination useState Start  ---------------------------------------------//
        DepartmentCP,
        setDepartmentCP,
        JobTitleCP,
        setJobTitleCP,
        UserRoleCP,
        setUserRoleCP,
        UserRightCP,
        setUserRightCP,
        UserCP,
        setUserCP,
        ProuctOutputFunctionIdsCP,
        setProuctOutputFunctionIdsCP,
        ProuctOutputFunctionTypesCP,
        setProuctOutputFunctionTypesCP,
        ProuctInventoryCP,
        setProuctInventoryCP,
        ProuctInputChannelCP,
        setProuctInputChannelCP,
        ProuctOutputChannelDeviceTypesCP,
        setProuctOutputChannelDeviceTypesCP,
        ProuctOutputChannelCP,
        setProuctOutputChannelCP,
        ProuctVersionCP,
        setProuctVersionCP,
        SolutionInventoryCP,
        setSolutionInventoryCP,
        CountryInventoryCP,
        setCountryInventoryCP,
        SpaceTypeAreaCP,
        setSpaceTypeAreaCP,
        ProjectSpaceCP,
        setProjectSpaceCP,
        AreaWifiConfigCP,
        setAreaWifiConfigCP,
        ProjectCP,
        setProjectCP,
        ProjectDepartmentCP,
        setProjectDepartmentCP,
        ProjectJobTitleCP,
        setProjectJobTitleCP,
        ProjectUserRoleCP,
        setProjectUserRoleCP,
        ProjectUserCP,
        setProjectUserCP,
        CustomerCP,
        setCustomerCP,
        ProgrammingRequesitionCP,
        setProgrammingRequesitionCP,
        CountryMasterCP,
        setCountryMasterCP,
        SpaceTypeCP,
        setSpaceTypeCP,
        SpaceGroupCP,
        setSpaceGroupCP,
        RBItemServingSlotCP,
        setRBItemServingSlotCP,
        RBItemApplicableServingSlotCP,
        setRBItemApplicableServingSlotCP,
        RBItemMasterCP,
        setRBItemMasterCP,
        LaundryCP,
        setLaundryCP,
        PromotionCP,
        setPromotionCP,
        SurveillanceCP,
        setSurveillanceCP,
        TelevisionCP,
        setTelevisionCP,
        DoorCameraCP,
        setDoorCameraCP,
        PlannedDeviceCP,
        setPlannedDeviceCP,
        PlannedDeviceConnectionCP,
        setPlannedDeviceConnectionCP,
        RoomSceneCP,
        setRoomSceneCP,
        RoomSceneConfigCP,
        setRoomSceneConfigCP,

        ScheduleCP,
        setScheduleCP,

        TelephoneCP,
        setTelephoneCP,
        KeyContactCP,
        setKeyContactCP,
        FeedbackCP,
        setFeedbackCP,
        AmenitiesCP,
        setAmenitiesCP,
        //----------------------------------------- Pagination useState End  ----------------------------------------------//

      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
