import React, { useEffect, Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetGAAProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { ClearInfo, RBItemCategoryList, RBItemTasteList, } from "../../../../Encapsulation/Utils";
import Loader from "../../../Common/Loader";
import BackComponent from "../../../../components/BackComponent";
import { ArrowCircleLeft2 } from "iconsax-react";
import Card from "../../../helpers/Card";
import Input from "../../../helpers/Input";
import Label from "../../../helpers/Label";
import Validation from "../../../helpers/Validation";
import SaveButton from "../../../helpers/SaveButton";
import CancelButton from "../../../helpers/CancelButton";
import LinkButton from "../../../helpers/LinkButton";
import flasher from "@flasher/flasher";
import CustomSelect from "../../../helpers/CustomSelect";
import Option from "../../../helpers/Option";
import { GetRBItemMaster, SaveRBItemMaster, } from "../../../../Encapsulation/HotelDomainEntities/RBItemMaster";
import TextArea from "../../../helpers/TextArea";

function GAAProjectRBItemMasterAddEdit({ Rights }) {
  let formMode = "New";
  const { id } = useParams();
  if (id) {
    document.title = "Gladiance - Edit RB item Master";
    formMode = "Edit";
  } else {
    document.title = "Gladiance - Add RB item Master";
  }
  const Navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const Location = useLocation();

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveRBItemMaster(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/rb-item-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProject();
      }
    }
  };
  const fetchProject = async () => {
    let Project = await GetGAAProject(Location.state);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name,
    }));
  };

  const fetchRBItemMaster = async () => {
    setFormInfo(await GetRBItemMaster(id));
  };

  useEffect(() => {
    if (Location.state) {
      fetchProject();
    }
    if (id) {
      fetchRBItemMaster();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit RB item Master" : "Add RB item Master"}
          button={
            <LinkButton to={"/rb-item-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectName"}
                      labelText={"Project Name"}
                    />
                    {formInfo.GAAProjectRef && (
                      <Input
                        id={"GAAProjectName"}
                        type={"text"}
                        name={"GAAProjectName"}
                        value={formInfo.GAAProjectName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Item Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      inputFocus={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={"VideoURL"} labelText={"Video URL"} />
                    <TextArea
                      rows={1}
                      id={"VideoURL"}
                      type={"text"}
                      name={"VideoURL"}
                      value={formInfo.VideoURL}
                      onChange={handleChange}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.VideoURL} />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectRBItemCategory"}
                      labelText={"RB Item Category"}
                    />
                    <CustomSelect
                      id={"GAAProjectRBItemCategory"}
                      name={"GAAProjectRBItemCategory"}
                      value={formInfo.GAAProjectRBItemCategory}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    >
                      <Option value={""}>
                        --- Select ---
                      </Option>
                      {RBItemCategoryList &&
                        RBItemCategoryList.map((data, id) => {
                          return (
                            <Option key={id} value={data.Value}>{data.Name}</Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectRBItemTaste"}
                      labelText={"RB Item Taste"}
                    />
                    <CustomSelect
                      id={"GAAProjectRBItemTaste"}
                      name={"GAAProjectRBItemTaste"}
                      value={formInfo.GAAProjectRBItemTaste}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    >
                      <Option value={""}>--- Select ---</Option>
                      {RBItemTasteList &&
                        RBItemTasteList.map((data, id) => {
                          return (
                            <Option key={id} value={data.Value}>{data.Name}</Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label
                      htmlFor={"SpiceOrSweetLevel"}
                      labelText={"Spice / Sweet Level"}
                    />
                    <CustomSelect
                      id={"SpiceOrSweetLevel"}
                      name={"SpiceOrSweetLevel"}
                      value={formInfo.SpiceOrSweetLevel}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    >
                      <Option value={""}>
                        --- Select ---
                      </Option>
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                      <Option value={5}>5</Option>

                    </CustomSelect>
                    <Validation error={errors.SpiceOrSweetLevel} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"Price"}
                      labelText={"Price"}
                    />
                    <Input
                      id={"Price"}
                      type={"text"}
                      name={"Price"}
                      value={formInfo.Price}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Price} />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"Description"} labelText={"Description"} />
                    <TextArea
                      rows={2}
                      id={"Description"}
                      type={"text"}
                      name={"Description"}
                      value={formInfo.Description}
                      onChange={handleChange}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Description} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/rb-item-master"} />
                  {Rights.Edit && <SaveButton text="Save" />}
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectRBItemMasterAddEdit;
