import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../../components/BackComponent'
import Label from '../../../helpers/Label'
import Card from '../../../helpers/Card'
import Input from '../../../helpers/Input'
import LinkButton from '../../../helpers/LinkButton'
import Loader from '../../../Common/Loader'
import Validation from '../../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../../helpers/CancelButton'
import SaveButton from '../../../helpers/SaveButton'
import flasher from '@flasher/flasher'
import CustomSelect from '../../../helpers/CustomSelect'
import { GetGAAProjectSpace, SaveGAAProjectSpace } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace'
import { GetProjectSpaceGroup, GetProjectSpaceGroupList } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup'
import { GetGAAProjectSpaceTypeListByProject } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType'
import Option from '../../../helpers/Option'


function GAAProjectAddEdit({ Rights }) {
    let formMode = "New";
    let { id } = useParams();
    {
        if (id) {
            document.title = "Gladiance - Edit Project Space";
            formMode = "Edit";
        } else {
            document.title = "Gladiance - Add Project Space";
        }
    }
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const Navigation = useNavigate();
    const Location = useLocation()
    const [formInfo, setFormInfo] = useState({});
    const [spaceTypeList, setSpaceTypeList] = useState([]);
    const [spaceGroupList, setSpaceGroupList] = useState([]);
    const [ProjectRef, setProjectRef] = useState();

    const handleChange = (e) => {
        setFormInfo((formInfo) => ({
            ...formInfo,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGAAProjectSpace(id, formInfo, []);
        if (result.Successful == false) {
            flasher.warning(result.Message);
        } else {
            flasher.success("Data Added Successfully");
            if (formMode == "Edit") {
                Navigation("/gaa-project-space");
            } else {
                setFormInfo(formInfo => ({
                    ...formInfo,
                    Name: ''
                }))
            }
            console.log("result :", result);
        }
    };


    // Fetch Space Group List For Dropdown //
    const fetchProjectSpaceGroupList = async () => {
        setSpaceGroupList(await GetProjectSpaceGroupList());
    };

    // Fetch Space list List For Dropdown //
    const fetchProjectSpaceTypeListByProject = async () => {
        ProjectRef && setSpaceTypeList(await GetGAAProjectSpaceTypeListByProject(ProjectRef));
    };

    //Frtch Space Group By Location//
    const fetchGladianceSpaceGroup = async () => {
        let SpaceGroup = await GetProjectSpaceGroup(Location.state);
        setProjectRef(SpaceGroup.GAAProjectRef)
        setFormInfo(formInfo => ({
            ...formInfo,
            GAAProjectSpaceGroupRef: SpaceGroup.Ref
        }))
    };

    // fetch single Project Space Data By id//
    const fetchProjectSpace = async () => {
        let space = await GetGAAProjectSpace(id);
        setProjectRef(space.GAAProjectRef);
        setFormInfo(space);
    };


    useEffect(() => {
        if (id) {
            fetchProjectSpace()
        }
        if (Location.state) {
            fetchGladianceSpaceGroup()
        }
        fetchProjectSpaceGroupList()
    }, []);

    useEffect(() => {
        fetchProjectSpaceTypeListByProject();
    }, [ProjectRef])

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <BackComponent
                    title={id ? "Edit Project Space" : "Add Project Space"}
                    button={
                        <LinkButton to={"/gaa-project-space"}>
                            <ArrowCircleLeft2
                                variant="Bold"
                                className="w-5 h-5 text-default-color"
                            />
                        </LinkButton>
                    }
                >
                    <div className="flex items-center justify-center">
                        <Card className={"w-full p-6"}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                                    <div>
                                        <Label htmlFor={"GAAProjectSpaceGroupRef"} labelText={"Project Space Group"} />
                                        <CustomSelect id={'GAAProjectSpaceGroupRef'} name={'GAAProjectSpaceGroupRef'} value={formInfo.GAAProjectSpaceGroupRef} onChange={handleChange}>
                                            <Option value={''}>--- Select Space Group ---</Option>
                                            {spaceGroupList.filter(
                                                (data) =>
                                                    data.GAAProjectRef == ProjectRef
                                            ).map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option
                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );
                                            })}
                                        </CustomSelect>
                                    </div>
                                    <div>
                                        <Label htmlFor={"GAAProjectSpaceTypeRef"} labelText={"Project Space Type"} />
                                        <CustomSelect id={'GAAProjectSpaceTypeRef'} name={'GAAProjectSpaceTypeRef'} value={formInfo.GAAProjectSpaceTypeRef} inputFocus={true} onChange={handleChange}>
                                            <Option value={''}>--- Select Space Type ---</Option>
                                            {spaceTypeList && spaceTypeList.filter(
                                                (data) =>
                                                    data.GAAProjectRef == ProjectRef
                                            ).map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option
                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );
                                            })}
                                        </CustomSelect>
                                    </div>
                                    <div>
                                        <Label htmlFor={"Name"} labelText={" Name"} />
                                        <Input
                                            id={"Name"}
                                            type={"text"}
                                            name={"Name"}
                                            value={formInfo.Name}
                                            onChange={handleChange}
                                            required={true}
                                            readOnly={!Rights.Edit}
                                        />
                                        <Validation error={errors.Name} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={"/gaa-project-space"} />
                                    {
                                        Rights.Edit && <SaveButton text="Save" />
                                    }
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    );
}

export default GAAProjectAddEdit;
