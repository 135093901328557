import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import {DeleteGAAProjectSpace, GetGAAProjectSpaceListBySpaceGroup,} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace";
import {GetSpaceGroupListByProject,} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup";
import { GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../../context/ContextProvider";

function GAAProjectSpaces({ Rights }) {
  document.title = "Gladiance - Project Space";
  const tableHeading = ["Sr.No", "Name", "Space Type", "Action"];
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [customerList, setCustomerList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [spaceGroupList, setSpaceGroupList] = useState([]);
  const [GAAprojectSpaceList, setGAAProjectSpaceList] = useState([]);
  const { CustomerRef, GAAProjectRef, GAAProjectSpaceGroupRef, handleCustomerRef, handleProjectRef, handleSpaceGroupRef,ProjectSpaceCP, setProjectSpaceCP} = useStateContext();

  const handlePageChange = (pageNumber) => {
    setProjectSpaceCP(pageNumber);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  //Fetch Project List By Customer for Dropdowm //
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef &&
      setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

 // fetch Space Group List By Project for Dropdown //
 const fetchProjectSpaceGroupListByProject = async () => {
  GAAProjectRef &&
    setSpaceGroupList(await GetSpaceGroupListByProject(GAAProjectRef));
};

  // fetch Project Space List By Space Group //
  const fetchGAAProjectSpaceListBySpaceGroup = async () => {
    GAAProjectSpaceGroupRef &&
      setGAAProjectSpaceList(
        await GetGAAProjectSpaceListBySpaceGroup(GAAProjectSpaceGroupRef)
      );
  };


  const deleteProjectSpace = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectSpace(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchGAAProjectSpaceListBySpaceGroup();
          (GAAprojectSpaceList.length - 1) % itemsPerPage == 0 && setProjectSpaceCP(ProjectSpaceCP - 1);

        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == "" && ProjectList.length > 0) {
      handleProjectRef(ProjectList[0].Ref);
    }
  }, [ProjectList]);

  useEffect(() => {
    fetchProjectSpaceGroupListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    if (GAAProjectSpaceGroupRef == "" && spaceGroupList.length > 0) {
      handleSpaceGroupRef(spaceGroupList[0].Ref);
    }
  }, [spaceGroupList]);

  useEffect(() => {
    fetchGAAProjectSpaceListBySpaceGroup();
  }, [GAAProjectSpaceGroupRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Project Spaces"}
          button={
            <LinkButton
              to={"/gaa-project-space/add-gaa-project-space"}
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={GAAProjectSpaceGroupRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Project Space</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={"Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
              >
                {ProjectList &&
                  ProjectList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"Space Group"}
                labelText={" Project Space Group"}
              />
              <CustomSelect
                id={"spaceGroupRef"}
                name={"spaceGroupRef"}
                value={GAAProjectSpaceGroupRef}
                onChange={(e) => handleSpaceGroupRef(e.target.value)}
                required={true}
              >
                {spaceGroupList.map((data, id) => {
                  return (
                      <Option key={id} value={data.Ref}>{data.Name}</Option>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {GAAprojectSpaceList && GAAprojectSpaceList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {GAAprojectSpaceList &&
                  GAAprojectSpaceList.slice(
                    (ProjectSpaceCP - 1) * itemsPerPage,
                    ProjectSpaceCP * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    const serialNumber = (ProjectSpaceCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            {tableData.GAAProjectSpaceTypeName}
                          </td>

                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/gaa-project-space/edit-gaa-project-space/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton
                                  onClick={() => deleteProjectSpace(tableData)}
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Project Space Data Not Found"} />
          )}
          {GAAprojectSpaceList && GAAprojectSpaceList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={GAAprojectSpaceList.length}
                currentPage={ProjectSpaceCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaces;
