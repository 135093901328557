import React, { Fragment, useEffect, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination';
import { AddCircle, LogoutCurve } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { useStateContext } from '../../../context/ContextProvider'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import Option from '../../helpers/Option'
import { FormatInputDate } from '../../../Encapsulation/Utils'
import { GetGAAProjectListByCustomer } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import { GetSpaceGroupListByProject } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup'
import { GetGladianceCustomerList } from '../../../Encapsulation/GladianceDomainEntities/Customer'

function CheckIn() {
    document.title = 'Gladiance - Check In - Out'
    const tableHeading = ['Sr.No', 'Room No.', 'Name', 'Mobile', 'Email', 'Check In', 'Expected Out', 'Actual Out', 'Action'];
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [customerList, setCustomerList] = useState([]);
    const [ProjectList, setProjectList] = useState([]);
    const [spaceGroupList, setSpaceGroupList] = useState([]);
    const [checkInList, setCheckInList] = useState([])
    const itemsPerPage = 7;
    const { CustomerRef, GAAProjectRef, GAAProjectSpaceGroupRef, handleCustomerRef, handleProjectRef, handleSpaceGroupRef } = useStateContext();
    const loginToken = '36983F8347364554B0E3132DD99267C4';
    const loginDeviceId = 'c03ea267-031f-4eb8-a441-e2150129d5f2';

    //Fetch Customer List For Dropdown//
    const fetchCustomerList = async () => {
        setCustomerList(await GetGladianceCustomerList());
    };

    //Fetch Project List By Customer for Dropdowm //
    const fetchGAAProjectListByCustomer = async () => {
        CustomerRef &&
            setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
    };

    // fetch Space Group List By Project for Dropdown //
    const fetchProjectSpaceGroupListByProject = async () => {
        GAAProjectRef &&
            setSpaceGroupList(await GetSpaceGroupListByProject(GAAProjectRef));
    };

    const fetchCheckInList = async () => {
        if (GAAProjectSpaceGroupRef) {
            axiosClient.get(GAAProjectSpaceGroupRef == 'All' ? `guestoperations/currentcheckinlist/${GAAProjectRef}/${loginToken}/${loginDeviceId}` : `guestoperations/currentcheckinlistbyspacegroup/${GAAProjectRef}/${GAAProjectSpaceGroupRef}/${loginToken}/${loginDeviceId}`)
                .then(({ data }) => {
                    setLoader(false)
                    setCheckInList(data.ObjectTag);
                }).catch((error) => {
                    flasher.warning(error)
                })
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCancel = (Ref) => {
        axiosClient.post(`guestoperations/cancelcheckin/${Ref}/${loginToken}/${loginDeviceId}`)
            .then(({ data }) => {
                setLoader(false)
                fetchCheckInList();
                flasher.success('Successfully Canceled')
            }).catch((error) => {
                flasher.warning(error)
            })
    }
    useEffect(() => {
        fetchCustomerList();
    }, []);

    useEffect(() => {
        if (CustomerRef == "" && customerList.length > 0) {
            handleCustomerRef(customerList[0].Ref);
        }
    }, [customerList]);

    useEffect(() => {
        fetchGAAProjectListByCustomer();
    }, [CustomerRef]);

    useEffect(() => {
        if (GAAProjectRef == "" && ProjectList.length > 0) {
            handleProjectRef(ProjectList[0].Ref);
        }
    }, [ProjectList]);

    useEffect(() => {
        fetchProjectSpaceGroupListByProject();
    }, [GAAProjectRef]);

    useEffect(() => {
        if (GAAProjectSpaceGroupRef == "" && spaceGroupList.length > 0) {
            handleSpaceGroupRef('All');
        }
    }, [spaceGroupList]);

    useEffect(() => {
        fetchCheckInList()
    }, [GAAProjectSpaceGroupRef])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Check In/Out'} button={
                    <LinkButton state={{ GAAProjectSpaceGroupRef }} to={'/check-in/add-check-in'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Check In/Out</span>
                    </LinkButton>
                }>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
                            <CustomSelect
                                id={"CustomerRef"}
                                name={"CustomerRef"}
                                value={CustomerRef}
                                onChange={(e) => handleCustomerRef(e.target.value)}
                            >
                                {customerList &&
                                    customerList.map((data, id) => {
                                        return (
                                            <Option key={id} value={data.Ref}>{data.Name}</Option>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label htmlFor={"GAAProjectRef"} labelText={"Project"} />
                            <CustomSelect
                                id={"GAAProjectRef"}
                                name={"GAAProjectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => handleProjectRef(e.target.value)}
                            >
                                {ProjectList &&
                                    ProjectList.map((data, id) => {
                                        return (
                                            <Option key={id} value={data.Ref}>{data.Name}</Option>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"Space Group"}
                                labelText={" Project Space Group"}
                            />
                            <CustomSelect
                                id={"spaceGroupRef"}
                                name={"spaceGroupRef"}
                                value={GAAProjectSpaceGroupRef}
                                onChange={(e) => handleSpaceGroupRef(e.target.value)}
                                required={true}
                            >
                                <Option value={'All'}>All</Option>
                                {spaceGroupList.map((data, id) => {
                                    return (
                                        <Option key={id} value={data.Ref}>{data.Name}</Option>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                    </div>
                    {checkInList && checkInList.length > 0 ? (
                        <div className='m-2'>
                            <Table tableHeading={tableHeading} >
                                {checkInList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    const serialNumber = (currentPage - 1) * itemsPerPage + tableDataID + 1;
                                    return (
                                        <Fragment key={tableDataID}>

                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{serialNumber}</td>
                                                <td className="px-4 py-3">{tableData.GAAProjectSpaceName}</td>
                                                <td className="px-4 py-3">{tableData.GuestName}</td>
                                                <td className="px-4 py-3">{tableData.GuestPhoneNos}</td>
                                                <td className="px-4 py-3">{tableData.GuestEMailId}</td>
                                                <td className="px-4 py-3">{FormatInputDate(tableData.CheckInDateTime)}</td>
                                                <td className="px-4 py-3">{FormatInputDate(tableData.ExpectedCheckOutDateTime)}</td>
                                                <td className="px-4 py-3">{FormatInputDate(tableData.CheckOutDateTime)}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link state={{ GAAProjectSpaceGroupRef, update: true }} to={`/check-in/edit-check-in/${tableData.Ref}`} title='CheckOut'><ActionButton className='p-1.5 text-white bg-green-500 rounded-lg cursor-pointer'>
                                                            <LogoutCurve className='w-4 h-4' />
                                                        </ActionButton></Link>
                                                        <Link state={{ GAAProjectSpaceGroupRef, update: false }} to={`/check-in/edit-check-in/${tableData.Ref}`} title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                            <PencilSquareIcon className='w-4 h-4' />
                                                        </ActionButton></Link>
                                                        <ActionButton onClick={() => handleCancel(tableData.Ref)} title={'Cancel'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <XMarkIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}

                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Check In Data Not Found'} />
                    )}
                    {checkInList && checkInList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={checkInList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}


export default CheckIn
