import React, { Fragment, useEffect, useState } from "react";
import {
  GetGAAProjectList,
  GetGAAProjectListByCustomer,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import Loader from "../../../Common/Loader";
import AddComponent from "../../../../components/AddComponent";
import Label from "../../../helpers/Label";
import CustomSelect from "../../../helpers/CustomSelect";
import DataNotFound from "../../../helpers/DataNotFound";
import Pagination from "../../../helpers/Pagination";
import LinkButton from "../../../helpers/LinkButton";
import { AddCircle } from "iconsax-react";
import Option from "../../../helpers/Option";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  DeleteRBItemMaster,
  GetRBItemMasterListByProject,
} from "../../../../Encapsulation/HotelDomainEntities/RBItemMaster";
import { useStateContext } from "../../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function GAAProjectRBItemMaster({ Rights }) {
  document.title = "Gladiance - RB item serving slot";
  const [loader, setLoader] = useState(false);
  const [rbitemservingslotList, setRBItemServingList] = useState([]);
  const itemsPerPage = 7;
  const handlePageChange = (pageNumber) => {
    setRBItemMasterCP(pageNumber);
  };
  const { theme, FRONTEND_URL } = useStateContext();
  const [ProjectList, setProjectList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const {
    GAAProjectRef,
    handleProjectRef,
    CustomerRef,
    handleCustomerRef,
    RBItemMasterCP,
    setRBItemMasterCP,
  } = useStateContext();

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  // Fetch Project List For Dropdown //
  const fetchProjectListByCustomer = async () => {
    CustomerRef &&
      setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };
  const fetchRBItemMasterListByProject = async () => {
    GAAProjectRef &&
      setRBItemServingList(await GetRBItemMasterListByProject(GAAProjectRef));
  };

  const deleteRBItemMaster = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteRBItemMaster(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchRBItemMasterListByProject();
          (rbitemservingslotList.length - 1) % itemsPerPage == 0 &&
            setRBItemMasterCP(RBItemMasterCP - 1);
        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == "" && ProjectList.length > 0) {
      handleProjectRef(ProjectList[0].Ref);
    }
  }, [ProjectList]);

  useEffect(() => {
    fetchRBItemMasterListByProject();
  }, [GAAProjectRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"RB Item Master"}
          button={
            <LinkButton
              to={"/rb-item-master/add-rb-item-master"}
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={GAAProjectRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Item Master</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAprojectRef"} labelText={"Project"} />
              <CustomSelect
                id={"GAAprojectRef"}
                name={"GAAprojectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {ProjectList.map((data, id) => {
                  return (
                      <Option key={id} value={data.Ref}>{data.Name}</Option>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {rbitemservingslotList && rbitemservingslotList.length > 0 ? (
            <div className="m-2">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                {rbitemservingslotList
                  .slice((RBItemMasterCP - 1) * itemsPerPage, RBItemMasterCP * itemsPerPage)
                  .map((tableData, tableDataID) => {
                    return (
                      <div key={tableDataID} className="flex flex-col items-center border border-gray-200 rounded-lg shadow md:flex-row">
                        <a href="#" className="flex flex-col items-center m-4 border border-gray-200 rounded-lg shadow md:flex-row ">
                          <img className="object-fill h-[26vh] w-[26vw] rounded-t-lg md:rounded-none md:rounded-s-lg" src="https://www.cookforindia.com/wp-content/uploads/2016/05/Dal-Fry-1024x528.jpg" alt="" />
                        </a>
                        <div className="leading-normal m-2">
                          <div className={(theme ? 'text-default-color' : 'text-gray-800 ') + "col-span-4 flex text-2xl mb-2 font-bold tracking-tight"}>Item : {tableData.Name}</div>
                          <div className="grid md:grid-cols-5 grid-cols-1 gap-1">
                            <div className="items-center">Category : {tableData.GAAProjectRBItemCategoryName}</div>
                            <div className="items-stretch">Test : {tableData.GAAProjectRBItemTasteName}</div>
                            <div className="items-center">Spicy : {tableData.SpiceOrSweetLevel}</div>
                            <div>Price : {tableData.Price} INR</div>
                            <div className="flex justify-end">
                              {Rights.Delete && (
                                <ActionButton onClick={() => deleteRBItemMaster(tableData)} title={'Delete'} className='p-1.5 m-1 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                              )}
                            </div>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-4 ">
                            <div className="col-start-1 col-span-3">
                              Video url : <a href={tableData.VideoURL}> <span className="text-blue-400" target="_blank">{tableData.VideoURL}</span>
                              </a>
                            </div>
                            <div className="flex justify-end items-center">
                              <Link to={`/rb-item-master/edit-rb-item-master/${tableData.Ref}`} title='Edit'>
                                <ActionButton className={'p-1.5 m-1 text-white bg-yellow-400 '}>
                                  <PencilSquareIcon className='w-4 h-4' />
                                </ActionButton>
                              </Link>
                            </div>
                          </div>

                          <div className="grid md:grid-cols-10 grid-cols-1 mb-4">
                            <div className=''>Description :</div>
                            <div className='col-span-8'>{tableData.Description}</div>
                            <div></div>
                          </div>
                          {/* <div className='grid grid-cols-3'>
                            <div>Serving Slot : {tableData.ServingSlot}</div>
                            <div></div>
                            <div></div>
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

           
          ) : (
            <DataNotFound labelText={"Sorry! Item Master Data Not Found"} />
          )}

          {rbitemservingslotList && rbitemservingslotList.length > 2 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={rbitemservingslotList.length}
                currentPage={RBItemMasterCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectRBItemMaster;
