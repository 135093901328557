import React, { Fragment } from 'react'
import { useStateContext } from '../context/ContextProvider'


function BackComponent({ children, title, button, className }) {
    const { theme } = useStateContext()

    return (
        <Fragment>
            <div className={'h-[100%] md:w-[98%] w-[95%] mx-auto rounded-lg border-[rgba(255,255,255,0.5)] border-[1px] mr-3 ' + (theme ? "bg-[#222222]" : "bg-gray-200")}>
                <header>
                    <div className={"mx-auto px-2 py-3 sm:px-6 lg:px-4 flex items-center " + (className)}>
                        {button}
                        <h1 className={"text-[4vw] md:text-2xl ml-3 font-bold tracking-tight " + (theme ? "text-default-color " : " text-gray-800 ")}>{title}</h1>
                    </div>
                </header>
                <main className='overflow-x-auto journal-scroll h-[88%]'>
                    {
                        children && (
                            <div className="mx-auto px-4 md:px-4">
                                {children}
                            </div>
                        )
                    }
                </main>
            </div>
        </Fragment>
    )
}

export default BackComponent