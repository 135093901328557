import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function DataNotFound({ className, labelText, className2 }) {
    const { theme } = useStateContext()
    return (
        <Fragment>
            <div className={'flex justify-center items-center h-[20vh] md:h-[70vh] ' + (className2)}>
                <label className={(theme ? 'text-gray-300' : 'text-gray-800 ') + " text-[1vw] md:text-3xl font-bold " + (className)}>{labelText}</label>
            </div>
        </Fragment>

    )
}

export default DataNotFound