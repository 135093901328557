import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../../components/BackComponent'
import Label from '../../../helpers/Label'
import Card from '../../../helpers/Card'
import Input from '../../../helpers/Input'
import LinkButton from '../../../helpers/LinkButton'
import Loader from '../../../Common/Loader'
import Validation from '../../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import { useStateContext } from '../../../../context/ContextProvider'
import CancelButton from '../../../helpers/CancelButton'
import SaveButton from '../../../helpers/SaveButton'
import flasher from '@flasher/flasher'
import { ClearInfo } from '../../../../Encapsulation/Utils'
import { GetProjectSpaceGroup, SaveProjectSpaceGroup } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup'
import { GetGAAProject } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'

function GAAProjectSpaceGroupAddEdit({ Rights }) {
    let formMode = "New";
    let { id } = useParams();
    {
        if (id) {
            document.title = 'Gladiance - Edit Project Space Group'
            formMode = "Edit";
        } else {
            document.title = 'Gladiance - Add Project Space Group'
        }
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const Location = useLocation()
    const [formInfo, setFormInfo] = useState({})
    const [projectName, setProjectName] = useState('')

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveProjectSpaceGroup(id, formInfo, []);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/gaa-project-space-group');
            }
            else {
                setFormInfo(ClearInfo(formInfo));
                fetchGAAProject();
            }
        }
    }

    //Fetch Project Data By Location//
    const fetchGAAProject = async () => {
        let project = await GetGAAProject(Location.state);
        setFormInfo(formInfo => ({
            ...formInfo,
            GAAProjectRef: project.Ref
        }))
        setProjectName(project.Name);
    };

    //Fetch Single Space Group Data By id//
    const fetchProjectSpaceGroup = async () => {
        let data = await GetProjectSpaceGroup(id);
        setFormInfo(data);
        setProjectName(data.GAAProjectName);
    }

    useEffect(() => {
        if (Location.state) {
            fetchGAAProject();
        }
        if (id) {
            fetchProjectSpaceGroup()
        }
    }, []);


    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Project Space Group' : 'Add Project Space Group'} button={
                    <LinkButton to={'/gaa-project-space-group'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>

                                    <div>
                                        <Label htmlFor={'GAAProjectRef'} labelText={'Project'} />
                                        {formInfo.GAAProjectRef && (<Input id={'GAAProjectRef'} type={'text'} name={'GAAProjectRef'} value={projectName} required={true} readOnly={true} />)}
                                        <Validation error={errors.GAAProjectRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'Space Group Name'} />
                                        <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} readOnly={!Rights.Edit} inputFocus={true} onChange={handleChange} />
                                        <Validation error={errors.Name} />
                                    </div>
                                </div>

                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/gaa-project-space-group'} />
                                    {
                                        Rights.Edit && <SaveButton text="Save" />
                                    }
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}




export default GAAProjectSpaceGroupAddEdit
