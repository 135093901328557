import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, PencilSquareIcon, PlusCircleIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { GetHotelBillViewManagementList } from '../../../Encapsulation/HotelDomainEntities/HotelBillViewManagement'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function BillViewManagement() {
  document.title = 'Gladiance - Bill View Management'
  const tableHeading = ['Sr.No', 'Room No.', 'Room Rent', 'In Room Dining', 'Laundry', 'XXX', 'Bill', 'Advance', 'Outstanding', 'Last Updated at date, time', 'Action'];
  const [loader, setLoader] = useState(false)
  const [viewModel, setViewModel] = useState(false)
  const [billViewCsv, setBillViewCsv] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [billViewList, setBillViewList] = useState([])

  const fetchBillViewList = async () => {
    setBillViewList(await GetHotelBillViewManagementList());
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const headers = [
    { label: 'Sr.No', key: 'index' },
    { label: 'Material', key: 'material_name' },
    { label: 'Unit', key: 'material_unit' },
  ];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleBillViewCsv = () => {
    // setLoader(true)
    // const formData = new FormData()
    // formData.append('billViewCsv', billViewCsv)
    // axiosClient.post(`import_material`, formData)
    //     .then(({ data }) => {
    //         flasher.success(data.msg)
    //         setLoader(false)
    //     })
    //     .catch(({ response }) => {
    //         setLoader(false)
    //     })
    // setViewModel(false)
    // fetchBillViewList()
  }

  const deleteBillView = (id) => {
    // Swal.fire({
    //     title: 'Are you sure to delete this?',
    //     text: "You won't be able to revert this!",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //     if (result.isConfirmed) {
    //         axiosClient.delete(`/delete_material/${id}`)
    //             .then(({ data }) => {
    //                 flasher.success(data.msg)
    //                 fetchBillViewList()
    //             })
    //     }
    // })
  }
  useEffect(() => {
    fetchBillViewList()
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <AddComponent title={'Bill View Management'} button={
          <LinkButton to={'/bill-view-management/add-bill-view-management'}>
            <AddCircle variant='Bold' className='w-5 h-5' />
            <span>Add Bill</span>
          </LinkButton>
        }>
          {billViewList && billViewList.length > 0 ? (
            <div ref={componentRef} className='m-2'>
              <Table tableHeading={tableHeading} >
                {billViewList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                 const serialNumber = (currentPage - 1) * itemsPerPage + tableDataID + 1;
                 return (
                    <Fragment key={tableDataID}>
                      <tr className="border-b border-opacity-30 border-white text-center">
                        <td className="px-4 py-3">{serialNumber}</td>
                        <td className="px-4 py-3">{tableData.RoomNo}</td>
                        <td className="px-4 py-3">{tableData.RoomRent}</td>
                        <td className="px-4 py-3">{tableData.InRoomDining}</td>
                        <td className="px-4 py-3">{tableData.Laundry}</td>
                        <td className="px-4 py-3">{tableData.XXX}</td>
                        <td className="px-4 py-3">{tableData.Bill}</td>
                        <td className="px-4 py-3">{tableData.Advance}</td>
                        <td className="px-4 py-3">{tableData.Outstanding}</td>
                        <td className="px-4 py-3">
                          <div className='flex justify-center gap-3'>
                            <Link title='Edit' >
                              <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                <PencilSquareIcon className='w-4 h-4' />
                              </ActionButton>
                            </Link>
                            <ActionButton onClick={() => deleteBillView(tableData.id)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                              <TrashIcon className='w-4 h-4' />
                            </ActionButton>
                          </div>
                        </td>
                      </tr>

                    </Fragment>
                  )
                })}

              </Table>
            </div>

          ) : (
            <DataNotFound labelText={'Sorry! Bill View Data Not Found'} />)}
          {billViewList && billViewList.length > 7 ? (<div className="flex justify-end">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={billViewList.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>) : (<div></div>)
          }

        </AddComponent>
      )}
    </Fragment>
  )
}

export default BillViewManagement