import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function LinkButton({ children, to, state, onClick, className }) {
    return (
        <Fragment>
            <Link to={to} state={state} onClick={onClick} className={"flex items-center px-[1vw] py-[1vh] gap-3 border text-default-color rounded-md bg-gradient-to-r from-[#ff7d14] to-[#f94327] hover:bg-gradient-to-bl hover:text-default-color cursor-pointer text-[2vw] md:text-base font-medium transition delay-100 " + (className)} >
                {children}
            </Link>
        </Fragment>
    )
}

export default LinkButton