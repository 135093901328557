import axiosClient from "./Axios";

const loginToken = window.location.hostname.includes('gladiance.one')
  || window.location.hostname.includes('localhost') ? 50000102 : 50000002;

export const AllocateSingleId = async (id) => {
  let result = 0;

  if (!id) {
    try {
      let tr = (
        await axiosClient.post(`/request/allocatesingleid/${loginToken}`)
      ).data;

      if (!tr.Successful) {
        throw tr.Message;
      } else {
        result = +tr.Tag;
        return result;
        // return (id = +tr.Tag);
      }
    } catch (errors) {
      //   setErrors(errors);
    }
  }
  else
  {
    return +id;
  }
};

