import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProjectSceneConfigurations";
const FetchRequestType = "GAAProjectSceneConfigurationFetchRequest";

// Save All Data To Database //
export const SaveHotelRoomSceneConfiguration = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};
// Get All Data From Database //
export const GetHotelRoomSceneConfigurationList = async () => {
  let payload = { GAAProjectSceneRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

// Get Single Data From Database //
export const GetHotelRoomSceneConfiguration = async (Ref) => {
console.log('Ref :', Ref);
  let payload = { GAAProjectSceneConfigurationRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteHotelRoomSceneConfiguration = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetProjectRoomSceneListByProjectScene = async (
  ProjectSceneRef
) => {
  let payload = { GAAProjectSceneRefs: [ProjectSceneRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};
