import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteGladianceCountry, GetGladianceCountryList } from '../../../Encapsulation/GladianceDomainEntities/GladianceCountryMaster'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { useStateContext } from '../../../context/ContextProvider'

function CountryMaster() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - Country Master'
    const tableHeading = ['Sr.No', 'Country Name', 'Currency', 'Currency Symbol', 'Currency Name', 'Action']
    const [loader, setLoader] = useState(false)
    const itemsPerPage = 7;
    const [errors, setErrors] = useState([])
    const [gladianceCountryList, setGladianceCountryList] = useState([]);
    const { CountryMasterCP, setCountryMasterCP } = useStateContext();

    const fetchGladianceCountryList = async () => {
        setGladianceCountryList(await GetGladianceCountryList(setErrors));
    }

    const handlePageChange = (pageNumber) => {
        setCountryMasterCP(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleGladianceDepartmentMasterCsv = () => {
        // setLoader(true)
        // const formData = new FormData()
        // formData.append('GladianceDepartmentMasterCsv', GladianceDepartmentMasterCsv)
        // axiosClient.post(`import_material`, formData)
        //     .then(({ data }) => {
        //         flasher.success(data.msg)
        //         setLoader(false)
        //     })
        //     .catch(({ response }) => {
        //         setLoader(false)
        //     })
        // setViewModel(false)
        // getGladianceCountryList()
    }

    const deleteCountry = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteGladianceCountry(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchGladianceCountryList();
                    (gladianceCountryList.length - 1) % itemsPerPage == 0 && setCountryMasterCP(CountryMasterCP - 1);

                }
            }
        })
    }

    useEffect(() => {
        fetchGladianceCountryList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Country Masters'} button={
                    <LinkButton to={'/country-master/add-country-master'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Country Details</span>
                    </LinkButton>
                }>
                    {gladianceCountryList && gladianceCountryList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {gladianceCountryList && gladianceCountryList.slice((CountryMasterCP - 1) * itemsPerPage, CountryMasterCP * itemsPerPage).map((tableData, tableDataID) => {
                                    const serialNumber = (CountryMasterCP - 1) * itemsPerPage + tableDataID + 1;
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{serialNumber}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">{tableData.Currency}</td>
                                                <td className="px-4 py-3">{tableData.CurrencySymbol}</td>
                                                <td className="px-4 py-3">{tableData.CurrencyName}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link to={`/country-master/edit-country-master/${tableData.Ref}`} title='Edit' >
                                                            <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                                <PencilSquareIcon className='w-4 h-4' />
                                                            </ActionButton>
                                                        </Link>
                                                        <ActionButton onClick={() => deleteCountry(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <TrashIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Country Master Data Not Found'} />
                    )}
                    {gladianceCountryList && gladianceCountryList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={gladianceCountryList.length}
                            currentPage={CountryMasterCP}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}

export default CountryMaster
