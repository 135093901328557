import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { GetGAAProjectList, GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGAAProjectSpaceTypeListByProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { DeleteGAAProjectSpaceTypePlannedDevice, GetGAAProjectSpaceTypePlannedDeviceByArea, GetGAAProjectSpaceTypePlannedDeviceBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDevice";
import Input from "../../../helpers/Input";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function GAAProjectSpaceTypePlannedDevice({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Project Space Type Planned Device";
  const tableHeading = ["Sr.No", "Name", 'Product Type', "Action"];
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [customerList, setCustomerList] = useState([])
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
  const [GAAProjectSpaceTypeAreaList, setGAAProjectSpaceTypeAreaList] = useState([]);
  const [ProjectSpaceTypePlannedDeviceList, setProjectSpaceTypePlannedDeviceList] = useState([]);
  const {  CustomerRef, GAAProjectRef, GAAProjectSpaceTypeRef, GAAProjectSpaceTypeAreaRef,handleCustomerRef, handleProjectRef, handleSpaceTypeRef, handleSpaceTypeAreaRef, PlannedDeviceCP, setPlannedDeviceCP } = useStateContext();

  const handlePageChange = (pageNumber) => {
    setPlannedDeviceCP(pageNumber);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

    //Fetch Customer List For Dropdown//
    const fetchCustomerList = async () => {
      setCustomerList(await GetGladianceCustomerList());
    }

  //Fetch Project List By Customer for Dropdowm //
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  // Fetch Project Space Type List By Project For Dropdown  //
  const fetchSpaceTypeListByProject = async () => {
    GAAProjectRef && setGAAProjectSpaceTypeList(await GetGAAProjectSpaceTypeListByProject(GAAProjectRef));
  };

  // Fetch Area List By Space Type For Dropdown //
  const fetchAreaBySpaceType = async () => {
    GAAProjectSpaceTypeRef && setGAAProjectSpaceTypeAreaList(await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef));
  };

  // fetch Planned Device List By Project Space Type //
  const fetchProjectSpaceTypePlannedDeviceListBySpaceType = async () => {
    GAAProjectSpaceTypeRef && setProjectSpaceTypePlannedDeviceList(await GetGAAProjectSpaceTypePlannedDeviceBySpaceType(GAAProjectSpaceTypeRef))
  };


  // fetch Planned Device List By Project Area  //
  const fetchProjectSpaceTypePlannedDeviceListByArea = async () => {
    GAAProjectSpaceTypeAreaRef && setProjectSpaceTypePlannedDeviceList(await GetGAAProjectSpaceTypePlannedDeviceByArea(GAAProjectSpaceTypeAreaRef))
  };

  const deletePlannedDevice = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectSpaceTypePlannedDevice(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchProjectSpaceTypePlannedDeviceListByArea();
          (ProjectSpaceTypePlannedDeviceList.length - 1) % itemsPerPage == 0 && setPlannedDeviceCP(PlannedDeviceCP - 1);

        }
      }
    })
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == '' && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]); 

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == "" && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchSpaceTypeListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeRef == "" && GAAProjectSpaceTypeList.length > 0) {
      handleSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
    }
  }, [GAAProjectSpaceTypeList]);

  useEffect(() => {
    fetchAreaBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeAreaRef == "" && GAAProjectSpaceTypeAreaList.length > 0) {
      handleSpaceTypeAreaRef(GAAProjectSpaceTypeAreaList[0].Ref);
    }
  }, [GAAProjectSpaceTypeAreaList]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListByArea();
  }, [GAAProjectSpaceTypeAreaRef]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={" Planned Devices"}
          button={
            <LinkButton to={"/gaa-project-space-type-planned-device/add-gaa-project-space-type-planned-device"} className={Rights.Add ? "opacity-100" : "hidden"} state={{ GAAProjectRef: GAAProjectRef, GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef, GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef }}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Plan Device</span>
            </LinkButton>
          }
        >

          <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-4'>
          <div>
              <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
              <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e) => handleCustomerRef(e.target.value)} >
                {
                  customerList && customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectRef"}
                labelText={" Project"}
              />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {GAAProjectList && GAAProjectList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeRef"}
                labelText={" Space Type"}
              />
              {GAAProjectSpaceTypeList.length > 0 ? (<div><CustomSelect
                id={"GAAProjectSpaceTypeRef"}
                name={"GAAProjectSpaceTypeRef"}
                value={GAAProjectSpaceTypeRef}
                onChange={(e) => handleSpaceTypeRef(e.target.value)}
                required={true}
              >
                {GAAProjectSpaceTypeList && GAAProjectSpaceTypeList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect></div>) : (<div><Input placeHolder={"  No Space Types"} disabled={true}></Input></div>)}
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                labelText={" Space Type Area"}
              />
              {GAAProjectSpaceTypeAreaList.length > 0 ? (<div><CustomSelect
                id={"GAAProjectSpaceTypeAreaRef"}
                name={"GAAProjectSpaceTypeAreaRef"}
                value={GAAProjectSpaceTypeAreaRef}
                onChange={(e) => handleSpaceTypeAreaRef(e.target.value)}
                required={true}
              >
                {GAAProjectSpaceTypeAreaList && GAAProjectSpaceTypeAreaList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect></div>) : (<div><Input placeHolder={"  No Areas"} disabled={true}></Input></div>)}
            </div>
          </div>
          {ProjectSpaceTypePlannedDeviceList && ProjectSpaceTypePlannedDeviceList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {ProjectSpaceTypePlannedDeviceList &&
                  ProjectSpaceTypePlannedDeviceList
                    .slice(
                      (PlannedDeviceCP - 1) * itemsPerPage,
                      PlannedDeviceCP * itemsPerPage
                    )
                    .map((tableData, tableDataID) => {
                      const serialNumber = (PlannedDeviceCP - 1) * itemsPerPage + tableDataID + 1;
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{serialNumber}</td>
                            <td className="px-4 py-3">{tableData.Name}</td>
                            <td className="px-4 py-3">{tableData.GAAProductName}</td>
                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/gaa-project-space-type-planned-device/edit-gaa-project-space-type-planned-device/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={
                                      "p-1.5 text-white bg-yellow-400 "
                                    }
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                {Rights.Delete && (
                                  <ActionButton onClick={() => deletePlannedDevice(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                    <TrashIcon className='w-4 h-4' />
                                  </ActionButton>
                                )}
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Planned Device Data Not Found"} />
          )}
          {ProjectSpaceTypePlannedDeviceList && ProjectSpaceTypePlannedDeviceList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={ProjectSpaceTypePlannedDeviceList.length}
                currentPage={PlannedDeviceCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypePlannedDevice;
