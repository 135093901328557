import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../../helpers/LinkButton'
import Table from '../../../helpers/Table'
import Loader from '../../../Common/Loader'
import { Link } from 'react-router-dom'
import flasher from '@flasher/flasher'
import ActionButton from '../../../helpers/ActionButton'
import Pagination from '../../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../../helpers/DataNotFound'
import { useStateContext } from '../../../../context/ContextProvider'
import { GetGAAProjectListByCustomer } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import Label from '../../../helpers/Label'
import Option from '../../../helpers/Option'
import CustomSelect from '../../../helpers/CustomSelect'
import { GetGladianceCustomerList } from '../../../../Encapsulation/GladianceDomainEntities/Customer'
import axiosClient from '../../../../Encapsulation/Axios'
import { SiteUsersList } from '../../../../Encapsulation/Utils'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function GAAProjectUser({ Rights }) {
  document.title = 'Hotel - User'
  const tableHeading = ['Sr.No', 'Name', 'Role', 'Phone No.', 'Email Id', 'Status', 'Action']
  const [loader, setLoader] = useState(false)
  const [customerList, setCustomerList] = useState([])
  const itemsPerPage = 7;
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [CustomerUserList, setCustomerUserList] = useState([]);
  const loginToken = '36983F8347364554B0E3132DD99267C4';
  const loginDeviceId = 'c03ea267-031f-4eb8-a441-e2150129d5f2';
  const { CustomerRef, GAAProjectRef, handleCustomerRef, handleProjectRef, ProjectUserCP, setProjectUserCP } = useStateContext();

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  }

  //Fetch Project List for Dropdowm By Customer//
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  const handlePageChange = (pageNumber) => {
    setProjectUserCP(pageNumber);
  };

  const fetchCustomerUserList = async () => {
    GAAProjectRef &&
      axiosClient.get(`usermanagement/customeruserlist/${GAAProjectRef}/${loginToken}/${loginDeviceId}`)
        .then(({ data }) => {
          setCustomerUserList(data.ObjectTag)
          setLoader(false)
          if (!data.Successful) {
            flasher.warning(data.Message)
          }
        }).catch((error) => {
          flasher.warning(error)
        })
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == '' && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  //  Set Zero Index Project Name For Dropdown //
  useEffect(() => {
    if (GAAProjectRef == '' && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchCustomerUserList();
  }, [GAAProjectRef]);

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <AddComponent title={'Users'} button={
          <LinkButton to={Rights.Add && '/project-user-master/add-project-user-master'} className={Rights.Add ? "opacity-100" : "hidden"} state={{ GAAProjectRef: GAAProjectRef }}>
            <AddCircle variant='Bold' className='w-5 h-5' />
            <span>Add User</span>
          </LinkButton>
        }>
          <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-4'>
            <div>
              <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
              <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e) => handleCustomerRef(e.target.value)} >
                {
                  customerList && customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={'GAAProjectRef'} labelText={'Project'} />
              <CustomSelect id={'GAAProjectRef'} name={'GAAProjectRef'} value={GAAProjectRef} onChange={(e) => handleProjectRef(e.target.value)}>
                {
                  GAAProjectList && GAAProjectList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    )
                  })
                }
              </CustomSelect>
            </div>
          </div>
          {CustomerUserList && CustomerUserList.length > 0 ? (
            <div className='m-2'>
              <Table tableHeading={tableHeading}>
                {CustomerUserList.slice((ProjectUserCP - 1) * itemsPerPage, ProjectUserCP * itemsPerPage).map((tableData, tableDataID) => {
                  const serialNumber = (ProjectUserCP - 1) * itemsPerPage + tableDataID + 1;
                  return (
                    <Fragment key={tableDataID}>
                      <tr className="border-b border-opacity-30 border-white">
                        <td className="px-4 py-3">{serialNumber}</td>
                        <td className="px-4 py-3">{tableData.FirstName} {tableData.MiddleName} {tableData.LastName} </td>
                        <td className="px-4 py-3">
                          {
                            SiteUsersList.map((data, index) => {
                              return ((tableData.Role == data.Value) && data.Name)
                            })
                          }
                        </td>
                        <td className="px-4 py-3">{tableData.PhoneNos}</td>
                        <td className="px-4 py-3">{tableData.EMailId}</td>
                        <td className="px-4 py-3"> {tableData.Enabled == 1 ? "Enabled" : "Disabled"}</td>
                        <td className="px-4 py-3">
                          <div className='flex justify-center gap-3'>
                            <Link to={`/project-user-master/edit-project-user-master/${tableData.Ref}`} state={{ GAAProjectRef: GAAProjectRef }} title='Edit' >
                              <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                <PencilSquareIcon className='w-4 h-4' />
                              </ActionButton>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={'Sorry! User Data Not Found'} />
          )}
          {CustomerUserList && CustomerUserList.length > 7 ? (<div className="flex justify-end">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={CustomerUserList.length}
              currentPage={ProjectUserCP}
              onPageChange={handlePageChange}
            />
          </div>) : (<div></div>)
          }
        </AddComponent>
      )}
    </Fragment>
  )
}
export default GAAProjectUser