export function ClearInfo(Info) {
  let blankInfo = {};
  for (let k in Info) {
    blankInfo[k] = "";
  }
  return blankInfo;
}

export function FilterAction(tableHeading, Rights) {
  return tableHeading.filter((data) =>
    Rights.View == false ? data != "Action" : data
  );
}
const validParams = [
  "AC Control",
  "AC Speed",
  "Set",
  "Power",
  "Action",
  "Transition",
  "Intensity",
  "Speed",
  "Brightness",
  "Hue",
  "Saturation",
];

export function FilterNodeConfig(ParamList) {
  return ParamList.filter((obj) => {
    return (
      obj.name == "AC Control" ||
      obj.name == "AC Speed" ||
      obj.name == "Set" ||
      obj.name == "Power" ||
      obj.name == "Action" ||
      obj.name == "Transition" ||
      obj.name == "Intensity" ||
      obj.name == "Speed" ||
      obj.name == "Brightness" ||
      obj.name == "Hue" ||
      obj.name == "Saturation"
    );
  });
}

export const FormatOutputDate = (OutputDate) => {
  if (OutputDate == "") {
    return OutputDate;
  } else {
    let date = new Date(OutputDate);
    // Extract components
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    let day = String(date.getDate()).padStart(2, "0");
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let seconds = String(date.getSeconds()).padStart(2, "0");
    let milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${milliseconds}`;
  }
};

export const FormatInputDate = (InputDate) => {
  if (InputDate == "") {
    return InputDate;
  } else {
    // Extract components from the string
    let parts = InputDate.split("-");
    let year = parts[0];
    let month = parts[1];
    let day = parts[2];
    let hours = parts[3];
    let minutes = parts[4];

    // Create a new Date object
    let date = new Date(year, month - 1, day, hours, minutes);

    // Format the date and time in the desired format
    let formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDateTime;
  }
};

export const ProjectTypeList = [
  {
    Value: 1,
    Name: "Hotel",
  },
  {
    Value: 2,
    Name: "Home",
  },
  {
    Value: 3,
    Name: "Hospital",
  },
  {
    Value: 4,
    Name: "Office",
  },
  {
    Value: 500,
    Name: "Other",
  },
];

export const TemperatureConventionList = [
  {
    Value: 1,
    Name: "Centigrade",
  },
  {
    Value: 2,
    Name: "Fahrenhite",
  },
];
export const TimeConventionList = [
  {
    Value: 1,
    Name: "12 Hours",
  },
  {
    Value: 2,
    Name: "24 Hours",
  },
];

export const ProductOperationModesList = [
  {
    Value: 10,
    Name: "Input",
  },
  {
    Value: 20,
    Name: "Output",
  },
  {
    Value: 30,
    Name: "Input and Output ",
  },
  {
    Value: 40,
    Name: "Trigger ",
  },
];

export const FirmwareStatusList = [
  {
    Value: 0,
    Name: "Inactive ",
  },
  {
    Value: 1,
    Name: "Active",
  },
  {
    Value: 100,
    Name: "Discontinued",
  },
];

export const TelevisionOperatingSystem = [
  {
    Value: 1,
    Name: "Android",
  },
];

export const RBItemCategoryList = [
  {
    Value: 10,
    Name: "veg",
  },
  {
    Value: 20,
    Name: "Non-Veg",
  },
  {
    Value: 30,
    Name: "Hot Beverages",
  },
  {
    Value: 40,
    Name: "Cold Beverages",
  },
  {
    Value: 50,
    Name: "Mocktails",
  },
  {
    Value: 60,
    Name: "Ice-Creams",
  },
  {
    Value: 70,
    Name: "Liquors",
  },
  {
    Value: 80,
    Name: "Cocktails",
  },
  {
    Value: 500,
    Name: "Other",
  },
];

export const RBItemTasteList = [
  {
    Value: 10,
    Name: "Spicy",
  },
  {
    Value: 20,
    Name: "Sweet",
  },
  {
    Value: 500,
    Name: "Other",
  },
];

export const HardwareTypes = [
  {
    Value: 0,
    Name: "None",
  },
  {
    Value: 10,
    Name: "ESP32",
  },
  {
    Value: 20,
    Name: "ESP32S2",
  },
  {
    Value: 30,
    Name: "ESP32C3",
  },
  {
    Value: 40,
    Name: "nRF52",
  },
];
export const ProductInputChannelTypes = [
  {
    Value: 100,
    Name: "Button",
  },
  {
    Value: 200,
    Name: "Keytag",
  },
];

export const GladianceUserRoleList = [
  // {
  //   Value: 100,
  //   Name: "Gladiance Super Admin",
  // },
  {
    Value: 200,
    Name: "Gladiance Admin",
  },
  {
    Value: 300,
    Name: "Gladiance Staff",
  },
];




export const SiteUsersList = [
  {
    Value: 400,
    Name: "Site Super Admin",
  },
  {
    Value: 500,
    Name: "Site IT Admin",
  },
  {
    Value: 600,
    Name: "Site Housekeeping Admin",
  },
  {
    Value: 700,
    Name: "Site Room Service Admin",
  },
  {
    Value: 800,
    Name: "Site Front Office Admin",
  },
  {
    Value: 900,
    Name: "Site Engineering Admin",
  },
  {
    Value: 1000,
    Name: "Site Management Admin",
  },
  {
    Value: 1100,
    Name: "Site Housekeeping Staff",
  },
  {
    Value: 1200,
    Name: "Site Room Service Staff",
  },
  {
    Value: 1300,
    Name: "Site Front Office Staff",
  },
  {
    Value: 1400,
    Name: "Site Engineering Staff ",
  },
  {
    Value: 1500,
    Name: "Site Management Staff",
  },
  {
    Value: 1600,
    Name: "Site Contractor Staff",
  },
];