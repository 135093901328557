import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight2, ArrowDown2 } from "iconsax-react";
import { useStateContext } from "../../context/ContextProvider";
import Nav from "../../components/Nav";
import AddComponent from "../../components/AddComponent";
import Navbar from "./Navbar";

function Navigationbar({ GladianceUserMapping, HotelUserMapping }) {
    const { Department } = GladianceUserMapping;
    const { theme, FRONTEND_URL } = useStateContext();

    const [showNotification, setShowNotification] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [toggleFun, setToggleFun] = useState({
        company: false,
        GladianceUserMasters: false,
        ProductMasters: false,
        ControlBouquet: false,
        CommercialInventory: false,
        Programming: false,
        CustomerMaster: false,
        UserManagement: false,
        RoomInventory: false,
        DeviceInventory: false,
        RoomSceneInventory: false,
        gladiancedevice: false,
        InRoomDining: false,
        door: false,
        Information: false,
        GuestManagement: false,
    });

    const navigate = useNavigate();

    const ToggleNotification = (status) => {
        setShowNotification(status);
    };

    const handleToggleFun = (e, v) => {
        setToggleFun({
            [e]: v,
        });
    };

    const logout = (e) => {
        e.preventDefault();
        navigate("/");
    };

    const userNavigation = [
        { name: "Your Profile", href: "/your-profile" },
        { name: "Change Password", href: "/change-password" },
        { name: "Background Image", href: "/background-image" },
        { name: "Sign out", clickFuntion: logout },
    ];

    return (
        <Fragment>
            <AddComponent>
                <div
                    className={
                        (theme ? "bg-[#222222] text-white" : "bg-gray-200 text-black") +
                        " h-[100%] w-[100%] flex items-center justify-center rounded-xl border-gray-300"
                    }
                >
                    <Navbar GladianceUserMapping={GladianceUserMapping} HotelUserMapping={HotelUserMapping} />
                </div>
            </AddComponent>
        </Fragment>
    );
}
export default Navigationbar;
