import React, { Fragment, useEffect, useState } from "react";
import { GetGAAProjectList, GetGAAProjectListByCustomer,} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import Loader from "../../../Common/Loader";
import AddComponent from "../../../../components/AddComponent";
import Label from "../../../helpers/Label";
import CustomSelect from "../../../helpers/CustomSelect";
import Table from "../../../helpers/Table";
import DataNotFound from "../../../helpers/DataNotFound";
import Pagination from "../../../helpers/Pagination";
import LinkButton from "../../../helpers/LinkButton";
import { AddCircle } from "iconsax-react";
import Option from "../../../helpers/Option";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { GetRBItemMasterListByProject } from "../../../../Encapsulation/HotelDomainEntities/RBItemMaster";
import {DeleteRBItemApplicableServingSlot,GetRBItemApplicableServingSlotByProject,GetRBItemApplicableServingSlotByRBItem,} from "../../../../Encapsulation/HotelDomainEntities/GAAProjectRBItemApplicableServingSlot";
import { useStateContext } from "../../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function GAAProjectRBItemApplicableServingSlots({ Rights }) {
  document.title = "Gladiance - RB item serving slot";
  const tableHeading = ["Sr.No","Serving Slot Name","Is Applicable","Action"];
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [rbitemservingslotList, setRBItemServingList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [GAAProjectRBItemList, setGAAProjectRBItemList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const {GAAProjectRef,handleProjectRef,CustomerRef,handleCustomerRef,GAAProjectRBItemRef,handleRBItemRef,RBItemApplicableServingSlotCP, setRBItemApplicableServingSlotCP} = useStateContext();
  
  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  // Fetch Project List For Dropdown //
  const fetchProjectListByCustomer = async () => {
    CustomerRef &&
      setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  const handlePageChange = (pageNumber) => {
    setRBItemApplicableServingSlotCP(pageNumber);
  };

  //Fetch RB Item For Dropdown
  const fetchRBItemListByProject = async () => {
    GAAProjectRef &&
      setGAAProjectRBItemList(
        await GetRBItemMasterListByProject(GAAProjectRef)
      );
  };

  const fetchRBItemApplicableServingSlotListByRBItem = async () => {
    GAAProjectRBItemRef &&
      setRBItemServingList(
        await GetRBItemApplicableServingSlotByRBItem(GAAProjectRBItemRef)
      );
  };

  const fetchRBItemApplicableServingSlotListByProject = async () => {
    // GAAProjectRef &&
    //   setRBItemServingList(
    //     await GetRBItemApplicableServingSlotByProject(GAAProjectRef)
    //   );
  };

  const deleteRBItemApplicableServingSlot = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteRBItemApplicableServingSlot(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchRBItemApplicableServingSlotListByRBItem();
          fetchRBItemApplicableServingSlotListByProject();
          (rbitemservingslotList.length - 1) % itemsPerPage == 0 && setRBItemApplicableServingSlotCP(RBItemApplicableServingSlotCP - 1);

        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == "" && ProjectList.length > 0) {
      handleProjectRef(ProjectList[0].Ref);
    }
  }, [ProjectList]);

  useEffect(() => {
    fetchRBItemApplicableServingSlotListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    if (GAAProjectRBItemRef == "" && GAAProjectRBItemList.length > 0) {
      handleRBItemRef(GAAProjectRBItemList[0].Ref);
    }
  }, [GAAProjectRBItemList]);

  useEffect(() => {
    fetchRBItemListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    fetchRBItemApplicableServingSlotListByRBItem();
  }, [GAAProjectRBItemRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"RB Item Applicable Serving"}
          button={
            <LinkButton
              to={
                "/rb-item-applicable-serving-slots/add-/rb-item-applicable-serving-slots"
              }
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={{
                GAAProjectRef: GAAProjectRef,
                GAAProjectRBItemRef: GAAProjectRBItemRef,
              }}
              >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Serving</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAprojectRef"} labelText={"Project"} />
              <CustomSelect
                id={"GAAprojectRef"}
                name={"GAAprojectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {ProjectList.map((data, id) => {
                  return (
                      <Option key={id} value={data.Ref}>{data.Name}</Option>
                  );
                })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAProjectRBItemRef"} labelText={"RB Item "} />
              <CustomSelect
                id={"GAAProjectRBItemRef"}
                name={"GAAProjectRBItemRef"}
                value={GAAProjectRBItemRef}
                onChange={(e) => handleRBItemRef(e.target.value)}
                required={true}
              >
                {GAAProjectRBItemList.map((data, id) => {
                  return (
                      <Option key={id} value={data.Ref}>{data.Name}</Option>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {rbitemservingslotList && rbitemservingslotList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {rbitemservingslotList
                  .slice(
                    (RBItemApplicableServingSlotCP - 1) * itemsPerPage,
                    RBItemApplicableServingSlotCP * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    const serialNumber = (RBItemApplicableServingSlotCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white text-center">
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">
                            {tableData.GAAProjectRBItemServingSlotName}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.IsApplicable == 1 ? "Yes" : "No"}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/rb-item-applicable-serving-slots/edit-/rb-item-applicable-serving-slots/${tableData.GAAProjectRBItemRef}/${tableData.GAAProjectRBItemServingSlotRef}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton
                                  onClick={() =>
                                    deleteRBItemApplicableServingSlot(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Serving Slot Data Not Found"} />
          )}
          {rbitemservingslotList && rbitemservingslotList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={rbitemservingslotList.length}
                currentPage={RBItemApplicableServingSlotCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectRBItemApplicableServingSlots;
