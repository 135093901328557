import React, { Fragment, useEffect, useRef, useState } from "react";
import axiosClient from "../../Encapsulation/Axios";
import { useStateContext } from "../../context/ContextProvider";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Button from "../helpers/Button";
import Input from "../helpers/Input";
import Label from "../helpers/Label";
import Validation from "../helpers/Validation";
import CheckBox from "../helpers/CheckBox";
import Loader from "../Common/Loader";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Eye, EyeSlash, Lock, User } from "iconsax-react";

function Login() {
  document.title = "Gladiance - Login";
  const [formInfo, setFormInfo] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState([]);
  const { theme } = useStateContext();

  const navigate = useNavigate();
  const { setCurrentUser, setUserToken, FRONTEND_URL } = useStateContext();
  const [loader, setLoader] = useState(false);
  const [remeberMe, setRemeberMe] = useState(false);
  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (remeberMe === true) {
      Cookies.set("email", formInfo.email);
      Cookies.set("password", formInfo.password);
      Cookies.set("remember", true);
    }
    const formData = new FormData();
    formData.append("email", formInfo.email);

    formData.append("password", formInfo.password);
    // setUserToken(50000002)
    navigate("/dashboard");
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <div className="absolute flex md:h-[82%] sm:h-[80%] m-[2%] md:w-[31%] sm:w-[51%] justify-center rounded-lg border-2  items-center">
          <div className="p-3 md:p-0 w-[90%]">
            <div className="flex justify-center">
              <img
                className=" w-[65%] mb-10"
                src={FRONTEND_URL + "/assets/logos/logo1.png"}
                alt="Your Company"
              />
            </div>
            <div className="flex justify-center">
              <h2 className="text-[140%] text-white uppercase font-extrabold tracking-widest ">
                Login
              </h2>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm  ">
                <div className="mb-3">
                  <Label
                    labelText={"User ID"}
                    htmlFor="email"
                    className={"font-medium"}
                  />
                  <div className="flex items-center ">
                    <Input
                      inputFocus={true}
                      type={"email"}
                      value={formInfo.email}
                      name={"email"}
                      id={"email"}
                      onChange={handleChange}
                    />
                    <Validation error={errors.email} />
                    <User
                      className="h-[10%] w-10%] m-1 "
                      color="#ffffff"
                      variant="Bold"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <Label
                    labelText={"Password"}
                    htmlFor="password"
                    className={" font-medium"}
                  />
                  <div className="flex items-center">
                    <div className="relative w-full">
                      <Input
                        className={"bg-transparent "}
                        type={passwordType}
                        value={formInfo.password}
                        name={"password"}
                        id={"password"}
                        onChange={handleChange}
                      />
                      {passwordType === "password" ? (
                        <Eye
                          className="text-white w-6 h-6 absolute top-2 right-3 cursor-pointer "
                          variant="Bold"
                          onClick={togglePassword}
                        />
                      ) : (
                        <EyeSlash
                          className="text-white w-6 h-6 absolute top-2 right-3 cursor-pointer "
                          variant="Bold"
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                    <Lock
                      className="h-[10%] w-10%] m-1 "
                      color="#ffffff"
                      variant="Bold"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between pb-5 ">
                <div className="flex items-center">
                  <input
                    className={
                      "bg-transparent border-white h-5 w-5 rounded text-transparent"
                    }
                    id={"remeber-me"}
                    name={"remember_me"}
                    type={"checkbox"}
                    onClick={(e) => setRemeberMe(e.target.checked)}
                    checked={remeberMe}
                  />
                  <Label
                    labelText={"Remember Me"}
                    htmlFor={"remeber-me"}
                    className={" font-medium text-[90%]"}
                  />
                </div>
                <div>
                  <Link
                    to="/forget-password"
                    className="font-medium text-default-color text-[90%]"
                  >
                    Forgot password..?
                  </Link>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  id="login"
                  name="login"
                  className={
                    (theme ? "text-gray-800" : "text-default-color") +
                    " w-[60%] bg-gradient-to-r from-orange-500 to-yellow-200 hover:bg-gradient-to-bl text-default-colorgroup relative flex justify-center rounded-md  p-2 tracking-wider font-semibold "
                  }
                >
                  <span className=" inset-y-0 left-0 flex items-center text-xl">
                    Login
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Login;
