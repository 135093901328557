import React, { Fragment, useEffect, useRef, useState } from 'react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { AddCircle } from 'iconsax-react'
import Loader from '../../Common/Loader'
import AddComponent from '../../../components/AddComponent'
import Table from '../../helpers/Table'
import LinkButton from '../../helpers/LinkButton'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination'
import DataNotFound from '../../helpers/DataNotFound'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import { GetGAAProjectListByCustomer } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import Option from '../../helpers/Option'
import { DeleteHotelAmenities, GetProjectAmenitiesListByProject } from '../../../Encapsulation/HotelDomainEntities/HotelAmenities'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { useStateContext } from '../../../context/ContextProvider'
import { GetGladianceCustomerList } from '../../../Encapsulation/GladianceDomainEntities/Customer'

function Amenities({ Rights }) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - Amenities'
    const tableHeading = ['Sr.No', 'Name', 'Video URL', 'From Time', 'To Time', 'Description', 'Action']
    const [loader, setLoader] = useState(false)
    const itemsPerPage = 7;
    const [ProjectList, setProjectList] = useState([]);
    const [AmenitiesList, setAmenitiesList] = useState([])
    const [customerList, setCustomerList] = useState([]);

    const { GAAProjectRef, handleProjectRef, CustomerRef, handleCustomerRef, AmenitiesCP, setAmenitiesCP } = useStateContext();

    //Fetch Customer List For Dropdown//
    const fetchCustomerList = async () => {
        setCustomerList(await GetGladianceCustomerList());
    };

    // Fetch Project List For Dropdown //
    const fetchProjectListByCustomer = async () => {
        CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
    };
    // fetch Amenities List According to Project Ref //
    const fetchProjectAmenitiesListByProject = async () => {
        GAAProjectRef && setAmenitiesList(await GetProjectAmenitiesListByProject(GAAProjectRef));
    };

    const handlePageChange = (pageNumber) => {
        setAmenitiesCP(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const deleteAmenitie = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteHotelAmenities(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchProjectAmenitiesListByProject();
                    (AmenitiesList.length - 1) % itemsPerPage == 0 && setAmenitiesCP(AmenitiesCP - 1);

                }
            }
        })
    }

    useEffect(() => {
        fetchCustomerList();
    }, []);

    useEffect(() => {
        if (CustomerRef == "" && customerList.length > 0) {
            handleCustomerRef(customerList[0].Ref);
        }
    }, [customerList]);

    useEffect(() => {
        fetchProjectListByCustomer();
    }, [CustomerRef]);

    useEffect(() => {
        if (GAAProjectRef == '' && ProjectList.length > 0) {
            handleProjectRef(ProjectList[0].Ref);
        }
    }, [ProjectList]);

    useEffect(() => {
        fetchProjectAmenitiesListByProject();
    }, [GAAProjectRef])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Amenities'} button={
                    <LinkButton to={'/amenities/add-amenities'} className={Rights.Add ? 'opacity-100' : 'hidden'} state={GAAProjectRef}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Amenities</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                        <div>
                            <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
                            <CustomSelect
                                id={"CustomerRef"}
                                name={"CustomerRef"}
                                value={CustomerRef}
                                onChange={(e) => handleCustomerRef(e.target.value)}
                            >
                                {customerList &&
                                    customerList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAprojectRef"}
                                labelText={"Project"}
                            />
                            <CustomSelect
                                id={"GAAprojectRef"}
                                name={"GAAprojectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => handleProjectRef(e.target.value)}
                                required={true}
                            >
                                {ProjectList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                    </div>
                    {AmenitiesList && AmenitiesList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading} >
                                {AmenitiesList.slice((AmenitiesCP - 1) * itemsPerPage, AmenitiesCP * itemsPerPage).map((tableData, tableDataID) => {
                                    const serialNumber = (AmenitiesCP - 1) * itemsPerPage + tableDataID + 1;
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{serialNumber}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3"> <a href={tableData.VideoUrl} className="text-blue-400" target="_blank">{tableData.VideoUrl}</a></td>
                                                <td className="px-4 py-3">{tableData.FromTime}</td>
                                                <td className="px-4 py-3">{tableData.ToTime}</td>
                                                <td className="px-4 py-3">{tableData.Description}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link
                                                            to={`/amenities/edit-amenities/${tableData.Ref}`}
                                                            title="Edit"
                                                        >
                                                            <ActionButton
                                                                className={
                                                                    "p-1.5 text-white bg-yellow-400 "
                                                                }
                                                            >
                                                                <PencilSquareIcon className="w-4 h-4" />
                                                            </ActionButton>
                                                        </Link>
                                                        {Rights.Delete && (
                                                            <ActionButton onClick={() => deleteAmenitie(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                <TrashIcon className='w-4 h-4' />
                                                            </ActionButton>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Amenities Data Not Found'} />
                    )}
                    {AmenitiesList && AmenitiesList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={AmenitiesList.length}
                            currentPage={AmenitiesCP}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}

export default Amenities


