import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import { DeleteHotelLaundry, GetGAAHotelLaundryListByProject } from "../../../Encapsulation/HotelDomainEntities/HotelLaundry";
import Label from "../../helpers/Label";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectListByCustomer } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { GetGladianceCustomerList } from "../../../Encapsulation/GladianceDomainEntities/Customer";
import { useStateContext } from "../../../context/ContextProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Laundry({ Rights }) {
  document.title = "Gladiance - Laundry List";
  const tableHeading = ["Sr.No", "Name", "Press Charges", "Drywash Charge", "Action",];
  const [loader, setLoader] = useState(false);
  const [laundryList, setLaundryList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const { CustomerRef, GAAProjectRef, handleProjectRef, handleCustomerRef, LaundryCP, setLaundryCP } = useStateContext();
  const itemsPerPage = 7;

  const handlePageChange = (pageNumber) => {
    setLaundryCP(pageNumber);
  };


  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  // Fetch Project List For Dropdown //
  const fetchProjectListByCustomer = async () => {
    CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  //Fetch Laundry List By Project//
  const fetchLaundryListByProject = async () => {
    GAAProjectRef &&
      setLaundryList(await GetGAAHotelLaundryListByProject(GAAProjectRef));
  };


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const deleteLaundry = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteHotelLaundry(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchLaundryListByProject();
          (laundryList.length - 1) % itemsPerPage == 0 && setLaundryCP(LaundryCP - 1);

        }
      }
    })
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == '' && ProjectList.length > 0) {
      handleProjectRef(ProjectList[0].Ref);
    }
  }, [ProjectList]);

  useEffect(() => {
    fetchLaundryListByProject();
  }, [GAAProjectRef]);


  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Laundry List"}
          button={
            <LinkButton to={"/laundry/add-laundry"} className={Rights.Add ? 'opacity-100' : 'hidden'} state={GAAProjectRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Laundry Details</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAprojectRef"}
                labelText={"Project"}
              />
              <CustomSelect
                id={"GAAprojectRef"}
                name={"GAAprojectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {ProjectList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {laundryList && laundryList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {laundryList
                  .slice(
                    (LaundryCP - 1) * itemsPerPage,
                    LaundryCP * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    const serialNumber = (LaundryCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white text-center">
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            {tableData.PressCharges}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.DryWashCharges}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/laundry/edit-laundry/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton onClick={() => deleteLaundry(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Laundry Data Not Found"} />
          )}
          {laundryList && laundryList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={laundryList.length}
                currentPage={LaundryCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default Laundry;
