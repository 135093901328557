import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetGladianceCustomer, SaveGladianceCustomer } from '../../../Encapsulation/GladianceDomainEntities/Customer'
import { GetGladianceCountryList } from '../../../Encapsulation/GladianceDomainEntities/GladianceCountryMaster'
import Option from '../../helpers/Option'
import CustomSelect from '../../helpers/CustomSelect'


function CustomerAddEdit() {
    let formMode = 'New';
    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit Customer'
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add Customer'
        }
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const [gladianceCountryList, setGladianceCountryList] = useState([]);

    const [formInfo, setFormInfo] = useState({})
    
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    
    const fetchGladianceCountryList = async () => {
        let countryList = await GetGladianceCountryList();
        setGladianceCountryList(countryList);
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('formInfo :', formInfo);
        
        let result = await SaveGladianceCustomer(id, formInfo, []);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/customer');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
            }
        }
    };

    const fetchCustomer = async () => {
        let Customer = await GetGladianceCustomer(id);
        setFormInfo(Customer);
    }

    // const formatDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const hours = String(date.getHours()).padStart(2, '0');
    //     const minutes = String(date.getMinutes()).padStart(2, '0');
    //     const seconds = String(date.getSeconds()).padStart(2, '0');
    //     const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    //     return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    // };

    useEffect(() => {
        if (id) {
            fetchCustomer();
        }
        fetchGladianceCountryList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Customer' : 'Add Customer'} button={
                    <LinkButton to={'/customer'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'Name'} />
                                        <Input inputFocus={true} id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} required={true} minLength={2} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'GoogleLocation'} labelText={'Google Location'} />
                                        <Input id={'GoogleLocation'} type={'text'} name={'GoogleLocation'} value={formInfo.GoogleLocation} onChange={handleChange} required={true} />
                                        <Validation error={errors.GoogleLocation} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Address'} labelText={'Address'} />
                                        <Input id={'Address'} type={'text'} name={'Address'} value={formInfo.Address} onChange={handleChange} required={true} />
                                        <Validation error={errors.Address} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'CountryRef'} labelText={'Country'} />
                                        <CustomSelect id={'CountryRef'} name={'CountryRef'} value={formInfo.CountryRef} onChange={handleChange}>
                                            <Option value={''}>--- Select Country ---</Option>
                                            {gladianceCountryList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option

                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.CountryRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'CityName'} labelText={'City Name'} />
                                        <Input id={'CityName'} type={'text'} name={'CityName'} value={formInfo.CityName} onChange={handleChange} required={true} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.CityName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Pincode'} labelText={'Pincode'} />
                                        <Input id={'Pincode'} type={'text'} name={'Pincode'} value={formInfo.Pincode} onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} maxLength={6} minLength={6} required={true} />
                                        <Validation error={errors.Pincode} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Website'} labelText={'Website'} />
                                        <Input id={'Website'} type={'text'} name={'Website'} value={formInfo.Website} onChange={handleChange} onKeyPress={(event) => { if (/^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([/?].*)?$/.test(event.key)) { event.preventDefault() } }} required={true} />
                                        <Validation error={errors.Website} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Telephone'} labelText={'Telephone'} />
                                        <Input id={'Telephone'} type={'text'} name={'Telephone'} value={formInfo.Telephone} onChange={handleChange} required={true} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} minLength={10} maxLength={10} />
                                        <Validation error={errors.Telephone} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/customer'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default CustomerAddEdit
