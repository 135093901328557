import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import axiosClient from "../../../Encapsulation/Axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import ActionButton from "../../helpers/ActionButton";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import {
  DeleteGladianceCustomer,
  GetGladianceCustomerList,
} from "../../../Encapsulation/GladianceDomainEntities/Customer";
import { useStateContext } from "../../../context/ContextProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Customer() {
  document.title = "Gladiance - Customers";
  const tableHeading = [
    "Sr.No",
    "Customer Name",
    "Google Location",
    "Address",
    "Country",
    "City",
    "Pincode",
    "Website",
    "Telephone",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  console.log("customerList :", customerList);
  const [errors, setErrors] = useState([]);
  const { CustomerCP, setCustomerCP } = useStateContext();
  const itemsPerPage = 7;

  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  const handlePageChange = (pageNumber) => {
    setCustomerCP(pageNumber);
  };

  const deleteCustomer = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGladianceCustomer(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchCustomerList();
          (customerList.length - 1) % itemsPerPage == 0 &&
            setCustomerCP(CustomerCP - 1);
        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Customer List"}
          button={
            <LinkButton to={"/customer/add-customer"}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Customer</span>
            </LinkButton>
          }
        >
          {customerList && customerList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {customerList
                  .slice(
                    (CustomerCP - 1) * itemsPerPage,
                    CustomerCP * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    const serialNumber = (CustomerCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            {tableData.GoogleLocation}
                          </td>
                          <td className="px-4 py-3">{tableData.Address}</td>
                          <td className="px-4 py-3">{tableData.CountryName}</td>
                          <td className="px-4 py-3">{tableData.CityName}</td>
                          <td className="px-4 py-3">{tableData.Pincode}</td>
                          {/* <td className="px-4 py-3">{tableData.Website} </td> */}
                          <td className="px-4 py-3"><a href={tableData.Website} className="text-blue-400" target="_blank">{tableData.Website}</a></td>
                          <td className="px-4 py-3">{tableData.Telephone}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/customer/edit-customer/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              <ActionButton
                                onClick={() => deleteCustomer(tableData)}
                                title={"Delete"}
                                className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </ActionButton>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Customer Data Not Found"} />
          )}
          {customerList && customerList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={customerList.length}
                currentPage={CustomerCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}
export default Customer;
