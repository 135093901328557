import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import Label from "../../../helpers/Label";
import CustomSelect from "../../../helpers/CustomSelect";
import Option from "../../../helpers/Option";
import { DeleteProjectSpaceType, GetGAAProjectSpaceTypeListByProject, } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectListByCustomer, } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";
import { useStateContext } from "../../../../context/ContextProvider";

function GAAProjectSpaceType({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Project Space Types ";
  const tableHeading = ["Sr.No", "Space Type", "Has Areas", "Action"];
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [customerList, setCustomerList] = useState([]);
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState();
  const { CustomerRef, GAAProjectRef, handleCustomerRef, handleProjectRef, SpaceTypeCP, setSpaceTypeCP } = useStateContext();

  const handlePageChange = (pageNumber) => {
    setSpaceTypeCP(pageNumber);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  // Fetch Project List For Dropdown//
  const fetchGAAProjectList = async () => {
    CustomerRef &&
      setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  // Fetch Space Type By Project Name //
  const fetchSpaceTypeList = async () => {
    GAAProjectRef &&
      setGAAProjectSpaceTypeList(
        await GetGAAProjectSpaceTypeListByProject(GAAProjectRef)
      );
  };

  const deleteProjectSpaceType = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteProjectSpaceType(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchSpaceTypeList();
          (GAAProjectSpaceTypeList.length - 1) % itemsPerPage == 0 && setSpaceTypeCP(SpaceTypeCP - 1);

        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);


  useEffect(() => {
    fetchGAAProjectList();
  }, [CustomerRef]);

  //  Set Zero Index Project Name For Dropdown //
  useEffect(() => {
    if (GAAProjectRef == "" && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchSpaceTypeList();
  }, [GAAProjectRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Project Space Group Types"}
          button={
            <LinkButton
              to={"/gaa-project-space-type/add-gaa-project-space-type"}
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={GAAProjectRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Project Space Type</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                      <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={"Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
              >
                {GAAProjectList &&
                  GAAProjectList.map((data, id) => {
                    return (
                      <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
          </div>
          <div className="relative">
            {GAAProjectSpaceTypeList && GAAProjectSpaceTypeList.length > 0 ? (
              <div ref={componentRef} className="m-2 pb-7">
                <Table tableHeading={tableHeading}>
                  {GAAProjectSpaceTypeList &&
                    GAAProjectSpaceTypeList.slice(
                      (SpaceTypeCP - 1) * itemsPerPage,
                      SpaceTypeCP * itemsPerPage
                    ).map((tableData, tableDataID) => {
                      const serialNumber = (SpaceTypeCP - 1) * itemsPerPage + tableDataID + 1;
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{serialNumber}</td>
                            <td className="px-4 py-3">{tableData.Name}</td>
                            <td className="px-4 py-3">
                              {" "}
                              {tableData.HasAreas == 1 ? "Yes" : "No"}
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/gaa-project-space-type/edit-gaa-project-space-type/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={"p-1.5 text-white bg-yellow-400 "}
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                {Rights.Delete && (
                                  <ActionButton
                                    onClick={() =>
                                      deleteProjectSpaceType(tableData)
                                    }
                                    title={"Delete"}
                                    className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                  >
                                    <TrashIcon className="w-4 h-4" />
                                  </ActionButton>
                                )}
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </Table>
              </div>
            ) : (
              <DataNotFound
                labelText={"Sorry! Project Space Type Data Not Found"}
              />
            )}
          </div>
          {GAAProjectSpaceTypeList && GAAProjectSpaceTypeList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={GAAProjectSpaceTypeList.length}
                currentPage={SpaceTypeCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceType;
