import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../../Encapsulation/Axios'
import flasher from '@flasher/flasher'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { AddCircle, ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo, FormatInputDate, FormatOutputDate } from '../../../Encapsulation/Utils'
// import axios from 'axios'
import Table from '../../helpers/Table'
import ActionButton from '../../helpers/ActionButton'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import DataNotFound from '../../helpers/DataNotFound'
import Swal from 'sweetalert2'
import CustomSelect from '../../helpers/CustomSelect'
import Option from '../../helpers/Option'
import { GetGAAProjectSpaceListBySpaceGroup } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace'
import { AllocateSingleId } from "../../../../src/Encapsulation/AllocateId";

function CheckInAddEdit() {
  let formMode = 'New';
  const { id } = useParams()
  if (id) {
    document.title = 'Gladiance - Edit Check In'
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Check In'
  }
  const Navigation = useNavigate()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const [CheckInDateTime, setCheckInDateTime] = useState('');
  const [ExpectedCheckOutDateTime, setExpectedCheckOutDateTime] = useState('');
  const [CheckOutDateTime, setCheckOutDateTime] = useState('');
  const [MemberAddEditBtn, setMemberAddEditBtn] = useState(true);
  const [MemberList, setMemberList] = useState([]);
  const [ProjectSpaceList, setProjectSpaceList] = useState([]);
  const Location = useLocation()
  const { GAAProjectSpaceGroupRef, update } = Location.state;
  const tableHeading = ['Sr.No.', 'Member Name', 'Contact', 'Email', 'Action'];
  const loginToken = '36983F8347364554B0E3132DD99267C4';
  const loginDeviceId = 'c03ea267-031f-4eb8-a441-e2150129d5f2';
  const [formInfo, setFormInfo] = useState({
    GAAProjectSpaceRef: 5000010000008586
  })
  const [MemberInfo, setMemberInfo] = useState({})
  const [MemberId, setMemberId] = useState('')

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  // fetch Project Space List By Space Group //
  const fetchGAAProjectSpaceListBySpaceGroup = async () => {
    Location.state &&
      setProjectSpaceList(
        await GetGAAProjectSpaceListBySpaceGroup(GAAProjectSpaceGroupRef)
      );
  };

  const fetchTime = async () => {
    axiosClient.get(`request/currentdatetime`)
      .then(({ data }) => {
        setLoader(false)
        setFormInfo(formInfo => ({
          ...formInfo,
          CheckInDateTime: data,
        }))
        setCheckInDateTime(FormatInputDate(data));
      })
  }

  const handleCheckInDateTime = (e) => {
    setCheckInDateTime(e.target.value);
    setFormInfo(formInfo => ({
      ...formInfo,
      CheckInDateTime: FormatOutputDate(e.target.value),
    }))
  }

  const handleExpectedCheckOutDateTime = (e) => {
    setExpectedCheckOutDateTime(e.target.value)
    setFormInfo(formInfo => ({
      ...formInfo,
      ExpectedCheckOutDateTime: FormatOutputDate(e.target.value),
    }))
  }

  const handleCheckOutDateTime = (e) => {
    setCheckOutDateTime(e.target.value)
    setFormInfo(formInfo => ({
      ...formInfo,
      CheckOutDateTime: FormatOutputDate(e.target.value),
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()

    formInfo["Ref"] = await AllocateSingleId(formInfo["Ref"]);

    axiosClient.post('guestoperations/' + (id ? (update ? ('checkoutroom/' + id + '/') : 'updatecheckindetails/') : 'checkinguestintoroom/') + loginToken + '/' + loginDeviceId, formInfo)
      .then(({ data }) => {
        setLoader(false)
        setFormInfo(ClearInfo(formInfo))
        if (data.Successful) {
          flasher.success('Data Added Successfully')
        } else {
          flasher.warning(data.Message)
        }
        if (formMode == 'Edit') {
          Navigation('/check-in');
        }
      }).catch((error) => {
        flasher.warning(error)
      })
  }

  const fetchCheckIn = async () => {
    axiosClient.get(`guestoperations/checkindetails/${id}/${loginToken}/${loginDeviceId}`)
      .then(({ data }) => {
        setLoader(false)
        setFormInfo(data.ObjectTag);
        setCheckInDateTime(FormatInputDate(data.ObjectTag.CheckInDateTime));
        setExpectedCheckOutDateTime(FormatInputDate(data.ObjectTag.ExpectedCheckOutDateTime));
      })
    formMode = 'Edit'
  }

  const handleMember = (e) => {
    setMemberInfo(MemberInfo => ({
      ...MemberInfo,
      [e.target.name]: e.target.value
    }))
  }

  const handleMemberEdit = (tableData, id) => {
    setMemberAddEditBtn(false);
    setMemberInfo(tableData);
    setMemberId(id)
  }

  const AddMember = () => {
    let List = [];
    const { MemberName, MemberPhoneNos, MemberEMailId } = MemberInfo;
    if (MemberName && MemberPhoneNos && MemberEMailId) {
      List = MemberList;
      if (MemberAddEditBtn) {
        List = [...List, MemberInfo];
        setMemberList(List);
      } else {
        List[MemberId] = MemberInfo;
        setMemberList(List);
        setMemberAddEditBtn(true)
      }
    }
    if (List.length > 0) {
      setFormInfo(formInfo => ({
        ...formInfo,
        MemberList: List
      }))
      setMemberInfo(ClearInfo(MemberInfo));
    }
  }

  const deleteMember = (memberIndex) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let List = MemberList.filter((data, index) => index != memberIndex);
        setMemberList(List);
        setMemberInfo(ClearInfo(MemberInfo));
        setFormInfo(formInfo => ({
          ...formInfo,
          MemberList: List
        }))
        setMemberAddEditBtn(true);
        flasher.success('Record Deleted Successfully')
      }
    })
  }

  useEffect(() => {
    if (Location.state) {
      fetchGAAProjectSpaceListBySpaceGroup();
    }
    if (id) {
      fetchCheckIn();
    }
    fetchTime();
  }, [])

  useEffect(() => {
    if (!id) {
      if (formInfo.GAAProjectSpaceRef == "" && ProjectSpaceList.length > 0) {
        setFormInfo(formInfo => ({
          ...formInfo,
          GAAProjectSpaceRef: ProjectSpaceList[0].Ref,
        }))
      }
    }
  }, [ProjectSpaceList]);

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? 'Edit Check In' : 'Add Check In'} button={
          <LinkButton to={'/check-in'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'GAAProjectSpaceRef'} labelText={'Room No'} />
                    {
                      update ? (
                        <Input id={'GAAProjectSpaceName'} type={'text'} name={'GAAProjectSpaceName'} className={update && 'opacity-50'} value={formInfo.GAAProjectSpaceName} required={true} readOnly={update} />
                      ) : (
                        <CustomSelect
                          id={"GAAProjectSpaceRef"}
                          name={"GAAProjectSpaceRef"}
                          value={formInfo.GAAProjectSpaceRef}
                          onChange={handleChange}
                        >
                          {ProjectSpaceList &&
                            ProjectSpaceList.map((data, id) => {
                              return (
                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                              );
                            })}
                        </CustomSelect>
                      )
                    }
                    <Validation error={errors.GAAProjectSpaceRef} />
                  </div>
                  <div className='col-span-2'>
                    <Label htmlFor={'Name'} labelText={'Guest Name'} />
                    <Input id={'Name'} type={'text'} name={'GuestName'} className={update && 'opacity-50'} value={formInfo.GuestName} onChange={handleChange} required={true} readOnly={update} />
                    <Validation error={errors.Name} />
                  </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'CheckInDateTime'} labelText={'Check In Date-Time'} />
                    <Input id={'CheckInDateTime'} type={'datetime-local'} name={'CheckInDateTime'} className={update && 'opacity-50'} value={CheckInDateTime} onChange={handleCheckInDateTime} required={true} readOnly={update} />
                    <Validation error={errors.CheckInDateTime} />
                  </div>
                  <div>
                    <Label htmlFor={'ExpectedCheckOutDateTime'} labelText={'Expected Check Out Date-Time'} />
                    <Input id={'ExpectedCheckOutDateTime'} type={'datetime-local'} name={'ExpectedCheckOutDateTime'} className={update && 'opacity-50'} value={ExpectedCheckOutDateTime} onChange={handleExpectedCheckOutDateTime} required={true} readOnly={update} />
                    <Validation error={errors.ExpectedCheckOutDateTime} />
                  </div>
                  <div>
                    <Label htmlFor={'Contact'} labelText={'Contact'} />
                    <Input id={'GuestPhoneNos'} type={'text'} name={'GuestPhoneNos'} className={update && 'opacity-50'} value={formInfo.GuestPhoneNos} onChange={handleChange} required={true} readOnly={update} />
                    <Validation error={errors.Mobile} />
                  </div>
                  <div>
                    <Label htmlFor={'Email'} labelText={'Email'} />
                    <Input id={'GuestEMailId'} type={'email'} name={'GuestEMailId'} className={update && 'opacity-50'} value={formInfo.GuestEMailId} onChange={handleChange} required={true} readOnly={update} />
                    <Validation error={errors.Email} />
                  </div>
                  {
                    update && (
                      <div>
                        <Label htmlFor={'CheckOutDateTime'} labelText={'Actual Check Out Date-Time'} />
                        <Input id={'CheckOutDateTime'} type={'datetime-local'} name={'CheckOutDateTime'} value={CheckOutDateTime} onChange={handleCheckOutDateTime} required={update} />
                        <Validation error={errors.CheckOutDateTime} />
                      </div>
                    )
                  }

                </div>
                {/* <br />
                <h1 className={"text-[4vw] md:text-2xl mb-3 font-bold tracking-tight "}>Add Members</h1>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'MemberName'} labelText={'Member Name'} />
                    <Input id={'MemberName'} type={'text'} name={'MemberName'} value={MemberInfo.MemberName} onChange={handleMember} />
                    <Validation error={errors.Mobile} />
                  </div>
                  <div>
                    <Label htmlFor={'Contact'} labelText={'Phone No(s)'} />
                    <Input id={'MemberPhoneNos'} type={'text'} name={'MemberPhoneNos'} value={MemberInfo.MemberPhoneNos} onChange={handleMember} />
                    <Validation error={errors.Mobile} />
                  </div>
                  <div>
                    <Label htmlFor={'MemberEmail'} labelText={'Email Id'} />
                    <Input id={'MemberEMailId'} type={'text'} name={'MemberEMailId'} value={MemberInfo.MemberEMailId} onChange={handleMember} />
                    <Validation error={errors.Mobile} />
                  </div>
                  <div className="">
                    <LinkButton onClick={() => AddMember()} className='flex justify-center w-full mt-7 gap-5 tracking-wider'>
                      {MemberAddEditBtn ? 'Add' : 'Edit'} Member<AddCircle variant="Outline" className="h-5 w-5" />
                    </LinkButton>
                  </div>
                </div>
                {MemberList &&
                  MemberList.length > 0 ? (
                  <div className="m-2">
                    <Table tableHeading={tableHeading}>
                      {MemberList.map((tableData, tableDataID) => {
                        return (
                          <Fragment key={tableDataID}>
                            <tr className="border-b border-opacity-30 border-white">
                              <td className="px-4 py-3">{tableDataID + 1}</td>
                              <td className="px-4 py-3">{tableData.MemberName}</td>
                              <td className="px-4 py-3">{tableData.MemberPhoneNos}</td>
                              <td className="px-4 py-3">{tableData.MemberEMailId}</td>
                              <td className="px-4 py-3">
                                <div className="flex justify-center gap-3">
                                  <Link
                                    onClick={() => handleMemberEdit(tableData, tableDataID)}
                                    title="Edit"
                                  >
                                    <ActionButton
                                      className={"p-1.5 text-white bg-yellow-400 "}
                                    >
                                      <PencilSquareIcon className="w-4 h-4" />
                                    </ActionButton>
                                  </Link>
                                  <ActionButton
                                    onClick={() =>
                                      deleteMember(tableDataID)
                                    }
                                    title={"Delete"}
                                    className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                  >
                                    <TrashIcon className="w-4 h-4" />
                                  </ActionButton>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </Table>
                  </div>
                ) : (
                  <DataNotFound className={'md:text-xl'} className2={'md:h-[100px]'} labelText={"Sorry! Members Data Not Found"} />
                )}*/}
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/check-in'} />
                  <SaveButton text='Save' />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )
      }
    </Fragment >
  )
}



export default CheckInAddEdit
