import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { GetGAAProjectList, GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGAAProjectSpaceTypeListByProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { GetGAAProjectSpaceTypePlannedDeviceByArea, GetGAAProjectSpaceTypePlannedDeviceBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDevice";
import { DeleteGAAProjectSpaceTypePlannedDeviceConnection, GetGAAProjectSpaceTypePlannedDeviceConnectionByPlannedDevice, } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDeviceConnections";
import flasher from "@flasher/flasher";
import Swal from "sweetalert2";
import { useStateContext } from "../../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function GAAProjectSpaceTypePlannedDeviceConnections({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Connections";
  const tableHeading = ["Sr.No", "Lable", "Wattage", "Product", "From Input", "From Output", "To Input", "To Output", "Action",];
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [customerList, setCustomerList] = useState([])
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
  const [GAASpaceTypeAreaList, setGAASpaceTypeAreaList] = useState([]);
  const [DeviceConnectionList, setDeviceConnectionList] = useState([]);
  const [ProjectSpaceTypePlannedDeviceList, setProjectSpaceTypePlannedDeviceList,] = useState([]);
  const { CustomerRef, GAAProjectRef, GAAProjectSpaceTypeRef, GAAProjectSpaceTypeAreaRef, GAAProjectSpaceTypePlannedDeviceRef, handleCustomerRef, handleProjectRef, handleSpaceTypeRef, handleSpaceTypeAreaRef, handlePlannedDeviceRef, PlannedDeviceConnectionCP, setPlannedDeviceConnectionCP } = useStateContext();

  const handlePageChange = (pageNumber) => {
    setPlannedDeviceConnectionCP(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  }

  //Fetch Project List By Customer for Dropdowm //
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  // Fetch Project Space Type List For Dropdown By Project //
  const fetchSpaceTypeListByProject = async () => {
    GAAProjectRef &&
      setGAAProjectSpaceTypeList(
        await GetGAAProjectSpaceTypeListByProject(GAAProjectRef)
      );
  };

  // Fetch Area List For Dropdown By Space Type//
  const fetchAreaBySpaceType = async () => {
    GAAProjectSpaceTypeRef &&
      setGAASpaceTypeAreaList(
        await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef)
      );
  };

  // fetchProject Space Type Planned Device List According to Space Type Ref //
  const fetchProjectSpaceTypePlannedDeviceListBySpaceType = async () => {
    GAAProjectSpaceTypeRef &&
      setProjectSpaceTypePlannedDeviceList(
        await GetGAAProjectSpaceTypePlannedDeviceBySpaceType(
          GAAProjectSpaceTypeRef
        )
      );
  };

  // fetchProject Space Type Planned Device List According to Area Ref //
  const fetchProjectSpaceTypePlannedDeviceListByArea = async () => {
    GAAProjectSpaceTypeAreaRef &&
      setProjectSpaceTypePlannedDeviceList(
        await GetGAAProjectSpaceTypePlannedDeviceByArea(
          GAAProjectSpaceTypeAreaRef
        )
      );
  };

  const fetchDeviceConnectionByPlannedDevice = async () => {
    GAAProjectSpaceTypePlannedDeviceRef &&
      setDeviceConnectionList(
        await GetGAAProjectSpaceTypePlannedDeviceConnectionByPlannedDevice(
          GAAProjectSpaceTypePlannedDeviceRef
        )
      );
  };

  const deleteDeviceConnection = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectSpaceTypePlannedDeviceConnection(
          tableData
        );
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchDeviceConnectionByPlannedDevice();
          (DeviceConnectionList.length - 1) % itemsPerPage == 0 && setPlannedDeviceConnectionCP(PlannedDeviceConnectionCP - 1);

        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == '' && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == "" && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchSpaceTypeListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeRef == "" && GAAProjectSpaceTypeList.length > 0) {
      handleSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
    }
  }, [GAAProjectSpaceTypeList]);

  useEffect(() => {
    fetchAreaBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeAreaRef == "" && GAASpaceTypeAreaList.length > 0) {
      handleSpaceTypeAreaRef(GAASpaceTypeAreaList[0].Ref);
    }
  }, [GAASpaceTypeAreaList]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListByArea();
  }, [GAAProjectSpaceTypeAreaRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypePlannedDeviceRef == "" && ProjectSpaceTypePlannedDeviceList.length > 0) {
      handlePlannedDeviceRef(ProjectSpaceTypePlannedDeviceList[0].Ref);
    }
  }, [ProjectSpaceTypePlannedDeviceList]);

  useEffect(() => {
    fetchDeviceConnectionByPlannedDevice();
  }, [GAAProjectSpaceTypePlannedDeviceRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Connections"}
          button={
            <LinkButton
              to={
                "/gaa-project-space-type-planned-device-Connections/add-gaa-project-space-type-planned-device-Connections"
              }
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={{
                GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef,
                GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef,
                GAAProjectSpaceTypePlannedDeviceRef:
                  GAAProjectSpaceTypePlannedDeviceRef,
              }}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Connection</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-4">
            <div>
              <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
              <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e) => handleCustomerRef(e.target.value)} >
                {
                  customerList && customerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={" Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {GAAProjectList &&
                  GAAProjectList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeRef"}
                labelText={" Space Type"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypeRef"}
                name={"GAAProjectSpaceTypeRef"}
                value={GAAProjectSpaceTypeRef}
                onChange={(e) => handleSpaceTypeRef(e.target.value)}
                required={true}
              >
                {GAAProjectSpaceTypeList &&
                  GAAProjectSpaceTypeList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                labelText={" Space Type Area"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypeAreaRef"}
                name={"GAAProjectSpaceTypeAreaRef"}
                value={GAAProjectSpaceTypeAreaRef}
                onChange={(e) => handleSpaceTypeAreaRef(e.target.value)}
                required={true}
              >
                {GAASpaceTypeAreaList &&
                  GAASpaceTypeAreaList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypePlannedDeviceRef"}
                labelText={"Planned Device"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypePlannedDeviceRef"}
                name={"GAAProjectSpaceTypePlannedDeviceRef"}
                value={GAAProjectSpaceTypePlannedDeviceRef}
                onChange={(e) =>
                  handlePlannedDeviceRef(e.target.value)
                }
                required={true}
              >
                {ProjectSpaceTypePlannedDeviceList &&
                  ProjectSpaceTypePlannedDeviceList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
          </div>
          {DeviceConnectionList && DeviceConnectionList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {DeviceConnectionList &&
                  DeviceConnectionList.slice(
                    (PlannedDeviceConnectionCP - 1) * itemsPerPage,
                    PlannedDeviceConnectionCP * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    const serialNumber = (PlannedDeviceConnectionCP - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.Label}</td>
                          <td className="px-4 py-3">{tableData.Wattage}</td>
                          <td className="px-4 py-3">
                            {tableData.GAAProductName}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.BindFromGAAProductInputDriverChannelName}
                          </td>
                          <td className="px-4 py-3">
                            {
                              tableData.BindFromGAAProductOutputDriverChannelName
                            }
                          </td>
                          <td className="px-4 py-3">
                            {tableData.BindToGAAProductInputDriverChannelName}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.BindToGAAProductOutputDriverChannelName}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                              state={{
                                GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef,
                                GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef,
                                GAAProjectSpaceTypePlannedDeviceRef:
                                  GAAProjectSpaceTypePlannedDeviceRef,
                              }}
                                to={`/gaa-project-space-type-planned-device-Connections/edit-gaa-project-space-type-planned-device-Connections/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton
                                  onClick={() =>
                                    deleteDeviceConnection(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Connections Data Not Found"} />
          )}
          {DeviceConnectionList && DeviceConnectionList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={DeviceConnectionList.length}
                currentPage={PlannedDeviceConnectionCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypePlannedDeviceConnections;
