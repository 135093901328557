import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProjectSpaceInstalledTelevisionSetMaster";
const FetchRequestType = "GAAProjectSpaceInstalledTelevisionSetFetchRequest";

// Save All Data To Database //
export const SaveHotelTelevision = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};
// Get All Data From Database //
export const GetHotelTelevisionList = async () => {
  let payload = { Refs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

// Get Single Data From Database //
export const GetHotelTelevision = async (Ref) => {
  let payload = { GAAProjectSpaceInstalledTelevisionSetRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteHotelTelevision = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

// Get Television Data by Project Space //
export const GetHotelTelevisionListByProjectSpace = async (SpaceRef) => {
  let payload = { GAAProjectSpaceRefs: [SpaceRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  return resultList;
};
