import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import flasher from '@flasher/flasher'
import { ClearInfo, TelevisionOperatingSystem } from '../../../Encapsulation/Utils'
import { GetHotelTelevision, SaveHotelTelevision } from '../../../Encapsulation/HotelDomainEntities/HotelTelevision'
import { GetGAAProjectSpace } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace'
import CustomSelect from '../../helpers/CustomSelect'
import Option from '../../helpers/Option'

function TelevisionMasterAddEdit({Rights}) {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Gladiance - Edit Television";
      formMode = "Edit";
    } else {
      document.title = "Gladiance - Add Television";
    }
  }

  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const Navigation = useNavigate()
  const location = useLocation()
  const [formInfo, setFormInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('formInfo :', formInfo);
    let result = await SaveHotelTelevision(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/television-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
      }
      console.log("result :", result);
    }
  }

  // fetch single Data //
  const fetchProjectSpace = async () => {
    let space = await GetGAAProjectSpace(location.state);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectSpaceRef: space.Ref,
      GAAProjectSpaceName: space.Name
    }))
  };

  const fetchHotelTelevision = async () => {
    let tev = await GetHotelTelevision(id);
    console.log('tev :', tev);
    setFormInfo(tev)
  };

  useEffect(() => {
    if (location.state) {
      fetchProjectSpace()
    }
    if (id) {
      fetchHotelTelevision();
    }
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? "Edit Television" : "Add Television"} button={
          <LinkButton to={'/television-master'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'GAAProjectSpaceName'} labelText={'Space'} />
                    <Input id={'GAAProjectSpaceName'} type={'text'} name={'GAAProjectSpaceName'} value={formInfo.GAAProjectSpaceName} required={true} readOnly={true} />
                    <Validation error={errors.GAAProjectSpaceName} />
                  </div>
                  <div>
                    <Label htmlFor={'Name'} labelText={'Name'} />
                    <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} required={true} onChange={handleChange} inputFocus={true} readOnly={!Rights.Edit}
 />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={'IPAddress'} labelText={'IP Address'} />
                    <Input id={'IPAddress'} type={'text'} name={'IPAddress'} value={formInfo.IPAddress} required={true} onChange={handleChange} readOnly={!Rights.Edit}/>
                    <Validation error={errors.IPAddress} />
                  </div>
                  <div>
                    <Label htmlFor={'TelevisionOperatingSystem'} labelText={'Operating System'} />
                    <CustomSelect id={'TelevisionOperatingSystem'} name={'TelevisionOperatingSystem'} value={formInfo.TelevisionOperatingSystem} onChange={handleChange} required={true}>
                      <Option value={''}>--- Select Room Type ---</Option>
                      {
                        TelevisionOperatingSystem.map((data, id) => {
                          return (
                            <Option key={id} value={data.Value}>{data.Name}</Option>
                          )
                        })
                      }

                    </CustomSelect>
                    <Validation error={errors.TelevisionOperatingSystem} />
                  </div>
                </div>

                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/television-master'} />
                  {
                    Rights.Edit && <SaveButton text="Save" />
                  }                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}




export default TelevisionMasterAddEdit
