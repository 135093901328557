import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo, GladianceUserRoleList } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import Option from '../../helpers/Option'
import { AllocateSingleId } from '../../../Encapsulation/AllocateId'
import axiosClient from '../../../Encapsulation/Axios'
import CheckBox from '../../helpers/CheckBox'

function UserMasterAddEdit() {
    let formMode = 'New';
    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit User';
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add User';
        }
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const loginToken = '36983F8347364554B0E3132DD99267C4';
    const loginDeviceId = 'c03ea267-031f-4eb8-a441-e2150129d5f2';
    const [formInfo, setFormInfo] = useState({
        Enabled: true
    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        formInfo["Ref"] = await AllocateSingleId(formInfo["Ref"]);

        axiosClient.post(`usermanagement/savegladianceuser/${loginToken}/${loginDeviceId}`, formInfo)
            .then(({ data }) => {
                setLoader(false)
                if (data.Successful) {
                    setFormInfo(ClearInfo(formInfo))
                    document.getElementById('Enabled').checked = true;
                    flasher.success('Data Added Successfully')
                } else {
                    flasher.warning(data.Message)
                }
                if (formMode == 'Edit') {
                    Navigation('/gladiance-user-master');
                }
            }).catch((error) => {
                flasher.warning(error)
            })
    }

    const FetchUser = async () => {
        axiosClient.get(`usermanagement/gladianceuser/${id}/${loginToken}/${loginDeviceId}`)
            .then(({ data }) => {
                setFormInfo(data.ObjectTag)
                if (data.ObjectTag.Enabled == false) {
                    document.getElementById('Enabled').checked = false;
                }
                setLoader(false)
                if (!data.Successful) {
                    flasher.warning(data.Message)
                }
            }).catch((error) => {
                flasher.warning(error)
            })
    };

    useEffect(() => {
        if (id) {
            FetchUser();
        }
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit User' : 'Add User'} button={
                    <LinkButton to={'/gladiance-user-master'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'EMailId'} labelText={'Email Id'} />
                                        <Input id={'EMailId'} type={'email'} name={'EMailId'} value={formInfo.EMailId} onChange={handleChange} required={true} />
                                        <Validation error={errors.EMailId} />
                                    </div>
                                    {
                                        formMode == 'New' && (
                                            <div>
                                                <Label htmlFor={'Password'} labelText={'Password'} />
                                                <Input id={'Password'} type={'Password'} name={'Password'} value={formInfo.Password} onChange={handleChange} required={true} />
                                                <Validation error={errors.Password} />
                                            </div>
                                        )
                                    }


                                    <div>
                                        <Label htmlFor={'PhoneNos'} labelText={'Phone Number'} />
                                        <Input id={'PhoneNos'} type={'text'} name={'PhoneNos'} value={formInfo.PhoneNos} onChange={handleChange}
                                            onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} minLength={10} required={true} />
                                        <Validation error={errors.PhoneNos} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'FirstName'} labelText={'First Name'} />
                                        <Input id={'FirstName'} type={'text'} name={'FirstName'} value={formInfo.FirstName} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.FirstName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'MiddleName'} labelText={'Middle Name'} />
                                        <Input id={'MiddleName'} type={'text'} name={'MiddleName'} value={formInfo.MiddleName} onChange={handleChange} required={true} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.MiddleName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'LastName'} labelText={'Last Name'} />
                                        <Input id={'LastName'} type={'text'} name={'LastName'} value={formInfo.LastName} onChange={handleChange} required={true} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />

                                        <Validation error={errors.LastName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Role'} labelText={'User Role'} />
                                        <div className="flex">
                                            <CustomSelect id={'Role'} name={'Role'} value={formInfo.Role} onChange={handleChange} required={true}>
                                                <Option value={''}>--- Select User Role ---</Option>
                                                {GladianceUserRoleList.map((data, id) => {
                                                    return (
                                                        <Option key={id} value={data.Value}>{data.Name}</Option>
                                                    );
                                                })}
                                            </CustomSelect>
                                        </div>
                                        <Validation error={errors.Role} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Enabled'} labelText={'Enabled'} />
                                        <div className={' p-2 pl-6'}>
                                            <CheckBox id={'Enabled'} name={'Enabled'} type={'checkbox'} onClick={(e) => setFormInfo({ ...formInfo, Enabled: e.target.checked })} checked={formInfo.Enabled} />
                                        </div>
                                        <Validation error={errors.Enabled} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/gladiance-user-master'} />
                                    <SaveButton text='Save' />
                                </div>

                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default UserMasterAddEdit
