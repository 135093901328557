import React, { Fragment, useEffect, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteGAAProductOutputFunctionType, GetGAAProductOutputFunctionTypeList } from '../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionType'
import Pagination from '../../helpers/Pagination'
import { useStateContext } from '../../../context/ContextProvider'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function GAAProductOutputFunctionType() {
    document.title = 'Gladiance - Product Output Function Type'
    const tableHeading = ['Sr.No', 'Name', 'value', 'Action']
    const [loader, setLoader] = useState(false)
    const itemsPerPage = 7;
    const [GAAProductOutputFunctionTypeList, setGAAProductOutputFunctionTypeList] = useState([])

    const { ProuctOutputFunctionTypesCP, setProuctOutputFunctionTypesCP } = useStateContext();

    const fetchGAAProductOutputFunctionTypeList = async () => {
        setGAAProductOutputFunctionTypeList(await GetGAAProductOutputFunctionTypeList());
    }

    const handlePageChange = (pageNumber) => {
        setProuctOutputFunctionTypesCP(pageNumber);
    };

    const deleteProductOutputFunctionType = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteGAAProductOutputFunctionType(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchGAAProductOutputFunctionTypeList();
                }
            }
        })
    }

    useEffect(() => {
        fetchGAAProductOutputFunctionTypeList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Product Output Function Types'} button={
                    <LinkButton to={'/product-output-function-type-master/add-product-output-function-type-master'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Product Output Function Type</span>
                    </LinkButton>
                }>
                    {GAAProductOutputFunctionTypeList && GAAProductOutputFunctionTypeList.length > 0 ? (
                        <div className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {GAAProductOutputFunctionTypeList.slice((ProuctOutputFunctionTypesCP - 1) * itemsPerPage, ProuctOutputFunctionTypesCP * itemsPerPage).map((tableData, tableDataID) => {
                                    const serialNumber = (ProuctOutputFunctionTypesCP - 1) * itemsPerPage + tableDataID + 1;
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{serialNumber}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">{tableData.Value}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link to={`/product-output-function-type-master/edit-product-output-function-type-master/${tableData.Ref}`} title='Edit' >
                                                            <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                                <PencilSquareIcon className='w-4 h-4' />
                                                            </ActionButton>
                                                        </Link>
                                                        <ActionButton onClick={() => deleteProductOutputFunctionType(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <TrashIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>
                    ) : (
                        <DataNotFound labelText={'Sorry! Product Output Function Type Data Not Found'} />
                    )}
                    {GAAProductOutputFunctionTypeList && GAAProductOutputFunctionTypeList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={GAAProductOutputFunctionTypeList.length}
                            currentPage={ProuctOutputFunctionTypesCP}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}
export default GAAProductOutputFunctionType