import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import ActionButton from "../../../helpers/ActionButton";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import { useStateContext } from "../../../../context/ContextProvider";
import {GetGAAProjectListByCustomer,} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import CustomSelect from "../../../helpers/CustomSelect";
import {DeleteGAAProjectDepartment,GetGAAProjectDepartmentListByProject,} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectDepartment";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function GAAProjectDepartment({ Rights }) {
  console.log("Rights :", Rights);
  document.title = "Hotel - Departments";
  let tableHeading = ["Sr.No", "Department Name", "Action"];
  // tableHeading = FilterAction(tableHeading, Rights);
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 7;
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectDepartmentMasterList, setProjectDepartmentMasterList] = useState([]);
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const { CustomerRef,  GAAProjectRef, handleCustomerRef,handleProjectRef,ProjectDepartmentCP, setProjectDepartmentCP } = useStateContext();
  
  const handlePageChange = (pageNumber) => {
    setProjectDepartmentCP(pageNumber);
  };

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  //Fetch Project List for Dropdowm By Customer//
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef &&
      setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  const fetchDepartmentList = async () => {
    GAAProjectRef &&
      setProjectDepartmentMasterList(
        await GetGAAProjectDepartmentListByProject(GAAProjectRef)
      );
  };

  const deleteProjectDepartment = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectDepartment(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchDepartmentList();
          (ProjectDepartmentMasterList.length - 1) % itemsPerPage == 0 && setProjectDepartmentCP(ProjectDepartmentCP - 1);

        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == '' && CustomerList.length > 0) {
      handleCustomerRef(CustomerList[0].Ref);
    }
  }, [CustomerList]);
  
  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  useEffect(() => {
    if (GAAProjectRef == '' && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchDepartmentList();
  }, [GAAProjectRef]);


  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Departments"}
          button={
            <LinkButton
              to={"/project-department-master/add-project-department-master"}
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={GAAProjectRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Department</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e)=> handleCustomerRef(e.target.value)}
              >
                {CustomerList &&
                  CustomerList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={"Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e)=> handleProjectRef(e.target.value) }
              >
                {GAAProjectList &&
                  GAAProjectList.map((data, id) => {
                    return (
                                         <Option key={id} value={data.Ref}>{data.Name}</Option>
                    );
                  })}
              </CustomSelect>
            </div>
          </div>
          {ProjectDepartmentMasterList &&
            ProjectDepartmentMasterList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {ProjectDepartmentMasterList.slice(
                  (ProjectDepartmentCP - 1) * itemsPerPage,
                  ProjectDepartmentCP * itemsPerPage
                ).map((tableData, tableDataID) => {
                  const serialNumber = (ProjectDepartmentCP - 1) * itemsPerPage + tableDataID + 1;
                  return (
                    <Fragment key={tableDataID}>
                      <tr className="border-b border-opacity-30 border-white">
                        <td className="px-4 py-3">{serialNumber}</td>
                        <td className="px-4 py-3">{tableData.Name}</td>
                        <td className="px-4 py-3">
                          <div className="flex justify-center gap-3">
                            <Link
                              to={`/project-department-master/edit-project-department-master/${tableData.Ref}`}
                              title="Edit"
                            >
                              <ActionButton
                                className={"p-1.5 text-white bg-yellow-400 "}
                              >
                                <PencilSquareIcon className="w-4 h-4" />
                              </ActionButton>
                            </Link>
                            {Rights.Delete && (
                              <ActionButton
                                onClick={() =>
                                  deleteProjectDepartment(tableData)
                                }
                                title={"Delete"}
                                className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </ActionButton>
                            )}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Departments Data Not Found"} />
          )}
          {ProjectDepartmentMasterList &&
            ProjectDepartmentMasterList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={ProjectDepartmentMasterList.length}
                currentPage={ProjectDepartmentCP}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}
export default GAAProjectDepartment;
