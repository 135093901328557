import React, { Fragment, useState } from 'react'
import axiosClient from '../../Encapsulation/Axios'
import { useStateContext } from '../../context/ContextProvider';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import Button from '../helpers/Button';
import Input from '../helpers/Input';
import Label from '../helpers/Label';
import Validation from '../helpers/Validation';
import Loader from '../Common/Loader';
import { Eye, EyeSlash, ShieldTick } from 'iconsax-react';

function Register() {
    document.title = 'Gladiance - Register'
    const [formInfo, setFormInfo] = useState({
        name : '',
        email : '',
        password : ''
    });

    const [errors, setErrors] = useState([]);
    const { setCurrentUser, setUserToken, FRONTEND_URL } = useStateContext()
    const [loader, setLoader] = useState(false)
    const handleChange = (e) =>{
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]:e.target.value
        }))
    }
    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoader(true)
        const formData = new FormData();
        formData.append('name', formInfo.name);
        formData.append('email', formInfo.email);
        formData.append('password', formInfo.password);
        axiosClient.post('/register', formData)
        .then(({data})=>{
            setLoader(false)
            setCurrentUser(data.user)
            setUserToken(data.token)
            Swal.fire({
                icon: 'success',
                title: data.msg,
                showConfirmButton: false,
                timer: 1500
            })
        })
        .catch(({response})=>{
            setLoader(false)
            setErrors(response.data.errors)
        })
    }
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () =>{
        if(passwordType==="password"){
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <Fragment>
            {loader ? (<Loader/>) : (
                <div className="flex w-full md:w-[35%] items-center px-4 py-12 sm:px-6 lg:px-8 absolute">
                    <div className="w-full space-y-2 pt-4 pb-6 px-6 rounded-lg">
                        <div>
                            <img className="mx-auto w-auto" src={FRONTEND_URL+"/assets/logos/logo1.png"} alt="Your Company"/>
                            <h2 className="text-center text-4xl text-white uppercase font-extrabold tracking-widest mt-4">Register</h2>
                            <p className="mt-2 text-center text-lg text-white">
                                Or{' '}
                                <Link to="/" className="font-medium text-white hover:text-indigo-500">
                                    Go To Login
                                </Link>
                            </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="rounded-md shadow-sm">
                                <div className='mb-3'>
                                    <Label labelText={'Name'} htmlFor='name' className={'text-white font-medium'}/>
                                    <Input type={'text'} value={formInfo.name} name={'name'} id={'name'} onChange={handleChange}/>
                                    <Validation error={errors.name}/>
                                </div>
                                <div className='mb-3'>
                                    <Label labelText={'Email Addess'} htmlFor='email' className={'text-white font-medium'}/>
                                    <Input type={'email'} value={formInfo.email} name={'email'} id={'email'} onChange={handleChange}/>
                                    <Validation error={errors.email}/>
                                </div>
                                <div className='mb-6'>
                                    <Label labelText={'Password'} htmlFor='password' className={'text-white font-medium'}/>
                                    <div className='relative'>
                                        <Input type={passwordType} value={formInfo.password} name={'password'} id={'password'} onChange={handleChange}/>
                                        {passwordType === 'password' ? (
                                            <Eye className='w-6 h-6 absolute top-2 right-3 cursor-pointer' onClick={togglePassword}/>
                                        ) : (
                                            <EyeSlash className='w-6 h-6 absolute top-2 right-3 cursor-pointer' onClick={togglePassword}/>
                                        )}
                                    </div>
                                    <Validation error={errors.password}/>
                                </div>
                            </div>
                            <Button className={'w-full'}>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <ShieldTick variant="Bold" className="h-5 w-5"  aria-hidden="true" />
                                </span>
                                Register Now
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default Register