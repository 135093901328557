import React, { Fragment, useEffect, useState } from 'react'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import CheckBox from '../../helpers/CheckBox'
import Table from '../../helpers/Table'
import Option from '../../helpers/Option'
import SaveButton from '../../helpers/SaveButton'
import flasher from '@flasher/flasher'
import { GetGladianceUserRightsList, SaveGladianceUserRights } from '../../../Encapsulation/GladianceDomainEntities/GladianceUserRights'
import { useStateContext } from '../../../context/ContextProvider'
import Pagination from '../../helpers/Pagination'

function GladianceUserRightsAddEdit() {

    document.title = 'Gladiance - User Rights'

    const tableHeading = ['Sr.No', 'Activity', 'Add', 'Edit', 'Delete', 'View']
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [formInfo, setFormInfo] = useState({
        GladianceUserRole: 'Gladiance Super Admin',
    })
    const itemsPerPage = 7;
    const { UserRightCP, setUserRightCP } = useStateContext();
    const [UserRights, setUserRights] = useState([])

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handlePageChange = (pageNumber) => {
        setUserRightCP(pageNumber);
    };


    const handleCheck = (UserRoleRef, ActivityRef, value, e) => {
        UserRights.map((data) => {
            if (data.UserRoleRef == UserRoleRef) {
                if (data.ActivityRef == ActivityRef) {
                    data[e.target.name] = !value
                }
            }
        })
    }

    const handleSubmit = async () => {
        console.log('HandleSubmit :', UserRights);
        let result = await SaveGladianceUserRights(UserRights);
        console.log('result :', result);

        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
        }
    }

    const fetchUserRights = async () => {
        setUserRights(await GetGladianceUserRightsList())
    }

    useEffect(() => {
        fetchUserRights();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title='User Rights'>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6  '}>
                            <div className='flex items-center mb-3'>
                                <div className="w-36"> <Label htmlFor={'GladianceUserRole'} labelText={'Gladiance User Role'} /></div>
                                <div className="flex">
                                    <CustomSelect id={'GladianceUserRole'} name={'GladianceUserRole'} value={formInfo.GladianceUserRole} onChange={handleChange}>
                                        <Option value={''}>--- Select User Role ---</Option>
                                        <Option value={'Gladiance Super Admin'}>Gladiance Super Admin (GSA)</Option>
                                        <Option value={'Gladiance Admin'}>Gladiance Admin (GAD)</Option>
                                        <Option value={'Gladiance Staff'}>Gladiance Staff (GST)</Option>
                                    </CustomSelect>
                                    <Validation error={errors.GladianceUserRole} />
                                </div>
                            </div>
                            <div className="h-96">
                                <Table tableHeading={tableHeading}>
                                    {
                                        UserRights && UserRights.slice((UserRightCP - 1) * itemsPerPage, UserRightCP * itemsPerPage).map((data, id) => {
                                            return (
                                                <Fragment key={id}>
                                                    <tr className="border-b border-opacity-20 border-white">
                                                        <td className="px-6 py-3"> {id + 1} </td>
                                                        <td className="px-6 py-3"> {data.Activity} </td>
                                                        <td className="px-6 py-3"><CheckBox id={'CanAdd'} name={'CanAdd'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.ActivityRef, data.CanAdd, e)} checked={data.CanAdd} /></td>
                                                        <td className="px-6 py-3"><CheckBox id={'CanEdit'} name={'CanEdit'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.ActivityRef, data.CanEdit, e)} checked={data.CanEdit} /></td>
                                                        <td className="px-6 py-3"><CheckBox id={'CanDelete'} name={'CanDelete'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.ActivityRef, data.CanDelete, e)} checked={data.CanDelete} /></td>
                                                        <td className="px-6 py-3"><CheckBox id={'CanView'} name={'CanView'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.ActivityRef, data.CanView, e)} checked={data.CanView} /></td>
                                                    </tr>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Table>
                                {UserRights && UserRights.length > 7 ? (<div className="flex justify-end">
                                    <Pagination
                                        itemsPerPage={itemsPerPage}
                                        totalItems={UserRights.length}
                                        currentPage={UserRightCP}
                                        onPageChange={handlePageChange}
                                    />
                                </div>) : (<div></div>)
                                }
                                <div className=" z-auto absolute w-full bottom-1 right-7 rounded-md mb-1 opacity-90 ">
                                    <div className="flex gap-8 justify-center mt-6 ">
                                        <SaveButton text="Save" onClick={() => handleSubmit()} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default GladianceUserRightsAddEdit

