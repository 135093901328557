import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetProgrammingRequisition, SaveProgrammingRequisition } from '../../../Encapsulation/GladianceDomainEntities/ProgrammingRequisition'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import Option from '../../helpers/Option'
import Swal from 'sweetalert2'
import { GetGAAProductModesMaster, GetGAAProductModesMasterList } from '../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster'
import { GAAProductVersionByProduct, GetGAAProductVersions } from '../../../Encapsulation/GladianceDomainEntities/GAAProductVersion'

function ProgrammingRequisitionAddEdit() {
    let formMode = 'New';
    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit Programming Requisition '
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add Programming Requisition '
        }
    }
    const Location = useLocation();
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const [productList, setProductList] = useState([]);
    const Navigation = useNavigate()
    const [formInfo, setFormInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveProgrammingRequisition(id, formInfo);
        console.log('result :', result);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/programming-requisition');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
            }
        }

    };

    const fetchdesignidfirmwareid = async () => {
        let Product = await GAAProductVersionByProduct(formInfo.GAAProductRef);
        console.log('Product :', Product);
        if(Product.length === 0) {
            setFormInfo((formInfo) => ({
                        ...formInfo,
                        DesignId: "-",
                        HardwareTypeName:"-",
                        HardwareType: "-",
                        FirmwareId: "-",
                        FirmwareStatusName: "-",
                    }))}
        else{
            setFormInfo((formInfo) => ({
                ...formInfo,
                DesignId: Product[0].DesignId,
                HardwareTypeName: Product[0].HardwareTypeName,
                HardwareType: Product[0].HardwareType,
                FirmwareId: Product[0].FirmwareId,
                FirmwareStatusName: Product[0].FirmwareStatusName,
            }))}
        }
 

    const FetchProgrammingRequisition = async () => {
        let ProgrammingRequisition = await GetProgrammingRequisition(id);
        setFormInfo(ProgrammingRequisition);
    };

      // Fetch Product List For Dropdown //
  const fetchProductList = async () => {
    setProductList(await GetGAAProductModesMasterList());
  };

    useEffect(() => {
        if (id) {
            FetchProgrammingRequisition();
        }
        fetchProductList()
    }, []);

    useEffect(() => {
        if (!id && productList.length > 0) {
          setFormInfo((formInfo) => ({
            ...formInfo,
            GAAProductRef:productList[0].Ref
        }));
        }
      }, [productList]);

    useEffect(() => {
        fetchdesignidfirmwareid();
    }, [formInfo.GAAProductRef]);

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Programming Requisition' : 'Add Programming Requisition'} button={
                    <LinkButton to={'/programming-requisition'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label
                                            htmlFor={"GAAProductRef"}
                                            labelText={" Product Name"}
                                        />
                                        <CustomSelect
                                            id={"GAAProductRef"}
                                            name={"GAAProductRef"}
                                            value={formInfo.GAAProductRef}
                                            onChange={handleChange}
                                            required={true}
                                        >
                                            {productList
                                                .map((data, id) => {
                                                    return (
                                                        <Option key={id} value={data.Ref}>{data.Name}</Option>
                                                    );
                                                })}
                                        </CustomSelect>
                                    </div>
                                    <div>
                                        <Label htmlFor={'DesignId'} labelText={'Design Id'} />
                                        <Input id={'DesignId'} type={'text'} name={'DesignId'} value={formInfo.DesignId} onChange={handleChange} />
                                        <Validation error={errors.DesignId} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'HardwareTypeName'} labelText={'Hardware Type'} />
                                        <Input id={'HardwareTypeName'} type={'text'} name={'HardwareTypeName'} value={formInfo.HardwareTypeName} onChange={handleChange} />
                                        <Validation error={errors.HardwareTypeName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'FirmwareId'} labelText={'Firmware ID'} />
                                        <Input id={'FirmwareId'} type={'text'} name={'FirmwareId'} value={formInfo.FirmwareId} onChange={handleChange} />
                                        <Validation error={errors.FirmwareId} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'FirmwareStatusName'} labelText={'Firmware Status'} />
                                        <Input id={'FirmwareStatusName'} type={'text'} name={'FirmwareStatusName'} value={formInfo.FirmwareStatusName} onChange={handleChange} />
                                        <Validation error={errors.FirmwareStatusName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Quantity'} labelText={'Quantity'} />
                                        <Input id={'Quantity'} type={'text'} name={'Quantity'} value={formInfo.Quantity} onChange={handleChange} />
                                        <Validation error={errors.Quantity} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/programming-requisition'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default ProgrammingRequisitionAddEdit
