import React, { Fragment } from 'react'

function ActionButton({children,className,title,onClick}) {
    return (
        <Fragment>
            <button type="button" className={" transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-semibold text-default-color  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color "+(className)} title={title} onClick={onClick}>
                {children}
            </button>
        </Fragment>
    )
}

export default ActionButton