import React, { Fragment, useEffect, useState } from "react";
import { PencilSquareIcon, TrashIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { AddCircle } from "iconsax-react";
import Loader from "../../Common/Loader";
import AddComponent from "../../../components/AddComponent";
import Table from "../../helpers/Table";
import LinkButton from "../../helpers/LinkButton";
import ActionButton from "../../helpers/ActionButton";
import Pagination from "../../helpers/Pagination";
import DataNotFound from "../../helpers/DataNotFound";
import { GetGAAProjectRoomSceneListBySpaceType } from "../../../Encapsulation/HotelDomainEntities/HotelRoomScene";
import Label from "../../helpers/Label";
import CustomSelect from "../../helpers/CustomSelect";
import { GetGAAProjectList, GetGAAProjectListByCustomer } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Option from "../../helpers/Option";
import Input from "../../helpers/Input";
import { GetGAAProjectSpaceTypeListByProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { DeleteHotelRoomSceneConfiguration, GetProjectRoomSceneListByProjectScene } from "../../../Encapsulation/HotelDomainEntities/HotelRoomSceneConfiguration";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../Encapsulation/GladianceDomainEntities/Customer";

function Schedule({ Rights }) {
    document.title = "Hotel - Schedule";
    const tableHeading = ["Sr.No", "Scene Code", "Space Type Planned Device Name", "Label", "Output Driver Channel Name", "Value", "Action",];
    const [loader, setLoader] = useState(false);
    const itemsPerPage = 7;
   const [customerList, setCustomerList] = useState([])
   const [GAAProjectList, setGAAProjectList] = useState([]);
   const [RoomSceneList, setRoomSceneList] = useState([]);
   const [RoomSceneConfigurationList, setRoomSceneConfigurationList] = useState([]);
   const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
   const [GAAProjectSpaceTypeAreaList, setGAAProjectSpaceTypeAreaList] = useState([]);
   const { CustomerRef, GAAProjectRef, GAAProjectSpaceTypeRef, GAAProjectSpaceTypeAreaRef, GAAProjectSceneRef, handleCustomerRef, handleProjectRef, handleSpaceTypeRef, handleSpaceTypeAreaRef, handleRoomSceneRef, RoomSceneConfigCP, setRoomSceneConfigCP } = useStateContext();


    //Fetch Customer List For Dropdown//
    const fetchCustomerList = async () => {
        setCustomerList(await GetGladianceCustomerList());
    }

    //Fetch Project List By Customer for Dropdowm //
    const fetchGAAProjectListByCustomer = async () => {
        CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
    };

    // Fetch Project Space Type List For Dropdown By Project //
    const fetchSpaceTypeListByProject = async () => {
        GAAProjectRef &&
            setGAAProjectSpaceTypeList(
                await GetGAAProjectSpaceTypeListByProject(GAAProjectRef)
            );
    };

    // Fetch Area List For Dropdown By Space Type//
    const fetchAreaBySpaceType = async () => {
        GAAProjectSpaceTypeRef &&
            setGAAProjectSpaceTypeAreaList(
                await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef)
            );
    };

    // fetch Room Scene List According to Project Ref //
    const fetchProjectRoomSceneListBySpaceType = async () => {
        GAAProjectSpaceTypeRef &&
            setRoomSceneList(
                await GetGAAProjectRoomSceneListBySpaceType(GAAProjectSpaceTypeRef)
            );
    };

    // fetch Room Scene Configuration List According to Scene Configuration Ref //
    const fetchProjectRoomSceneListByRoomSceneConfiguration = async () => {
        GAAProjectSceneRef &&
            setRoomSceneConfigurationList(
                await GetProjectRoomSceneListByProjectScene(GAAProjectSceneRef)
            );
    };

    const deleteSceneConfiguration = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteHotelRoomSceneConfiguration(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchProjectRoomSceneListByRoomSceneConfiguration();
                    (RoomSceneConfigurationList.length - 1) % itemsPerPage == 0 && setRoomSceneConfigCP(RoomSceneConfigCP - 1);

                }
            }
        })
    }

    const handlePageChange = (pageNumber) => {
        setRoomSceneConfigCP(pageNumber);
    };

    useEffect(() => {
        fetchCustomerList();
    }, []);

    useEffect(() => {
        if (CustomerRef == '' && customerList.length > 0) {
            handleCustomerRef(customerList[0].Ref);
        }
    }, [customerList]);

    useEffect(() => {
        fetchGAAProjectListByCustomer();
    }, [CustomerRef]);

    useEffect(() => {
        if (GAAProjectRef == "" && GAAProjectList.length > 0) {
            handleProjectRef(GAAProjectList[0].Ref);
        }
    }, [GAAProjectList]);
    // =====================================================
    useEffect(() => {
        fetchSpaceTypeListByProject();
    }, [GAAProjectRef]);

    useEffect(() => {
        if (GAAProjectSpaceTypeRef == "" && GAAProjectSpaceTypeList.length > 0) {
            handleSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
        }
    }, [GAAProjectSpaceTypeList]);

    // =====================================================
    useEffect(() => {
        fetchAreaBySpaceType();
    }, [GAAProjectSpaceTypeRef]);

    useEffect(() => {
        if (GAAProjectSpaceTypeAreaRef == "" && GAAProjectSpaceTypeAreaList.length > 0) {
            handleSpaceTypeAreaRef(GAAProjectSpaceTypeAreaList[0].Ref);
        }
    }, [GAAProjectSpaceTypeAreaList]);

    // =====================================================
    useEffect(() => {
        fetchProjectRoomSceneListBySpaceType();
    }, [GAAProjectSpaceTypeRef]);

    useEffect(() => {
        if (GAAProjectSceneRef == "" && RoomSceneList.length > 0) {
            handleRoomSceneRef(RoomSceneList[0].Ref);
        }
    }, [RoomSceneList]);

    useEffect(() => {
        fetchProjectRoomSceneListByRoomSceneConfiguration();
    }, [GAAProjectSceneRef]);

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <AddComponent
                    title={"Schedule"}
                    button={
                        <LinkButton
                            to={"/schedule/add-schedule"}
                            className={Rights.Add ? "opacity-100" : "hidden"}
                            state={{
                                GAAProjectRef: GAAProjectRef,
                                GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef,
                                GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef,
                            }}
                        >
                            <AddCircle variant="Bold" className="w-5 h-5" />
                            <span>Add Schedule</span>
                        </LinkButton>
                    }
                >
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                        <div>
                            <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
                            <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e) => handleCustomerRef(e.target.value)} >
                                <Option value={''}>-- Select --</Option>
                                {
                                    customerList && customerList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </div>
                        <div>
                            <Label htmlFor={"GAAProjectRef"} labelText={" Project"} />
                            <CustomSelect
                                id={"GAAProjectRef"}
                                name={"GAAProjectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => handleProjectRef(e.target.value)}
                                required={true}
                            >
                                {GAAProjectList &&
                                    GAAProjectList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option key={id} value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeRef"}
                                labelText={" Space Type"}
                            />
                            {GAAProjectSpaceTypeList.length > 0 ? (
                                <div>
                                    <CustomSelect
                                        id={"GAAProjectSpaceTypeRef"}
                                        name={"GAAProjectSpaceTypeRef"}
                                        value={GAAProjectSpaceTypeRef}
                                        onChange={(e) => handleSpaceTypeRef(e.target.value)}
                                        required={true}
                                    >
                                        {GAAProjectSpaceTypeList &&
                                            GAAProjectSpaceTypeList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option key={id} value={data.Ref}>{data.Name}</Option>
                                                    </Fragment>
                                                );
                                            })}
                                    </CustomSelect>
                                </div>
                            ) : (
                                <div>
                                    <Input
                                        placeHolder={"  No Space Types"}
                                        disabled={true}
                                    ></Input>
                                </div>
                            )}
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                                labelText={" Space Type Area"}
                            />
                            {GAAProjectSpaceTypeAreaList.length > 0 ? (
                                <div>
                                    <CustomSelect
                                        id={"GAAProjectSpaceTypeAreaRef"}
                                        name={"GAAProjectSpaceTypeAreaRef"}
                                        value={GAAProjectSpaceTypeAreaRef}
                                        onChange={(e) =>
                                            handleSpaceTypeAreaRef(e.target.value)
                                        }
                                        required={true}
                                    >
                                        {GAAProjectSpaceTypeAreaList &&
                                            GAAProjectSpaceTypeAreaList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option key={id} value={data.Ref}>{data.Name}</Option>
                                                    </Fragment>
                                                );
                                            })}
                                    </CustomSelect>
                                </div>
                            ) : (
                                <div>
                                    <Input placeHolder={"  No Areas"} disabled={true}></Input>
                                </div>
                            )}
                        </div>
                    </div>
                    {RoomSceneConfigurationList &&
                        RoomSceneConfigurationList.length > 0 ? (
                        <div className="m-2">
                            <Table tableHeading={tableHeading}>
                                {RoomSceneConfigurationList.slice(
                                    (RoomSceneConfigCP - 1) * itemsPerPage,
                                    RoomSceneConfigCP * itemsPerPage
                                ).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">
                                                    {tableData.GAAProjectSceneCode}
                                                </td>
                                                <td className="px-4 py-3">
                                                    {tableData.GAAProjectSpaceTypePlannedDeviceName}
                                                </td>
                                                <td className="px-4 py-3">{tableData.Label}</td>
                                                <td className="px-4 py-3">
                                                    {tableData.OutputDriverChannelName}
                                                </td>
                                                <td className="px-4 py-3">
                                                    {tableData.Value}
                                                </td>
                                                <td className="px-4 py-3">
                                                    <div className="flex justify-center gap-3">
                                                        <Link
                                                            to={`/schedule/edit-schedule/${tableData.GAAProjectSceneRef}/${tableData.GAAProjectSpaceTypeRef}/${tableData.GAAProjectSpaceTypePlannedDeviceConnectionRef}`}
                                                            title="Edit"
                                                        >
                                                            <ActionButton
                                                                className={"p-1.5 text-white bg-yellow-400 "}
                                                            >
                                                                <PencilSquareIcon className="w-4 h-4" />
                                                            </ActionButton>
                                                        </Link>
                                                        {
                                                            Rights.Delete && (
                                                                <ActionButton onClick={() => deleteSceneConfiguration(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                    <TrashIcon className='w-4 h-4' />
                                                                </ActionButton>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                })}
                            </Table>
                        </div>
                    ) : (
                        <DataNotFound
                            labelText={"Sorry! Schedule Data Not Found"}
                        />
                    )}
                    {RoomSceneConfigurationList &&
                        RoomSceneConfigurationList.length > 7 ? (
                        <div className="flex justify-end">
                            <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={RoomSceneConfigurationList.length}
                                currentPage={RoomSceneConfigCP}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </AddComponent>
            )}
        </Fragment>
    );
}

export default Schedule;
