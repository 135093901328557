import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../../components/BackComponent'
import Label from '../../../helpers/Label'
import Card from '../../../helpers/Card'
import Input from '../../../helpers/Input'
import LinkButton from '../../../helpers/LinkButton'
import Loader from '../../../Common/Loader'
import Validation from '../../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../../helpers/CancelButton'
import SaveButton from '../../../helpers/SaveButton'
import flasher from '@flasher/flasher'
import CustomSelect from '../../../helpers/CustomSelect'
import { GetGAAProject, SaveGAAProject } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import { ClearInfo, ProjectTypeList, TemperatureConventionList, TimeConventionList } from '../../../../Encapsulation/Utils'
import { GetGladianceCustomer } from '../../../../Encapsulation/GladianceDomainEntities/Customer'
import { GetGladianceCountry, GetGladianceCountryList } from '../../../../Encapsulation/GladianceDomainEntities/GladianceCountryMaster'
import Option from '../../../helpers/Option'
import TextArea from '../../../helpers/TextArea'


function GAAProjectAddEdit() {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Gladiance - Edit Project";
      formMode = "Edit";
    } else {
      document.title = "Gladiance - Add Project";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation()
  const [formInfo, setFormInfo] = useState({});
  const [CountryList, setCountryList] = useState([]);
  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save Form //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAAProject(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/gaa-project");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchGladianceCustomer(); // Function To Save Customer Name to Server //
      }
    }
  };
  // Fetch Single Project Data From id While Reload//
  const fetchProject = async () => {
    setFormInfo(await GetGAAProject(id));
  };
  // Fetch Customer From Master Component Dropdown //
  const fetchGladianceCustomer = async () => {
    let Customer = await GetGladianceCustomer(Location.state);
    setFormInfo(formInfo => ({
      ...formInfo,
      CustomerRef: Customer.Ref,
      CustomerName: Customer.Name
    }))
  };


  // Fetch Country List For Dropdown //
  const fetchCountryList = async () => {
    setCountryList(await GetGladianceCountryList());
  };

  const fetchcurrency = async () => {
  const currency = formInfo.CountryName && (await GetGladianceCountry(formInfo.CountryName))
  currency &&  setFormInfo(formInfo => ({
    ...formInfo,
    Currency: currency.CurrencyName
  }))
  }

  useEffect(() => {
    if (id) {
      fetchProject();
    }
    fetchGladianceCustomer();
    fetchCountryList()
  }, []);

  useEffect(() => {
   fetchcurrency()
  }, [formInfo.CountryName]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Project" : "Add Project"}
          button={
            <LinkButton to={"/gaa-project"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"CustomerRef"} labelText={"Customer Name"} />
                    {formInfo.CustomerRef && (<Input
                      id={"CustomerRef"}
                      type={"text"}
                      name={"CustomerRef"}
                      value={formInfo.CustomerName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                    <Validation error={errors.Address} />
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Project Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      inputFocus={true}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={"CountryName"} labelText={"Country Name"} />
                    <CustomSelect
                      id={"CountryName"}
                      name={"CountryName"}
                      value={formInfo.CountryName}
                      onChange={handleChange}
                      required={true}
                    >
                    <Option value={''}>--- Select Country ---</Option>
                      {CountryList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option
                              value={data.Ref}
                            >
                              {data.Name}
                            </Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                    <Validation error={errors.CountryName} />
                  </div>
                  <div>
                    <Label htmlFor={"CityName"} labelText={"City Name"} />
                    <Input
                      id={"CityName"}
                      type={"text"}
                      name={"CityName"}
                      value={formInfo.CityName}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.CityName} />
                  </div>
                  <div>
                    <Label htmlFor={"Address"} labelText={"Address"} />
                    <Input
                      id={"Address"}
                      type={"text"}
                      name={"Address"}
                      value={formInfo.Address}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Address} />
                  </div>
                  <div>
                    <Label htmlFor={"Pincode"} labelText={"Pin code"} />
                    <Input
                      id={"Pincode"}
                      type={"text"}
                      name={"Pincode"}
                      value={formInfo.Pincode}
                      onChange={handleChange}
                      onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}
                      maxLength={6}
                      required={true}
                    />
                    <Validation error={errors.Pincode} />
                  </div>
                  <div>
                    <Label htmlFor={"Currency"} labelText={"Currency"} />
                    <Input
                      id={"Currency"}
                      type={"text"}
                      name={"Currency"}
                      value={formInfo.Currency}
                      readOnly={true}
                      required={true}
                    />
                    <Validation error={errors.Currency} />
                  </div>
                  <div>
                    <Label htmlFor={"EmailId"} labelText={"Email Id"} />
                    <Input
                      id={"EmailId"}
                      type={"email"}
                      name={"EmailId"}
                      value={formInfo.EmailId}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.EmailId} />
                  </div>
                  <div>
                    <Label htmlFor={"Website"} labelText={"Website"} />
                    <Input
                      id={"Website"}
                      type={"text"}
                      name={"Website"}
                      value={formInfo.Website}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Website} />
                  </div>
                  <div>
                    <Label htmlFor={"Telephone"} labelText={"Telephone"} />
                    <Input
                      id={"Telephone"}
                      type={"text"}
                      name={"Telephone"}
                      value={formInfo.Telephone}
                      onChange={handleChange}
                      maxLength={10}
                      required={true}
                      onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}
                    />
                    <Validation error={errors.Telephone} />
                  </div>
                  <div>
                    <Label htmlFor={"Language"} labelText={"Language"} />
                    <Input
                      id={"Language"}
                      type={"text"}
                      name={"Language"}
                      value={formInfo.Language}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Language} />
                  </div>
                  <div>
                    <Label htmlFor={"Timezone"} labelText={"Time zone"} />
                    <Input
                      id={"Timezone"}
                      type={"text"}
                      name={"Timezone"}
                      value={formInfo.Timezone}
                      onChange={handleChange}

                      required={true}
                    />
                    <Validation error={errors.Timezone} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"TimeConvention"}
                      labelText={"Time Convention"}
                    />
                    <CustomSelect
                      id={"TimeConvention"}
                      name={"TimeConvention"}
                      value={formInfo.TimeConvention}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option
                        className="bg-black bg-opacity-80 text-white"
                        value={""}
                      >
                        --- Select Time Convention ---
                      </Option>
                      {TimeConventionList && TimeConventionList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option
                              className="bg-black bg-opacity-80 text-white"
                              value={data.Value}
                            >
                              {data.Name}
                            </Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                    <Validation error={errors.TimeConvention} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"TemperatureConvention"}
                      labelText={"Temperature Convention"}
                    />
                    <CustomSelect
                      id={"TemperatureConvention"}
                      name={"TemperatureConvention"}
                      value={formInfo.TemperatureConvention}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option
                        className="bg-black bg-opacity-80 text-white"
                        value={""}
                      >
                        --- Select Temperature Convention ---
                      </Option>
                      {TemperatureConventionList && TemperatureConventionList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option
                              className="bg-black bg-opacity-80 text-white"
                              value={data.Value}
                            >
                              {data.Name}
                            </Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                    <Validation error={errors.TemperatureConvention} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"IoTDataRetentionPeriodInDays"}
                      labelText={"IoT Data Retention Period In Days"}
                    />
                    <Input
                      id={"IoTDataRetentionPeriodInDays"}
                      type={"number"}
                      name={"IoTDataRetentionPeriodInDays"}
                      value={formInfo.IoTDataRetentionPeriodInDays}
                      onChange={handleChange}

                      required={true}
                    />
                    <Validation error={errors.IoTDataRetentionPeriodInDays} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectType"}
                      labelText={"Project Type"}
                    />
                    <CustomSelect
                      id={"GAAProjectType"}
                      name={"GAAProjectType"}
                      value={formInfo.GAAProjectType}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option
                        className="bg-black bg-opacity-80 text-white"
                        value={""}
                      >
                        --- Select Project Type ---
                      </Option>
                      {ProjectTypeList && ProjectTypeList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option
                              className="bg-black bg-opacity-80 text-white"
                              value={data.Value}
                            >
                              {data.Name}
                            </Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                    <Validation error={errors.GAAProjectType} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/gaa-project"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectAddEdit;
