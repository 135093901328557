import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  CheckIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import AddComponent from "../../../components/AddComponent";
import { useStateContext } from "../../../context/ContextProvider";
import DataNotFound from "../../helpers/DataNotFound";
import { GetComboList } from "../../../Encapsulation/GladianceDomainEntities/Combo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function GAAComboMaster() {
  document.title = "Gladiance - Ambiance Automation Combo ";
  const tableHeading = [
    "Sr.No",
    "Combo Code",
    "Name",
    "Image",
    "Verticals",
    "Action",
  ];
  const tableHeading2 = ["Hotels", "Homes", "Offices", "Retail"];
  const tableHeading3 = [
    "Sr.No",
    "Img",
    "Composition Name",
    "QTY",
    "Rate",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [comboList, setComboList] = useState([
    {
      ComboCode: "XYZ",
      Name: "ABC",
      arr1: [
        {
          Hotel: "true",
          Home: "false",
          Office: "true",
          Retail: "true",
        },
      ],
      arr2: [
        {
          img: "XYZ",
          CompositionName: "ABC",
          Qty: "123",
          Rate: "2000",
        },
        {
          img: "XYZ",
          CompositionName: "PQR",
          Qty: "321",
          Rate: "3000",
        },
      ],
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [image, setImage] = useState("");
  const [accordian, setAccordian] = useState(false);
  const { FRONTEND_URL } = useStateContext();
  const itemsPerPage = 7;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const headers = [
    { label: "Sr.No", key: "index" },
    { label: "Material", key: "material_name" },
    { label: "Unit", key: "material_unit" },
  ];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [errors, setErrors] = useState([]);

  const [gaacomboList, setGAAComboListList] = useState([]);
  const fetchGladianceUserRoleList = async () => {
    setGAAComboListList(await GetComboList(setErrors));
  };
  const deleteCombo = (id) => {
    // Swal.fire({
    //     title: 'Are you sure to delete this?',
    //     text: "You won't be able to revert this!",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //     if (result.isConfirmed) {
    //         axiosClient.delete(`/delete_material/${id}`)
    //             .then(({ data }) => {
    //                 flasher.success(data.msg)
    //                 getComboList()
    //             })
    //     }
    // })
  };
  useEffect(() => {
    //   fetchGladianceUserRoleList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Ambiance Automation Combos"}
          button={
            <LinkButton to={"/gladiance-combo/add-gladiance-combo"}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Combo</span>
            </LinkButton>
          }
        >
          {comboList && comboList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {comboList
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    const serialNumber = (currentPage - 1) * itemsPerPage + tableDataID + 1;
                    return (
                      <Fragment key={tableDataID}>
                        <tr
                          onClick={() => setAccordian(!accordian)}
                          className="border-b border-opacity-30 border-white text-center"
                          id="accordion-collapse"
                          data-accordion="collapse"
                        >
                          <td className="px-4 py-3">{serialNumber}</td>
                          <td className="px-4 py-3">{tableData.Code}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            {
                              <Link className="flex justify-center items-centertransition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300">
                                <img
                                  src={
                                    image
                                      ? image.name
                                        ? URL.createObjectURL(image)
                                        : FRONTEND_URL +
                                        "/assets/images/image/" +
                                        image
                                      : FRONTEND_URL +
                                      "/assets/images/noimage.png"
                                  }
                                  alt="Cad-img"
                                  className="w-12 h-12 rounded"
                                />
                              </Link>
                            }
                          </td>
                          <td className="px-4 py-3">
                            {
                              <Table tableHeading={tableHeading2}>
                                {tableData.arr1 &&
                                  tableData.arr1.map((data, id) => {
                                    return (
                                      <Fragment key={id}>
                                        <tr
                                          id="accordion-collapse-body-1"
                                          className={
                                            "border-b border-opacity-30 border-white text-center"
                                          }
                                          aria-labelledby="accordion-collapse-heading-1"
                                        >
                                          {/* <td className="px-4 py-3">{(tableDataID + 1) + ((id + 1) / 10)}</td> */}
                                          <td className="px-4 py-3">
                                            {data.Hotel == "true" ? (
                                              <CheckIcon className="w-4 h-4" />
                                            ) : (
                                              <XMarkIcon className="w-4 h-4" />
                                            )}
                                          </td>
                                          <td className="px-4 py-3">
                                            {data.Home == "true" ? (
                                              <CheckIcon className="w-4 h-4" />
                                            ) : (
                                              <XMarkIcon className="w-4 h-4" />
                                            )}
                                          </td>
                                          <td className="px-4 py-3">
                                            {data.Office == "true" ? (
                                              <CheckIcon className="w-4 h-4" />
                                            ) : (
                                              <XMarkIcon className="w-4 h-4" />
                                            )}
                                          </td>
                                          <td className="px-4 py-3">
                                            {data.Retail == "true" ? (
                                              <CheckIcon className="w-4 h-4" />
                                            ) : (
                                              <XMarkIcon className="w-4 h-4" />
                                            )}
                                          </td>
                                        </tr>
                                      </Fragment>
                                    );
                                  })}
                              </Table>
                            }
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link title="Edit">
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              <ActionButton
                                onClick={() => deleteCombo(tableData.id)}
                                title={"Delete"}
                                className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </ActionButton>
                            </div>
                          </td>
                        </tr>
                        <tr className={accordian ? "visible" : "hidden"}>
                          <td colspan="6">
                            {
                              <Table tableHeading={tableHeading3}>
                                {tableData.arr2 &&
                                  tableData.arr2.map((data, id) => {
                                    return (
                                      <Fragment key={id}>
                                        <tr
                                          id="accordion-collapse-body-1"
                                          className={
                                            accordian
                                              ? "border-b border-opacity-30 bg-[rgba(0,0,0,0.6)] border-white text-center"
                                              : "hidden"
                                          }
                                          aria-labelledby="accordion-collapse-heading-1"
                                        >
                                          <td className="px-4 py-3">
                                            {tableDataID + 1 + (id + 1) / 10}
                                          </td>
                                          <td className="px-4 py-3 justify-center flex">
                                            {
                                              <Link className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300">
                                                <img
                                                  src={
                                                    image
                                                      ? image.name
                                                        ? URL.createObjectURL(
                                                          image
                                                        )
                                                        : FRONTEND_URL +
                                                        "/assets/images/image/" +
                                                        image
                                                      : FRONTEND_URL +
                                                      "/assets/images/noimage.png"
                                                  }
                                                  alt="Cad-img"
                                                  className="w-12 h-12 rounded"
                                                />
                                              </Link>
                                            }
                                          </td>
                                          <td className="px-4 py-3">
                                            {data.CompositionName}
                                          </td>
                                          <td className="px-4 py-3">
                                            {data.Qty}
                                          </td>
                                          <td className="px-4 py-3">
                                            {data.Rate}
                                          </td>
                                          <td className="px-4 py-3">
                                            <div className="flex justify-center gap-3">
                                              <Link title="Edit">
                                                <ActionButton
                                                  className={
                                                    "p-1.5 text-white bg-yellow-400 "
                                                  }
                                                >
                                                  <PencilSquareIcon className="w-4 h-4" />
                                                </ActionButton>
                                              </Link>
                                              <ActionButton
                                                onClick={() =>
                                                  deleteCombo(tableData.id)
                                                }
                                                title={"Delete"}
                                                className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                              >
                                                <TrashIcon className="w-4 h-4" />
                                              </ActionButton>
                                            </div>
                                          </td>
                                        </tr>
                                      </Fragment>
                                    );
                                  })}
                              </Table>
                            }
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound
              labelText={" Sorry! Ambiance Automation Combos Data Not Found"}
            />
          )}
          {comboList && comboList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={comboList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}
export default GAAComboMaster;
