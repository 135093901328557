import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'
import '../../CSS/Style.css'
function Table({ className, className2, children, tableHeading }) {
    const { theme } = useStateContext()
    return (
        <Fragment>
            <div className={"relative overflow-x-auto  journal-scroll " + (className2)}>
                <table className={"w-full text-left " + (theme ? 'text-default-color' : 'text-gray-800')}>
                    <thead className={"md:text-base text-[2vw] tracking-wider text-orange-400 border-b border-orange-400 text-center border-t " + (className)}>
                        <tr>
                            {tableHeading && tableHeading.map((heading, id) => {
                                return (<Fragment key={id}>
                                    <th scope="col" className="px-4 py-3 break-words whitespace-nowrap">{heading}</th>
                                </Fragment>)
                            })}
                        </tr>
                    </thead>
                    <tbody className='md:text-sm text-center text-[2vw] tracking-wide'>{children}</tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default Table