import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../../Encapsulation/Axios'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { useStateContext } from '../../../context/ContextProvider'
import { AddCircle, ArrowCircleLeft2, CloseCircle, DirectInbox } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import { PlusCircleIcon, PlusSmallIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import { GetHotelGuestCheckOut, SaveHotelGuestCheckOut } from '../../../Encapsulation/HotelDomainEntities/HotelGuestCheckOut'

function CheckOutAddEdit() {
  let formMode = 'New';
  const { id } = useParams()
  if (id) {
    document.title = 'Gladiance - Edit Check Out'
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Check Out'
  }
  const Navigation = useNavigate()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const [formInfo, setFormInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let result = await SaveHotelGuestCheckOut(id, formInfo, []);
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation('/check-out');
      }
      else {
        setFormInfo(ClearInfo(formInfo))
      }
    }
  }

  const fetchCheckOut = async () => {
    setFormInfo(await GetHotelGuestCheckOut(id));
  }

  useEffect(() => {
    if (id) {
      fetchCheckOut();
    }
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? 'Edit Check Out' : 'Add Check Out'} button={
          <LinkButton to={'/check-out'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={handleSubmit}>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'RoomNo'} labelText={'Room No'}  />
                    <Input id={'RoomNo'} type={'text'} name={'RoomNo'} value={formInfo.RoomNo} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.RoomNo} />
                  </div>
                  <div>
                    <Label htmlFor={'Name'} labelText={'Name'}  />
                    <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={'CheckInTime'} labelText={'Check In Time'}  />
                    <Input id={'CheckInTime'} type={'text'} name={'CheckInTime'} value={formInfo.CheckInTime} onChange={handleChange} />
                    <Validation error={errors.CheckInTime} />
                  </div>
                  <div>
                    <Label htmlFor={'CheckInDate'} labelText={'Check In Date'}  />
                    <Input id={'CheckInDate'} type={'text'} name={'CheckInDate'} value={formInfo.CheckInDate} onChange={handleChange}
                       />
                    <Validation error={errors.CheckInDate} />
                  </div>
                  <div>
                    <Label htmlFor={'CheckOutTime'} labelText={'Check Out Tme'}  />
                    <Input id={'CheckOutTime'} type={'text'} name={'CheckOutTime'} value={formInfo.CheckOutDate} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.CheckOutTime} />
                  </div>
                  <div>
                    <Label htmlFor={'CheckOutDate'} labelText={'Check Out Date'}  />
                    <Input id={'CheckOutDate'} type={'text'} name={'CheckOutDate'} value={formInfo.CheckOutDate} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.CheckOutDate} />
                  </div>
                  <div>
                    <Label htmlFor={'Mobile'} labelText={'Mobile'}  />
                    <Input id={'Mobile'} type={'text'} name={'Mobile'} value={formInfo.Mobile} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.Mobile} />
                  </div>
                  <div>
                    <Label htmlFor={'Email'} labelText={'Email'}  />
                    <Input id={'Email'} type={'text'} name={'Email'} value={formInfo.Email} onChange={handleChange}  />
                    <Validation error={errors.Email} />
                  </div>
                  <div>
                    <Label htmlFor={'Action'} labelText={'Action'}  />
                    <Input id={'Action'} type={'text'} name={'Action'} value={formInfo.Action} onChange={handleChange}  />
                    <Validation error={errors.Action} />
                  </div>
                  <div>
                  <button className='group relative flex justify-center rounded-md bg-transparent text-orange-500 w-[16vw] mt-6  p-2 gap-5 tracking-wider font-normal duration-300'>
                  Add Member with same data<AddCircle variant="Outline"  className="h-5 w-5" /></button>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/check-out'} />
                  <SaveButton text='Save' />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}




export default CheckOutAddEdit
