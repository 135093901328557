import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import Validation from "../../helpers/Validation";
import { ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import SaveButton from "../../helpers/SaveButton";
import { ClearInfo } from "../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";
import { GetHotelRoomScene } from "../../../Encapsulation/HotelDomainEntities/HotelRoomScene";
import { GetProjectSpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDeviceConnections";
import { GetProjectSpaceTypeArea } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { GetGAAProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import {
  GetHotelRoomSceneConfiguration,
  SaveHotelRoomSceneConfiguration,
} from "../../../Encapsulation/HotelDomainEntities/HotelRoomSceneConfiguration";
import CheckBox from "../../helpers/CheckBox";
// import TimePicker from "react-time-picker";
// import "react-time-picker/dist/TimePicker.css";
import { useStateContext } from "../../../context/ContextProvider";

function ScheduleAddEdit({ Rights }) {
  let formMode = "New";
  let {
    id,
    GAAProjectSceneRef,
    GAAProjectSpaceTypeRef,
    GAAProjectSpaceTypePlannedDeviceConnectionRef,
  } = useParams();
  {
    if (
      (id,
        GAAProjectSceneRef,
        GAAProjectSpaceTypeRef,
        GAAProjectSpaceTypePlannedDeviceConnectionRef)
    ) {
      document.title = "Hotel - Edit Schedule";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add Schedule";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation();
  const { theme } = useStateContext();

  // const [formInfo, setFormInfo] = useState({});
  const [formInfo, setFormInfo] = useState({
    ReleaseDate: new Date().toISOString(),
  });
  console.log("formInfo :", formInfo);

  const [ProjectRef, setProjectRef] = useState();
  const [DeviceConnectionList, setDeviceConnectionList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2024);

  const handleChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const years = [];
  for (let year = 2024; year <= 2050; year++) {
    years.push(year);
  }

  const monthdays = [];
  for (let day = 1; day <= 31; day++) {
    monthdays.push(day);
  }

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveHotelRoomSceneConfiguration(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/schedule");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProjectSpaceTypeandAreaHotelRoomScene(); // Save ProjectRef
      }
      console.log("result :", result);
    }
  };

  const fetchProjectSpaceTypeandAreaHotelRoomScene = async () => {
    let Project = await GetGAAProject(Location.state.GAAProjectRef);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name,
    }));
    let SpaceType = await GetProjectSpaceType(
      Location.state.GAAProjectSpaceTypeRef
    );
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectSpaceTypeRef: SpaceType.Ref,
      GAAProjectSpaceTypeName: SpaceType.Name,
    }));

    let SpaceTypeArea = "";
    if (Location.state.GAAProjectSpaceTypeAreaRef) {
      SpaceTypeArea = await GetProjectSpaceTypeArea(
        Location.state.GAAProjectSpaceTypeAreaRef
      );
    }
    if (SpaceTypeArea) {
      setFormInfo((formInfo) => ({
        ...formInfo,
        GAAProjectSpaceTypeAreaRef: SpaceTypeArea.Ref,
        GAAProjectSpaceTypeAreaName: SpaceTypeArea.Name,
      }));
    } else {
      setFormInfo((formInfo) => ({
        ...formInfo,
        GAAProjectSpaceTypeAreaRef: 0,
        GAAProjectSpaceTypeAreaName: "-",
      }));
    }
    let Scene = await GetHotelRoomScene(Location.state.GAAProjectSceneRef);
    setProjectRef(Scene.GAAProjectRef);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectSceneRef: Scene.Ref,
      GAAProjectSceneName: Scene.Name,
    }));
  };

  const fetchDeviceConnectionBySpaceType = async () => {
    formInfo.GAAProjectSpaceTypeRef &&
      setDeviceConnectionList(
        await GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType(
          formInfo.GAAProjectSpaceTypeRef,
          0
        )
      );
    console.log("DeviceConnectionList :", DeviceConnectionList);
  };

  const fetchRoomSceneConfiguration = async () => {
    let data = await GetHotelRoomSceneConfiguration(
      GAAProjectSceneRef,
      GAAProjectSpaceTypeRef,
      GAAProjectSpaceTypePlannedDeviceConnectionRef
    );
    console.log("data :", data);
    setFormInfo(data);
  };

  // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchProjectSpaceTypeandAreaHotelRoomScene();
    }
    if (
      (GAAProjectSceneRef,
        GAAProjectSpaceTypeRef,
        GAAProjectSpaceTypePlannedDeviceConnectionRef)
    ) {
      fetchRoomSceneConfiguration();
    }
  }, []);

  useEffect(() => {
    if (DeviceConnectionList.length > 0) {
      setFormInfo((formInfo) => ({
        ...formInfo,
        GAAProjectSpaceTypePlannedDeviceConnectionRef:
          DeviceConnectionList[0].Ref,
      }));
    }
  }, [DeviceConnectionList]);

  useEffect(() => {
    fetchDeviceConnectionBySpaceType();
  }, [formInfo.GAAProjectSpaceTypeRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={
            (id,
              GAAProjectSceneRef,
              GAAProjectSpaceTypeRef,
              GAAProjectSpaceTypePlannedDeviceConnectionRef)
              ? "Edit Schedule"
              : "Add Schedule"
          }
          button={
            <LinkButton to={"/schedule"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"GAAProjectName"} labelText={"Project"} />
                    {formInfo.GAAProjectRef && (
                      <Input
                        id={"GAAProjectName"}
                        type={"text"}
                        name={"GAAProjectName"}
                        value={formInfo.GAAProjectName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeName"}
                      labelText={"Space Type"}
                    />
                    {formInfo.GAAProjectSpaceTypeRef && (
                      <Input
                        id={"GAAProjectSpaceTypeName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeName"}
                        value={formInfo.GAAProjectSpaceTypeName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeAreaName"}
                      labelText={"Area"}
                    />
                    {formInfo.GAAProjectSpaceTypeAreaRef == 0 ? (
                      <Input
                        id={"GAAProjectSpaceTypeAreaName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeAreaName"}
                        value={formInfo.GAAProjectSpaceTypeAreaName}
                        required={true}
                        readOnly={true}
                      />
                    ) : (
                      <Input
                        id={"GAAProjectSpaceTypeAreaName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeAreaName"}
                        value={formInfo.GAAProjectSpaceTypeAreaName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"ScheduleName"}
                      labelText={"Schedule Name"}
                    />
                    <Input
                      id={"ScheduleName"}
                      type={"text"}
                      name={"ScheduleName"}
                      value={formInfo.ScheduleName}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.ScheduleName} />
                  </div>

                  <div>
                    <Label
                      htmlFor={"ScheduleName"}
                      labelText={"Schedule Name"}
                    />
                    <Input
                      id={"ScheduleName"}
                      type={"time"}
                      name={"ScheduleName"}
                      value={formInfo.ScheduleName}
                      step="1"
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.ScheduleName} />
                  </div>

                  <div>
                    <Label
                      htmlFor={"NodeConfigParamName"}
                      labelText={"Node Config Param Name"}
                    />
                    <Input
                      id={"NodeConfigParamName"}
                      type={"text"}
                      name={"NodeConfigParamName"}
                      value={formInfo.NodeConfigParamName}
                      inputFocus={true}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.NodeConfigParamName} />
                  </div>
                  <div>
                    <Label htmlFor={"Value"} labelText={"Value"} />
                    <Input
                      id={"Value"}
                      type={"text"}
                      name={"Value"}
                      value={formInfo.Value}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Value} />
                  </div>
                </div>
                <div className="mt-10"> Trigger Time :</div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
                  <div></div>

                  <div>
                    <Label htmlFor={"Time"} labelText={"Time (hh:mm:ss) "} />
                    <input
                      className={
                        "block w-full rounded-md border-0 text-sm py-2 shadow-sm ring-1 ring-inset bg-transparent ring-gray-400 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#f94327] sm:leading-6 transition ease-in-out delay-150 duration-300 " +
                        (theme ? "text-default-color " : "text-gray-800 ")
                      }
                      id="Time"
                      name="Time"
                      type="time"
                      value={formInfo.time}
                      onChange={handleChange}
                      step="1" // step attribute to allow seconds selection
                    />
                  </div>
                </div>

                <div className="ml-5 mt-10 flex content-center items-center mb-5">
                  <Label htmlFor={"DayOfWeek"} labelText={"Day Of Week :"} />
                  <div className={" p-2 pl-4 flex items-center"}>
                    <CheckBox
                      id={"DayOfWeek"}
                      readOnly={!Rights.Edit}
                      name={"DayOfWeek"}
                      type={"checkbox"}
                      onClick={(e) =>
                        setFormInfo({
                          ...formInfo,
                          DayOfWeek: e.target.checked,
                        })
                      }
                      checked={formInfo.DayOfWeek}
                    />
                  </div>
                </div>

                {formInfo.DayOfMonth != formInfo.DayOfWeek ? (
                  <div className="grid grid-cols-1 md:grid-cols-7 gap-4 mb-5">
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Monday"} labelText={"Monday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Monday"}
                          readOnly={!Rights.Edit}
                          name={"Monday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Monday: e.target.checked,
                            })
                          }
                          checked={formInfo.Monday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Tuesday"} labelText={"Tuesday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Tuesday"}
                          readOnly={!Rights.Edit}
                          name={"Tuesday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Tuesday: e.target.checked,
                            })
                          }
                          checked={formInfo.Tuesday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Wednesday"} labelText={"Wednesday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Wednesday"}
                          readOnly={!Rights.Edit}
                          name={"Wednesday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Wednesday: e.target.checked,
                            })
                          }
                          checked={formInfo.Wednesday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Thursday"} labelText={"Thursday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Thursday"}
                          readOnly={!Rights.Edit}
                          name={"Thursday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Thursday: e.target.checked,
                            })
                          }
                          checked={formInfo.Thursday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Friday"} labelText={"Friday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Friday"}
                          readOnly={!Rights.Edit}
                          name={"Friday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Friday: e.target.checked,
                            })
                          }
                          checked={formInfo.Friday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Saturday"} labelText={"Saturday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Saturday"}
                          readOnly={!Rights.Edit}
                          name={"Saturday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Saturday: e.target.checked,
                            })
                          }
                          checked={formInfo.Saturday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Sunday"} labelText={"Sunday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Sunday"}
                          readOnly={!Rights.Edit}
                          name={"Sunday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Sunday: e.target.checked,
                            })
                          }
                          checked={formInfo.Sunday}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <span className="flex justify-center mb-5 ">
                  - - - - - - - - - - - - - OR - - - - - - - - - - - - -
                </span>

                <div className="ml-5 mt-5 flex content-center items-center mb-5">
                  <Label htmlFor={"DayOfMonth"} labelText={"Day Of Month :"} />
                  <div className={" p-2 pl-4 flex items-center"}>
                    <CheckBox
                      id={"DayOfMonth"}
                      readOnly={!Rights.Edit}
                      name={"DayOfMonth"}
                      type={"checkbox"}
                      onClick={(e) =>
                        setFormInfo({
                          ...formInfo,
                          DayOfMonth: e.target.checked,
                        })
                      }
                      checked={formInfo.DayOfMonth}
                    />
                  </div>
                </div>

                {formInfo.DayOfMonth ? (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
                    <div></div>
                    <div>
                      <Label htmlFor={"Date"} labelText={"Date"} />

                      <CustomSelect
                        id="Date"
                        name="Date"
                        value={formInfo.Date}
                        onChange={handleChange}
                      >
                        <Option value={""}>-- Select Date --</Option>
                        {monthdays &&
                          monthdays.map((day, id) => {
                            return (
                              <Option key={id} value={day}>{day}</Option>
                            );
                          })}
                      </CustomSelect>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div>
                  <Label
                    htmlFor={"MonthOrWeek"}
                    labelText={"Select Month or Week"}
                  />

                  <CustomSelect
                    id="MonthOrWeek"
                    name="MonthOrWeek"
                    value={formInfo.MonthOrWeek}
                    onChange={handleChange}
                  >
                    return (
                    <Fragment>
                      <Option value={"DayOfMonth"}>Day Of Month</Option>
                      <Option value={"DayOfWeek"}>Day Of Week</Option>
                    </Fragment>
                    );
                  </CustomSelect>
                </div>

                {formInfo.MonthOrWeek == "DayOfWeek" ? (
                  <div className="grid grid-cols-1 md:grid-cols-7 gap-4 mb-5  mt-5">
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Monday"} labelText={"Monday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Monday"}
                          readOnly={!Rights.Edit}
                          name={"Monday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Monday: e.target.checked,
                            })
                          }
                          checked={formInfo.Monday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Tuesday"} labelText={"Tuesday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Tuesday"}
                          readOnly={!Rights.Edit}
                          name={"Tuesday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Tuesday: e.target.checked,
                            })
                          }
                          checked={formInfo.Tuesday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Wednesday"} labelText={"Wednesday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Wednesday"}
                          readOnly={!Rights.Edit}
                          name={"Wednesday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Wednesday: e.target.checked,
                            })
                          }
                          checked={formInfo.Wednesday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Thursday"} labelText={"Thursday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Thursday"}
                          readOnly={!Rights.Edit}
                          name={"Thursday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Thursday: e.target.checked,
                            })
                          }
                          checked={formInfo.Thursday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Friday"} labelText={"Friday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Friday"}
                          readOnly={!Rights.Edit}
                          name={"Friday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Friday: e.target.checked,
                            })
                          }
                          checked={formInfo.Friday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Saturday"} labelText={"Saturday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Saturday"}
                          readOnly={!Rights.Edit}
                          name={"Saturday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Saturday: e.target.checked,
                            })
                          }
                          checked={formInfo.Saturday}
                        />
                      </div>
                    </div>
                    <div className="grid content-center justify-center">
                      <Label htmlFor={"Sunday"} labelText={"Sunday"} />
                      <div className={"flex justify-center content-center p-2"}>
                        <CheckBox
                          id={"Sunday"}
                          readOnly={!Rights.Edit}
                          name={"Sunday"}
                          type={"checkbox"}
                          onClick={(e) =>
                            setFormInfo({
                              ...formInfo,
                              Sunday: e.target.checked,
                            })
                          }
                          checked={formInfo.Sunday}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10 mt-5">
                    <div></div>
                    <div>
                      <Label htmlFor={"Date"} labelText={"Date"} />

                      <CustomSelect
                        id="Date"
                        name="Date"
                        value={formInfo.Date}
                        onChange={handleChange}
                      >
                        <Option value={""}>-- Select Date --</Option>
                        {monthdays &&
                          monthdays.map((day, id) => {
                            return (
                              <Option key={id} value={day}>{day}</Option>
                            );
                          })}
                      </CustomSelect>
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-6  mb-4 mt-10">
                  <div className="grid content-center justify-center  ">
                    <Label htmlFor={"January"} labelText={"January"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"January"}
                        readOnly={!Rights.Edit}
                        name={"January"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            January: e.target.checked,
                          })
                        }
                        checked={formInfo.January}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center  ">
                    <Label htmlFor={"February"} labelText={"February"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"February"}
                        readOnly={!Rights.Edit}
                        name={"February"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            February: e.target.checked,
                          })
                        }
                        checked={formInfo.February}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"March"} labelText={"March"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"March"}
                        readOnly={!Rights.Edit}
                        name={"March"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            March: e.target.checked,
                          })
                        }
                        checked={formInfo.March}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"April"} labelText={"April"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"April"}
                        readOnly={!Rights.Edit}
                        name={"April"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            April: e.target.checked,
                          })
                        }
                        checked={formInfo.April}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"May"} labelText={"May"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"May"}
                        readOnly={!Rights.Edit}
                        name={"May"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({ ...formInfo, May: e.target.checked })
                        }
                        checked={formInfo.May}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"June"} labelText={"June"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"June"}
                        readOnly={!Rights.Edit}
                        name={"June"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            June: e.target.checked,
                          })
                        }
                        checked={formInfo.June}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"July"} labelText={"July"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"July"}
                        readOnly={!Rights.Edit}
                        name={"July"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({ ...formInfo, July: e.target.checked })
                        }
                        checked={formInfo.July}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"August"} labelText={"August"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"August"}
                        readOnly={!Rights.Edit}
                        name={"August"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            August: e.target.checked,
                          })
                        }
                        checked={formInfo.August}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"Spetember"} labelText={"Spetember"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"Spetember"}
                        readOnly={!Rights.Edit}
                        name={"Spetember"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            Spetember: e.target.checked,
                          })
                        }
                        checked={formInfo.Spetember}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"October"} labelText={"October"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"October"}
                        readOnly={!Rights.Edit}
                        name={"October"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            October: e.target.checked,
                          })
                        }
                        checked={formInfo.October}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"November"} labelText={"November"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"November"}
                        readOnly={!Rights.Edit}
                        name={"November"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            November: e.target.checked,
                          })
                        }
                        checked={formInfo.November}
                      />
                    </div>
                  </div>
                  <div className="grid content-center justify-center">
                    <Label htmlFor={"December"} labelText={"December"} />
                    <div className={"flex justify-center content-center p-2"}>
                      <CheckBox
                        id={"December"}
                        readOnly={!Rights.Edit}
                        name={"December"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({
                            ...formInfo,
                            December: e.target.checked,
                          })
                        }
                        checked={formInfo.December}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
                  <div></div>
                  <div>
                    <Label htmlFor={"time"} labelText={"Year"} />
                    <Input
                      id={"time"}
                      type={"Time"}
                      name={"time"}
                      value={formInfo.time}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
                  <div></div>
                  <div>
                    <Label htmlFor={"Year"} labelText={"Year"} />
                    <CustomSelect
                      id="Year"
                      name="Year"
                      value={formInfo.selectedYear}
                      onChange={handleChange}
                    >
                      <Option value={""}>-- Select Year --</Option>
                      {years &&
                        years.map((year, id) => {
                          return (
                            <Option key={id} value={year}>{year}</Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                </div>

                <div className="ml-5 mt-10 flex content-center items-center mb-8">
                  <Label
                    htmlFor={"RepeatEveryYear"}
                    labelText={"Repeat Every Year :"}
                  />
                  <div className={" p-2 pl-4 flex items-center"}>
                    <CheckBox
                      id={"RepeatEveryYear"}
                      readOnly={!Rights.Edit}
                      name={"RepeatEveryYear"}
                      type={"checkbox"}
                      onClick={(e) =>
                        setFormInfo({
                          ...formInfo,
                          RepeatEveryYear: e.target.checked,
                        })
                      }
                      checked={formInfo.RepeatEveryYear}
                    />
                  </div>
                </div>
                <div className="flex gap-8 justify-center">
                  <CancelButton to={"/schedule"} />
                  {Rights.Edit && <SaveButton text="Save" />}
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default ScheduleAddEdit;
