import React, { Fragment, useEffect, useRef } from 'react'
import { useStateContext } from '../../context/ContextProvider';

function TextArea({id,name,value,rows,className,onChange,readOnly,disabled,inputFocus,type,required}) {

    const { theme } = useStateContext();
    const textareaRef = useRef(null);
    useEffect(() => {
        if (inputFocus == true) {
            textareaRef.current.focus();
        }
    }, []);
    return (
        <Fragment>
            <textarea ref={textareaRef} type={type} id={id} name={name} value={value} rows={rows} className={"block w-full bg-transparent rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#f94327] sm:text-sm sm:leading-6 "+ (theme ? 'text-default-color ' : 'text-gray-800 ')+(className)} onChange={onChange} readOnly={readOnly} disabled={disabled} required={required}></textarea>
        </Fragment>
    )
}

export default TextArea