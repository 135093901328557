import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import flasher from '@flasher/flasher'
import { useStateContext } from '../../../context/ContextProvider'
import axiosClient from '../../../Encapsulation/Axios'

function GladianceUserRoleMaster() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - Users'
    const tableHeading = ['Sr.No', 'Name', 'Email ID', "Phone No's", 'Role', 'Status', 'Action']
    const [loader, setLoader] = useState(false)
    const itemsPerPage = 7;
    const [errors, setErrors] = useState([])
    const [gladianceUserList, setGladianceUserList] = useState([]);
    const { UserCP, setUserCP } = useStateContext()
    const loginToken = '36983F8347364554B0E3132DD99267C4';
    const loginDeviceId = 'c03ea267-031f-4eb8-a441-e2150129d5f2';


    const fetchGladianceUserList = async () => {
        axiosClient.get(`usermanagement/gladianceuserlist/${loginToken}/${loginDeviceId}`)
            .then(({ data }) => {
                setGladianceUserList(data.ObjectTag)
                setLoader(false)
                if (!data.Successful) {
                    flasher.warning(data.Message)
                }
            }).catch((error) => {
                flasher.warning(error)
            })
    }

    const handlePageChange = (pageNumber) => {
        setUserCP(pageNumber);
    };

    useEffect(() => {
        fetchGladianceUserList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Users'} button={
                    <LinkButton to={'/gladiance-user-master/add-user-master'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add User</span>
                    </LinkButton>
                }>
                    {gladianceUserList && gladianceUserList.length > 0 ? (
                        <div className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {gladianceUserList && gladianceUserList.slice((UserCP - 1) * itemsPerPage, UserCP * itemsPerPage).map((tableData, tableDataID) => {
                                    const serialNumber = (UserCP - 1) * itemsPerPage + tableDataID + 1;
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{serialNumber}</td>
                                                <td className="px-4 py-3">{tableData.FirstName} {tableData.MiddleName} {tableData.LastName} </td>
                                                <td className="px-4 py-3">{tableData.EMailId}</td>
                                                <td className="px-4 py-3">{tableData.PhoneNos}</td>
                                                <td className="px-4 py-3"> {tableData.Role == 200 ? "Gladiance Admin" : "Gladiance Staff"}</td>
                                                <td className="px-4 py-3"> {tableData.Enabled == 1 ? "Enabled" : "Disabled"}</td>

                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link to={`/gladiance-user-master/edit-gladiance-user-master/${tableData.Ref}`} title='Edit' ><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                            <PencilSquareIcon className='w-4 h-4' />
                                                        </ActionButton></Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                            <p className='text-[3vw] md:text-3xl font-bold'>Sorry! User Master Data Not Found</p>
                        </div>
                    )}
                    {gladianceUserList && gladianceUserList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={gladianceUserList.length}
                            currentPage={UserCP}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}

export default GladianceUserRoleMaster
