import React, { Fragment } from 'react';
import { useStateContext } from '../../context/ContextProvider';

function Pagination({ itemsPerPage, totalItems, currentPage, onPageChange }) {
  const { theme } = useStateContext()
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = currentPage - 1;
    let endPage = currentPage + 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(3, totalPages);
    }
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - 2);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    return paginationNumbers.map((pageNumber, id) => (
      <Fragment key={id}>
        <button
          key={pageNumber}
          className={(theme ? (`"flex items-center justify-center px-3 h-8 leading-tight  border-spacing-3 border border-gray-300 hover:bg-gray-300 hover:text-gray-700 "  ${currentPage === pageNumber
              ? 'bg-[#cfebfd] text-black '
              : 'hover:bg-gray-200 text-white'
            }`) : (`"flex items-center justify-center px-3 h-8 leading-tight border-spacing-3 border border-gray-300 hover:bg-gray-300 hover:text-gray-700 "  ${currentPage === pageNumber
                ? 'bg-[#cfebfd] text-black '
                : 'hover:bg-gray-200 text-black'
              }`))}
          onClick={() => handlePageClick(pageNumber)}>
          {pageNumber}
        </button>
      </Fragment>
    ));
  };

  return (
    <div className="absolute bottom-3 right-3 flex items-center justify-end h-8 text-sm mt-1">
      <div className="flex ">
        <button className={(theme ? (`"flex items-center justify-center px-3 h-8 ml-0 leading-tight font-bold  border border-gray-300 rounded-l-lg rounded-r-none hover:bg-gray-300 hover:text-gray-700" ${currentPage === 1
            ? ' text-white rounded'
            : 'hover:bg-gray-200'
          }`) : (`"flex items-center justify-center px-3 h-8 ml-0 leading-tight font-bold text-black border border-gray-300 rounded-l-lg rounded-r-none hover:bg-gray-300 hover:text-gray-700" ${currentPage === 1
              ? ' text-black rounded'
              : 'hover:bg-gray-200'
            }`))}
          disabled={currentPage === 1}
          onClick={() => handlePageClick(currentPage - 1)}>
          {'<'}
        </button>
        {renderPaginationNumbers()}
        <button className={(theme ? (`"flex items-center justify-center px-3 h-8 ml-0 leading-tight font-bold text-white  border border-gray-300 rounded-r-lg rounded-l-none hover:bg-gray-300 hover:text-gray-700 " ${currentPage === totalPages
            ? ' text-white rounded'
            : 'hover:bg-gray-200'
          }`) : (`"flex items-center justify-center px-3 h-8 ml-0 leading-tight font-bold text-black  border border-gray-300 rounded-r-lg rounded-l-none hover:bg-gray-300 hover:text-gray-700 " ${currentPage === totalPages
              ? ' text-black rounded'
              : 'hover:bg-gray-200'
            }`))}
          disabled={currentPage === totalPages}
          onClick={() => handlePageClick(currentPage + 1)}>
          {'>'}
        </button>
      </div>
    </div>
  );
}
export default Pagination;
