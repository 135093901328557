import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link, NavLink, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import "../CSS/Style.css";
import { Bars3Icon } from "@heroicons/react/24/solid";
import Sidebar from "../views/Common/Sidebar";
import MQTT from "./MQTT";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function MainLayout({ GladianceUserMapping, HotelUserMapping }) {
    const {
        theme,
        setUserToken,
        FRONTEND_URL,
    } = useStateContext();
    
    const [userImg, SetUserImg] = useState(
        localStorage.getItem("CURRENT_PROFILEIMAGE")
    );
    const [showNotification, setShowNotification] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [toggleFun, setToggleFun] = useState({
        company: false,
        master: false,
        userManagement: false,
        roommaster: false,
        gladiancedevice: false,
        door: false,
        information: false,
        GuestManagement: false,
    });

    const navigate = useNavigate();

    const ToggleNotification = (status) => {
        setShowNotification(status);
    };

    const handleToggleFun = (e, v) => {
        setToggleFun({
            [e]: v,
        });
    };

    const logout = (e) => {
        e.preventDefault();
        setUserToken('')
        navigate("/");
    };

    const userNavigation = [
        { name: "Your Profile", href: "/your-profile" },
        { name: "Change Password", href: "/change-password" },
        { name: "Background Image", href: "/background-image" },
        { name: "Sign out", clickFuntion: logout },
        // { name: "Sign out", href: "/" },
    ];

    return (
        <Fragment>
            <Disclosure as="nav">
                {({ open }) => (
                    <Fragment>
                        <div onClick={() => ToggleNotification(false)} className={" h-screen w-screen relative text-sm " + (theme ? " bg-black" : " bg-default-color")}>
                            {/* <MQTT /> */}
                            <div className="w-full flex-wrap items-center mx-auto ">
                                <div className="flex justify-between pt-2">
                                    <img onClick={() => navigate("/dashboard")}
                                        className="w-[30%] cursor-pointer md:w-[12%] mx-4 md:mx-14 "
                                        src={FRONTEND_URL + "/assets/logos/" + (theme ? "whitelogo.png" : "blacklogo.png")}
                                        alt="Your Company"
                                    />

                                    <div className="flex justify-end items-center mx-4 text-gray-400">
                                        <button
                                            id="dropdownNotificationButton"
                                            data-dropdown-toggle="dropdownNotification"
                                            className="inline-flex items-center text-sm font-medium text-center focus:outline-none"
                                            type="button"
                                        >
                                            <svg
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 14 20"
                                            >
                                                <path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z" />
                                            </svg>
                                            <div className="relative w-3 h-3 bg-red-500 border-2 border-white rounded-full -top-2 right-3 "></div>
                                        </button>

                                        <Menu as="div" className="relative mx-3 ">
                                            <div>
                                                <Menu.Button className="flex max-w-xs items-center rounded-full bg-primary-color text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-color">
                                                    <span className="sr-only">Open user menu</span>
                                                    <img
                                                        src={
                                                            userImg
                                                                ? userImg.name
                                                                    ? URL.createObjectURL(userImg)
                                                                    : FRONTEND_URL +
                                                                    "/assets/images/UserImages/" +
                                                                    userImg
                                                                : FRONTEND_URL + "/assets/images/noimage.png"
                                                        }
                                                        alt="user-img"
                                                        className="w-12 h-12 rounded-full"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95 "
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-black bg-opacity-80 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {userNavigation.map((item) => (
                                                        <Menu.Item key={item.name}>
                                                            {({ active }) => (
                                                                <NavLink
                                                                    to={item.href}
                                                                    onClick={item.clickFuntion}
                                                                    className={classNames(
                                                                        active ? "bg-gray-700" : "",
                                                                        "block px-4 py-2 text-sm "
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </NavLink>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div className={"-ml-1 flex rounded border md:hidden " + (theme ? " bg-black border-white" : "bg-gray-200 border-black")}>
                                            <Link to={'/navbar'}>
                                                <Disclosure.Button className="inline-flex items-center justify-center p-1.5">
                                                    <span className="sr-only">Open main menu</span>
                                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                                </Disclosure.Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2 ">
                                <div className={" absolute text-sm h-[86%]  hidden md:block pl-3 md:w-[25%] lg:w-[20%]"} >
                                    <Sidebar GladianceUserMapping={GladianceUserMapping} HotelUserMapping={HotelUserMapping} />
                                </div>

                                <div className={"absolute h-[86%] w-[100%] md:w-[75%] lg:w-[80%] mx-auto md:ml-[25%] lg:ml-[20%]"}>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Disclosure>
        </Fragment>
    );
}

