import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import Validation from "../../helpers/Validation";
import { ArrowCircleLeft2, Driver } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import SaveButton from "../../helpers/SaveButton";
import { ClearInfo, FilterNodeConfig } from "../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";
import { GetHotelRoomScene } from "../../../Encapsulation/HotelDomainEntities/HotelRoomScene";
import { GetProjectSpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDeviceConnections";
import { GetProjectSpaceTypeArea } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { GetGAAProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetHotelRoomSceneConfiguration, SaveHotelRoomSceneConfiguration } from "../../../Encapsulation/HotelDomainEntities/HotelRoomSceneConfiguration";
import axios from "axios";

function RoomSceneConfigurationAddEdit({ Rights }) {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Hotel - Edit Room Scene Configuration";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add Room Scene Configuration";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation();
  const [formInfo, setFormInfo] = useState({});
  const [DeviceConnectionList, setDeviceConnectionList] = useState([]);
  const [NodeList, setNodeList] = useState([]);
  const [NodeType, setNodeType] = useState([]);
  const [Options, setOptions] = useState([]);

  console.log('formInfo :', formInfo);
  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveHotelRoomSceneConfiguration(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/scene-configurations");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProjectSpaceTypeandAreaHotelRoomScene(); // Save ProjectRef
      }
    }
  };


  const handleNodeList = async () => {
    try {
      const response = await axios.get(`https://api.gladiance.one/gladiancecloud-web-api/mqtt/nodecontrollableparameters/${formInfo.GAAProjectSpaceTypePlannedDeviceConnectionRef}`);
      setNodeList(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleParamValue = () => {
    if (formInfo.NodeConfigParamName) {
      NodeList && NodeList.map((data) => {
        if (data.name == formInfo.NodeConfigParamName) {
          setNodeType(data.data_type);
          if (data.name == "CCT") {
            setOptions(data.bounds && Array.from({ length: (((data.bounds.max / data.bounds.step) - (data.bounds.min / data.bounds.step)) + 1) }, (_, index) => data.bounds.min + (index * 100)));
          } else {
            data.bounds && setOptions(Array.from({ length: ((data.bounds.max - data.bounds.min) + 1) }, (_, index) => (data.bounds.min - 1) + (index + 1)));
            // data.bounds && setOptions(['True', 'False']);
            data.valid_strs && setOptions(data.valid_strs);
          }
        }
      })
    }
  }

  const fetchProjectSpaceTypeandAreaHotelRoomScene = async () => {
    let Project = await GetGAAProject(Location.state.GAAProjectRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name
    }))
    let SpaceType = await GetProjectSpaceType(Location.state.GAAProjectSpaceTypeRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectSpaceTypeRef: SpaceType.Ref,
      GAAProjectSpaceTypeName: SpaceType.Name,
    }))

    let SpaceTypeArea = '';
    if (Location.state.GAAProjectSpaceTypeAreaRef) {
      SpaceTypeArea = await GetProjectSpaceTypeArea(Location.state.GAAProjectSpaceTypeAreaRef);
    }
    if (SpaceTypeArea) {
      setFormInfo(formInfo => ({
        ...formInfo,
        GAAProjectSpaceTypeAreaRef: SpaceTypeArea.Ref,
        GAAProjectSpaceTypeAreaName: SpaceTypeArea.Name
      }));
    } else {
      setFormInfo(formInfo => ({
        ...formInfo,
        GAAProjectSpaceTypeAreaRef: 0,
        GAAProjectSpaceTypeAreaName: '-'
      }));
    }
    if (Location.state.GAAProjectSceneRef) {
      let Scene = await GetHotelRoomScene(Location.state.GAAProjectSceneRef);
      setFormInfo((formInfo) => ({
        ...formInfo,
        GAAProjectSceneRef: Scene.Ref,
        GAAProjectSceneName: Scene.Name,
      }));
    }

  };

  const fetchDeviceConnectionBySpaceType = async () => {
    if (formInfo.GAAProjectSpaceTypeRef) {
      let lst = await GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType(formInfo.GAAProjectSpaceTypeRef, 0)
      setDeviceConnectionList(lst.filter((data) => data.BindToGAAProjectSpaceTypePlannedDeviceRef == 0 && data.BindToGAAProjectSceneRef == 0))
    }
  };

  const fetchRoomSceneConfiguration = async () => {
    setFormInfo(await GetHotelRoomSceneConfiguration(id));
  }

  useEffect(() => {
    fetchDeviceConnectionBySpaceType();
  }, [formInfo.GAAProjectSpaceTypeRef]);

  useEffect(() => {
    if (DeviceConnectionList.length > 0) {
      if (!formInfo.GAAProjectSpaceTypePlannedDeviceConnectionRef) {
        setFormInfo((formInfo) => ({
          ...formInfo,
          GAAProjectSpaceTypePlannedDeviceConnectionRef: DeviceConnectionList[0].Ref,
        }));
      };
    }
  }, [DeviceConnectionList]);

  useEffect(() => {
    if (formInfo.GAAProjectSpaceTypePlannedDeviceConnectionRef) {
      handleNodeList();
    }
  }, [formInfo.GAAProjectSpaceTypePlannedDeviceConnectionRef])

  useEffect(() => {
    if (NodeList.length > 0) {
      if (!formInfo.NodeConfigParamName) {
        setFormInfo((formInfo) => ({
          ...formInfo,
          NodeConfigParamName: NodeList[0].name,
        }));
      }
    };
  }, [NodeList]);

  useEffect(() => {
    handleParamValue();
  }, [formInfo.NodeConfigParamName, NodeList])


  // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchProjectSpaceTypeandAreaHotelRoomScene();
    }
    if (id) {
      fetchRoomSceneConfiguration();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Room Scene Configuration" : "Add Room Scene Configuration"}
          button={
            <LinkButton to={"/scene-configurations"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectName"}
                      labelText={"Project"}
                    />

                    {formInfo.GAAProjectRef && (
                      <Input
                        id={"GAAProjectName"}
                        type={"text"}
                        name={"GAAProjectName"}
                        value={formInfo.GAAProjectName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeName"}
                      labelText={"Space Type"}
                    />
                    {formInfo.GAAProjectSpaceTypeRef && (
                      <Input
                        id={"GAAProjectSpaceTypeName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeName"}
                        value={formInfo.GAAProjectSpaceTypeName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeAreaName"}
                      labelText={"Area"}
                    />
                    {formInfo.GAAProjectSpaceTypeAreaRef == 0 ? (
                      <Input
                        id={"GAAProjectSpaceTypeAreaName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeAreaName"}
                        value={formInfo.GAAProjectSpaceTypeAreaName}
                        required={true}
                        readOnly={true}
                      />
                    ) : (
                      <Input
                        id={"GAAProjectSpaceTypeAreaName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeAreaName"}
                        value={formInfo.GAAProjectSpaceTypeAreaName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSceneRef"}
                      labelText={"Room Scene Name"}
                    />
                    {formInfo.GAAProjectSceneRef && (
                      <Input
                        id={"GAAProjectSceneRef"}
                        type={"text"}
                        name={"GAAProjectSceneRef"}
                        value={formInfo.GAAProjectSceneName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>

                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypePlannedDeviceConnectionRef"}
                      labelText={"Label"}
                    />
                    <CustomSelect
                      id={"GAAProjectSpaceTypePlannedDeviceConnectionRef"}
                      name={"GAAProjectSpaceTypePlannedDeviceConnectionRef"}
                      value={
                        formInfo.GAAProjectSpaceTypePlannedDeviceConnectionRef
                      }
                      onChange={handleChange}
                      required={true}
                    >
                      {DeviceConnectionList &&
                        DeviceConnectionList.map((data, id) => {
                          return (
                            <Option key={id} value={data.Ref}>{data.Label}</Option>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label
                      htmlFor={"NodeConfigParamName"}
                      labelText={"Node Config Param Name"}
                    />
                    <CustomSelect
                      id={"NodeConfigParamName"}
                      name={"NodeConfigParamName"}
                      value={
                        formInfo.NodeConfigParamName
                      }
                      onChange={handleChange}
                      required={true}
                    >
                      {NodeList &&
                        NodeList.map((data, id) => {
                          return (
                            <Option key={id} value={data.name}>{data.name}</Option>
                          );
                        })}
                    </CustomSelect>
                    <Validation error={errors.NodeConfigParamName} />
                  </div>
                  <div>
                    <Label htmlFor={"Value"} labelText={"Value"} />

                    <CustomSelect
                      id={"Value"}
                      name={"Value"}
                      value={
                        formInfo.Value
                      }
                      onChange={handleChange}
                      required={true}
                    >
                      <Option value=''>-- Select --</Option>
                      {
                        NodeType == 'bool' && (
                          <Fragment>
                            <Option value='true'>True</Option>
                            <Option value='false'>False</Option>
                          </Fragment>
                        )
                      }
                      {
                        (NodeType == 'string' || NodeType == 'int') && Options && Options.map((data, index) => {
                          return (
                            <Option key={index} value={data}>{data}</Option>
                          )
                        })
                      }
                    </CustomSelect>
                    <Validation error={errors.Value} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/scene-configurations"} />
                  {
                    Rights.Edit && <SaveButton text="Save" />
                  }
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default RoomSceneConfigurationAddEdit;
